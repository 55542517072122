import { useState } from "react";
import axios from "axios";
import { useAuth } from "@/context/AuthContext";

const useUploadCV = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const { user } = useAuth();

    const uploadCV = async (file) => {
        setLoading(true);
        setError(null);

        // Préparer les données pour le multipart/form-data
        const formData = new FormData();
        formData.append("resume", file, file.name);

        try {
            const res = await axios.post(
                window.ENV.BACKEND_URL + "/api/secured/admin/prospect/analyzeCV",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${user.strongToken}`, // Utiliser le token de l'utilisateur pour l'autorisation
                    },
                }
            )
                .then(response => console.log('Success: ', response))
                .catch(error => console.log('Error: ', error));
            ;
            // if(!res) throw new Error("error on upload cv files");
            
            setResponse(res?.data);
            return { data: res.data, error: null }
        } catch (err) {
            console.error("uploadCV", err)
            setError(err.response ? err.response.data : "Network error");
            return { data: null, error: err }

        } finally {
            setLoading(false);
        }

    };

    return { uploadCV, loading, error, response };
};

export default useUploadCV;
