// import { useAuth } from "@/context/AuthContext";
// import { useParams } from "react-router-dom";

// import { gql, useMutation } from "@apollo/client";

// const UPDATE_INVOICE = gql`
//   mutation UpdateConsultantInvoice(
//     $updatedInvoice: UpdateConsultantInvoiceRequestDto!
//   ) {
//     updateConsultantInvoice(updatedInvoice: $updatedInvoice) {
//       year
//       uuid
//       periodStart
//       periodEnd
//       month
//       invoiceNumber
//       invoiceDate
//       daysWorked
//       dailyRateConsultant
//       dailyRateClient
//       currency
//       clientName
//       amountConsultant
//       amountClient
//     }
//   }
// `;

// export const useUpdateInvoice = () => {
//   const { user } = useAuth();

//   const [updateInvoice, { loading, error, data }] = useMutation(
//     UPDATE_INVOICE,
//     {
//       context: {
//         headers: {
//           Authorization: `Bearer ${user.strongToken}`,
//         },
//       },
//     }
//   );

//   return { updateInvoice, loading, error, data };
// };

// useUpdateConsultantInvoice.js

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const UPDATE_INVOICE = gql`
  mutation UpdateConsultantInvoice($updatedInvoice: UpdateConsultantInvoiceRequestDto!) {
    updateConsultantInvoice(updatedInvoice: $updatedInvoice) {
      uuid
      invoiceDate
      periodStart
      periodEnd
      clientName
      invoiceNumber
      currency
      dailyRateConsultant
      dailyRateClient
      daysWorked
    }
  }
`;

export const useUpdateInvoice = () => {
  const { user } = useAuth();

  const [updateInvoice, { loading, error, data }] = useMutation(UPDATE_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  return { updateInvoice, loading, error, data };
};
