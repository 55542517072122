

import * as React from "react";
import BlueHeader from "@/components/organisms/blueHeader";
import LineArt from "@/assets/images/lineArt.png";
import { Label } from "@/components/atoms/texts/label";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
    SelectValue,
} from "@/components/ui/select";
import { ArrowForward, Interests } from "@mui/icons-material"; // Import icône de Material-UI pour le bouton
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Button } from "@/components/molecules/buttons/button";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts"
import {
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { TrendingUp } from "lucide-react"
import { useEffect } from "react";
import { undefined } from "zod";

const investmentProjects = [
    {
        uuid: "0",
        name: "Baloise - Pilier 2",
        rendement: "11",
        description: "Rendement du S&P 500",
        taxes: "Prélèvement à la source de 20,09% sur tout versement",
        duration: "Jusqu'à la retraite",
    },
    {
        uuid: "1",
        name: "Acquisition de nos futurs bureaux",
        rendement: "4,5",
        description: "Rendement locatif",
        taxes: "Durée de détention indéfinie",
        duration: "Indéfinie",
    },
    {
        uuid: "2",
        name: "Coupons obligataires S42",
        rendement: "9",
        description: "Coupon détaché annuellement",
        taxes: "Durée de détention indéfinie",
        duration: "Indéfinie",
    },
];


// Overflow page view as Consultant
export default function Overflow() {
    const [initialInvestment, setInitialInvestment] = React.useState(10000);
    const [regularInvestment, setRegularInvestment] = React.useState(500);
    const [investmentDuration, setInvestmentDuration] = React.useState(10);
    const [investmentFrequency, setInvestmentFrequency] = React.useState("month");
    const [selectedProject, setSelectedProject] = React.useState(investmentProjects[0].uuid);
    const [interetsFrequency, setInterestFrequency] = React.useState("month");
    const [chartData, setChartData] = React.useState([]);
    const frequencyMapping = {
        month: 12,
        quarter: 4,
        year: 1,
    };
    const invertFrequencyMapping = {
        month: 1,
        quarter: 4,
        year: 12,
    };
    useEffect(() => {
        console.log("use effect", initialInvestment, regularInvestment, regularInvestment === "", investmentDuration, investmentFrequency, selectedProject, interetsFrequency)

        if (initialInvestment !== "" && regularInvestment !== "" && investmentDuration !== "" && investmentFrequency !== "" && selectedProject !== "" && interetsFrequency !== "") {
            handleSubmit()
        } else {
            console.log("a value isn't setted", initialInvestment, regularInvestment, investmentDuration, investmentFrequency, selectedProject, interetsFrequency)

        }
    }, [initialInvestment, regularInvestment, investmentDuration, investmentFrequency, selectedProject, interetsFrequency]);
    const handleSubmit = (e) => {
        e?.preventDefault();

        const project = investmentProjects.find((p) => p.uuid === selectedProject);
        const rendement = parseFloat(project.rendement) / 100;
        const totalDuration = parseInt(investmentDuration);
        const depositFrequency = frequencyMapping[investmentFrequency];
        const interestFrequency = frequencyMapping[interetsFrequency];

        let netInvestment = parseFloat(initialInvestment);
        let compoundedInvestment = netInvestment;
        let currentMonth = 0;
        let netInterest = 0
        let netInterestCumulative = 0;  // Ajouter cette variable pour cumuler les intérêts

        const data = [];
        data.push({
            month: 0,
            year: 0,
            interests: 0,
            compoundedInvestment: compoundedInvestment.toFixed(0),
            netInvestment: netInvestment.toFixed(0),
            netInterestCumulative: netInterestCumulative.toFixed(0),  // Initialiser à zéro
        });
        for (let year = 1; year <= totalDuration; year++) {
            for (let month = 1; month <= 12; month++) {
                currentMonth++;
                netInterest = 0
                // Si c'est un mois où un versement régulier est fait
                if ((month % (12 / depositFrequency)) === 0) {
                    netInvestment += parseFloat(regularInvestment);
                    compoundedInvestment += parseFloat(regularInvestment);
                }

                // Si c'est un mois où les intérêts composés sont appliqués
                if ((month % (12 / interestFrequency)) === 0) {
                    compoundedInvestment += compoundedInvestment * (rendement / interestFrequency);
                    netInterest = compoundedInvestment * (rendement / interestFrequency);
                    netInterestCumulative += netInterest;  // Cumul des intérêts

                }

                data.push({
                    month: currentMonth,
                    year: year,
                    interests: netInterest,
                    compoundedInvestment: compoundedInvestment.toFixed(0),
                    netInvestment: netInvestment.toFixed(0),
                    netInterestCumulative: netInterestCumulative.toFixed(0),  // Ajout de la donnée des intérêts cumulés

                });
            }
        }

        setChartData(data);
    };


    const chartConfig = {
        netInterestCumulative: {
            label: "Intérets composés cumulés",
            color: "hsl(var(--chart-1))",
        },
        compoundedInvestment: {
            label: "Intérets composés",
            color: "hsl(var(--chart-2))",
        },
        netInvestment: {
            label: "Versements nets",
            color: "hsl(var(--chart-1))",
        },


    }
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { month, year } = payload[0].payload;
            console.log("month", month, "year", year)

            return (
                <div className="custom-tooltip bg-white p-firstDegree shadow-md rounded-sm">
                    {/* Affiche "Mois X Années Y" */}
                    <Label className="flex label font-bold">{month == 0 && year == 0 ? `Début` : `Année ${year} : Mois ${month % 12 || 12}`}</Label>

                    <Label className="flex text-greyStrong italic">{"Intérets reçus ce mois "}: {parseInt(payload[0].payload.interests)} €</Label>

                    {/* Affiche les valeurs avec le signe € */}
                    {/* Inverser l'ordre d'affichage : intérêts cumulés d'abord, puis versements */}


                    {payload
                        .slice() // Copie du tableau payload pour ne pas le modifier directement
                        .reverse() // Inverser l'ordre des éléments pour afficher les intérêts cumulés en premier
                        .map((entry, index) => (
                            <Label key={`item-${index}`} className="intro block" >
                                <span style={{ color: entry.color }}>{entry.name}</span> : {entry.value} €
                            </Label>
                        ))}


                </div>
            );
        }
        return null;
    };
    function getInterval() {
        if (investmentDuration <= 10) {
            return 11
        } else if (investmentDuration < 30) {
            return 23
        } else {
            return 35
        }
    }
    return (
        <div className="bg-lighter flex grow overflow-hidden relative">
            <div className="w-full h-[93vh] overflow-y-scroll overflow-x-hidden z-10 pb-32">
                <img src={LineArt} className="bgLineArt" alt="Line Art Background" />
                <BlueHeader title="navbarConsultant.overflowOptimization" />

                <div className="px-[10vw] py-thirdDegree  gap-thirdDegree flex flex-col items-center">
                    <Label Label size="h2" fontWeight="bold" message="overflow.simulatorTitle" />
                    <Label className="w-full " size="body" message="overflow.simulatorDescription" />

                    <div className="flex w-full gap-secondDegree">
                        <form onSubmit={handleSubmit} className="w-full">
                            <div className="flex flex-col gap-6">
                                {/* Montant investi initialement */}
                                <div className="flex flex-col gap-firstDegree">
                                    <Label fontWeight="semibold">Montant investi initialement</Label>
                                    <div className="flex gap-firstDegree w-full items-center">
                                        <Input
                                            type="number"
                                            placeholder="Ex: 10 000"
                                            value={initialInvestment}
                                            onChange={(e) => { setInitialInvestment(e.target.value) }}
                                            className="w-full"
                                            onBlur={(e) => { e.target.value == "" && setInitialInvestment(0) }}
                                        />

                                        <Label className="min-w-min flex mx-1"  >€</Label>
                                    </div>
                                </div>

                                {/* Montant investi régulièrement */}
                                <div className="flex flex-col gap-firstDegree">
                                    <Label fontWeight="semibold">Montant investi régulièrement</Label>
                                    <ToggleGroup type="single" className="w-full " onValueChange={setInvestmentFrequency} value={investmentFrequency}>
                                        <ToggleGroupItem value="month" aria-label="Toggle bold" className="w-full rounded-sm">
                                            <Label >Mensuel</Label>
                                        </ToggleGroupItem>
                                        <ToggleGroupItem value="quarter" aria-label="Toggle italic" className="w-full rounded-sm">
                                            <Label >Trimestriel</Label>
                                        </ToggleGroupItem>
                                        <ToggleGroupItem value="year" aria-label="Toggle underline" className="w-full rounded-sm">
                                            <Label >Annuel</Label>
                                        </ToggleGroupItem>
                                    </ToggleGroup>

                                    <div className="flex gap-firstDegree w-full items-center">
                                        <Input
                                            type="number"
                                            placeholder="Ex: 500"
                                            value={regularInvestment}
                                            onChange={(e) => setRegularInvestment(e.target.value)}
                                            onBlur={(e) => { e.target.value == "" && setRegularInvestment(0) }}
                                            className="w-full"
                                        />
                                        <Label className="min-w-min flex mx-1"  >€</Label>
                                    </div>
                                </div>

                                {/* Durée de l'investissement */}
                                <div className="flex flex-col gap-firstDegree">
                                    <Label fontWeight="semibold">Durée de l'investissement </Label>
                                    <div className="flex gap-firstDegree w-full items-center">
                                        <Input
                                            type="number"
                                            placeholder="Ex: 10"
                                            value={investmentDuration}
                                            onChange={(e) => setInvestmentDuration(e.target.value)}
                                            onBlur={(e) => { e.target.value == "" && setInvestmentDuration(1) }}
                                            className="min-w-fit"
                                        />

                                        <Label className="min-w-min flex mx-1" message={investmentDuration > 1 ? `overflow.years` : `overflow.year`} ></Label>

                                    </div>

                                </div>


                                {/* Fréquence des intérets */}
                                <div className="flex flex-col gap-firstDegree">
                                    <Label fontWeight="semibold">Fréquence de versements de vos intérets</Label>
                                    <ToggleGroup type="single" className="w-full " onValueChange={setInterestFrequency} value={interetsFrequency}>
                                        <ToggleGroupItem value="month" aria-label="Toggle bold" className="w-full rounded-sm">
                                            <Label >Mensuel</Label>
                                        </ToggleGroupItem>
                                        <ToggleGroupItem value="quarter" aria-label="Toggle italic" className="w-full rounded-sm">
                                            <Label >Trimestriel</Label>
                                        </ToggleGroupItem>
                                        <ToggleGroupItem value="year" aria-label="Toggle underline" className="w-full rounded-sm">
                                            <Label >Annuel</Label>
                                        </ToggleGroupItem>
                                    </ToggleGroup>

                                </div>

                                {/* Choix du projet d'investissement */}
                                <div className="flex flex-col gap-firstDegree">
                                    <Label fontWeight="semibold">Choisissez un projet d'investissement</Label>
                                    <Select value={selectedProject} onValueChange={setSelectedProject}>
                                        <SelectTrigger className="flex items-center h-auto gap-secondDegree bg-white">
                                            <SelectValue placeholder="Sélectionnez un projet" />
                                        </SelectTrigger>
                                        <SelectContent className="rounded-sm">
                                            {investmentProjects.map((project, index) => (
                                                <SelectItem key={index} value={project.uuid} className="flex flex-col items-start justify-center">
                                                    <div className="flex flex-col items-start gap-secondDegree my-1">
                                                        <Label fontWeight="bold" size="body">{project.name}</Label>
                                                    </div>
                                                    <div className="flex gap-secondDegree">
                                                        <div className="flex gap-betweenText">
                                                            <Label size="bodySmall">Rendement</Label>
                                                            <Label fontWeight="bold">{project.rendement} %</Label>
                                                        </div>
                                                        <div className="flex gap-betweenText">
                                                            <Label size="bodySmall">Durée</Label>
                                                            <Label fontWeight="bold">{project.duration}</Label>
                                                        </div>

                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                {/* Bouton de soumission */}
                                {/* <Button
                                    type="submit"
                                    text="global.actions.simulate"
                                    showRightIcon rightIcon={<ArrowForward />}
                                /> */}
                            </div>
                        </form>
                        <div className="flex flex-col w-full  ">

                            <ChartContainer config={chartConfig} className="h-full">
                                <AreaChart
                                    accessibilityLayer
                                    data={chartData}
                                    margin={{
                                        left: 12,
                                        top:24,
                                        right: 24,
                                    }}
                                >
                                    <CartesianGrid />
                                    {/* Ajout de l'axe Y avec légende personnalisée */}
                                    <YAxis
                                    width={70}
                                        tickFormatter={(value) => {
                                            if (value >= 1000) {
                                                return `${value / 1000} k €`; // Formatte les valeurs en milliers (50k, 100k, etc.)
                                            }
                                            return value; // Si la valeur est en-dessous de 1000, affiche-la normalement
                                        }}
                                    />
                                    <XAxis
                                        dataKey="year"
                                        tickLine
                                        axisLine
                                        tickMargin={8}
                                        // tickMargin={20}
                                        // height={50}
                                        // padding={30}
                                        // angle={45}
                                        // xAxisId="1"
                                        // interval={investmentDuration<= 10 ? 11 : 23}
                                        // tickCount={investmentDuration * 12}  // 1 tick par année
                                        interval={Math.floor(investmentDuration / 12) * 12 +11}  // Calcul de l'intervalle basé sur investmentDuration
                                        tickCount={investmentDuration <= 10 ? investmentDuration : 10}  // Limite à un maximum de 10 ticks
                                        tickFormatter={(value) => {
                                            let value_ = value - 1;
                                            // console.log("value in tck formatter =", value);
                                            if (value > 1) {
                                                return `${value} ans`;
                                            } else if (value_ < 0) {
                                                return ``;
                                            } else {
                                                return `${value} an`;
                                            }
                                        }}
                                    />
                                {/* <XAxis xAxisId="0" dataKey="" interval={investmentDuration / 12} tickFormatter={() => {return 'Année'}}/> */}
{/* 
                                    <XAxis xAxisId="1" dataKey="year" interval={0} allowDuplicatedCategory={false} tickFormatter={(v) => {return v-1}} /> */}
                                    {/* <XAxis
                                        dataKey="month"  // Utilise le mois comme clé de données
                                        tickLine
                                        axisLine
                                        tickMargin={8}
                                        interval={11}  // Affiche un tick à chaque période
                                        allowDuplicatedCategory={false}
                                        tickFormatter={(value) => {
                                            // Tick du premier intervalle (après 12 mois)
                                            if (value === 12) {
                                                return `1 an`;
                                            }

                                            // Les ticks suivants après 11 mois (mois 23, 34, 45, etc.)
                                            if ((value - 12) % 11 === 0 && value > 12) {
                                                const year = Math.floor((value - 1) / 12);  // Calculer l'année
                                                return `${year} ans`;
                                            }

                                            return '';  // Aucun label pour les mois intermédiaires
                                        }}
                                    /> */}


                                    <ChartTooltip
                                        cursor

                                        content={<CustomTooltip />}
                                    />
                                    <Area
                                        dataKey="netInvestment"
                                        name="Versements"
                                        type="monotone"
                                        fill="var(--color-netInvestment)"
                                        fillOpacity={0.4}
                                        stroke="var(--color-netInvestment)"
                                        stackId="a"
                                    />
                                    {/* <Area
                                        dataKey="compoundedInvestment"
                                        name="Total avec intérets"
                                        type="monotone"
                                        fill="var(--color-compoundedInvestment)"
                                        fillOpacity={0.4}
                                        stroke="var(--color-compoundedInvestment)"
                                        stackId="b"
                                    /> */}
                                    {/* Area for netInterestCumulative */}
                                    <Area
                                        dataKey="netInterestCumulative"
                                        name="Intérêts composés cumulés"
                                        type="monotone"
                                        fill="var(--color-compoundedInvestment)"
                                        fillOpacity={0.4}
                                        stroke="var(--color-compoundedInvestment)"
                                        stackId="a"  // Empiler sur le même axe
                                    />
                                    <ChartLegend content={<ChartLegendContent />} />
                                </AreaChart>

                            </ChartContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
