import React, { useRef, useState, useEffect } from 'react';

import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';

// import FormNewPayslip from '@/components/macro-organisms/panels/panels-admin/newPayslip/FormNewPayslip';
import Action from '@/components/molecules/buttons/Action';
import FormNewPayslip from './FormNewPayslip';
import { useStorePayslip } from '@/services/admin-services/api-rest/useStorePayslip';
import { useAddPayslip } from '@/services/admin-services/mutations/useAddPayslip';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';

export default function NewPayslipPanel({ global = true, refetchFromScratch }) {
    const submitRef = useRef(null);
    const [openNewPayslip, setOpenNewPayslip] = useState(false);
    const [dataToSend, setDataToSend] = useState();
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    const { storePayslip, loading: storeLoading, error: storeError, data: storeData } = useStorePayslip();

    const { addPayslip, loading: loadingAdd, error: errorAdd, data: dataAdd } = useAddPayslip()

    const handleSubmit = async (formData) => {
        console.log("Form data:", formData);
        setDataToSend(formData)
        try {
            // Réinitialiser les états
            setSubmissionError(null);
            setSubmissionSuccess(false);

            // Étape 1 : Envoyer le fichier et récupérer l'UUID
            const fileResponse = await formData.file && storePayslip(formData.file, formData?.consultantUuid);
            console.log("fileResponse :", fileResponse);
        } catch (err) {
            // Gestion des erreurs
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
    };
    useEffect(() => {
        console.log("use effect storeData", storeData)

        const sendAddPayslip = async (uuidFile) => {
            // Valider et formater les valeurs de formData
            const newPayslip = {
                payslipFileUuid: uuidFile, // Ajouter l'UUID du fichier payslipFileUuid
                consultantUuid: dataToSend.consultantUuid,
                period: dataToSend.period,
                currency: dataToSend.currency,
                grossSalary: parseFloat(dataToSend.grossSalary),
                netSalary: parseFloat(dataToSend.netSalary), // Convertir en Float
                taxes: parseFloat(dataToSend.taxes), // Convertir en Float
                employerContributions: parseFloat(dataToSend.employerContributions),
                employeeContributions: parseFloat(dataToSend.employeeContributions),
                comment: dataToSend.comment,
                bonus:dataToSend.bonus

            };
            console.log("newPayslip:", newPayslip, storeData, dataToSend.vatList);

            // Étape 2 : Envoyer la mutation avec les variables formatées
            await addPayslip({
                variables: newPayslip, // Passer les variables correctement
            });
        }
        if (storeData) {
            dataToSend && sendAddPayslip(storeData.uuid)
        }

        storeError && setSubmissionError(storeError)
    }, [storeError, storeData]);

    
    useEffect(() => {
        if (dataAdd?.addPayslip?.uuid) {
            // Afficher le succès si tout est ok
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        console.log("use effect dataAdd", dataAdd)
        errorAdd && setSubmissionError(errorAdd)
        
        
    }, [errorAdd, dataAdd]);


    const handleClose = (close) => {
        setSubmissionError(null);
        setSubmissionSuccess(null);
        setOpenNewPayslip(close);
    };

    return (
        <Sheet open={openNewPayslip} onOpenChange={handleClose}>
            <SheetTrigger className={global && `w-full`}>

                {global ? (
                    <Action

                        icon={<DirectionsCar />}
                        text="consultants.dashboard.actions.payslip"
                        initialVariant={openNewPayslip ? "active" : "default"}


                    />
                ) : (
                    <Button leftIcon={<AddRounded />} showLeftIcon text="consultants.payslip.addPayslip" />
                )}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={"consultants.payslip.addPayslip"}
                    subheader={"global.information.allFieldsMandatory"}
                />
                <SheetSlot className=" flex  relative">
                    <FormNewPayslip onSubmit={handleSubmit} submitRef={submitRef} global={global} submissionSuccess={submissionSuccess} />
                    {(storeLoading || loadingAdd) && <span className='loader' />}
                    {(storeError || errorAdd) && <PrintErrorMessage error={storeError || errorAdd} />}
                </SheetSlot>
                <SheetFooter
                    buttonOutlineText={"global.actions.clear"}
                    buttonDressedText={"global.actions.ConfirmNext"}
                    warning={"global.userBeNotified"}
                    onClickOutline={(e) => submitRef.current && submitRef.reset.resetForm()}
                    onClickDressed={() => submitRef.current && submitRef.current.click()}

                />
            </SheetContent>
        </Sheet>
    )
}