import React, { useState } from 'react'
import { useConsultantContext } from '@/context/ConsultantContext';
import { useToast } from "@/components/organisms/feedback/use-toast"
import useUpdateCooptation from '@/services/consultant-services/mutation/useUpdateCooptation';


const UseNewCooptation = ({setInformationData}) => {
    const [data, setData] = useState([]);
    const [cooptingUUID, setcooptingUUID] = useState();
    const { activeUser } = useConsultantContext();
    const [openNewCooptation, setOpenNewCooptation] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');
    // const retribution = ["5/10/15", "7/14/21"];
    const { toast } = useToast();
    const { setCooptation, data:dataUpdateCooptation, loading, error } = useUpdateCooptation();

    const changeData = (index, lineData, coopting = false) => {
        if (coopting) {
            setcooptingUUID(lineData);
            // console.log("coopting change case : ", lineData, cooptingUUID);
            return
        }
        if (data) {
            setData(currentData => {
                const existingItem = currentData[index] || {};
                const updatedItem = { ...existingItem, ...lineData };
                const newData = [...currentData];
                newData[index] = updatedItem;
                console.log("debug newData : ", newData, cooptingUUID);
                return newData;
            });
        }

    };

    function onCancel() {
        toast({
            variant: "warning",
            title: "error.discardChange",
            duration: 8000,
        });
        setData([]);
        setOpenNewCooptation(false);
    }

    function onSend() {
        try {
            dataControl();
        } catch (error) {
            console.error("Error while processing data: ", error);
            return
        }

        const cooptedBy = data.map(item => ({
            cooptantConsultantUuid: item.uuid,
            percentage: parseInt(item.pourcent, 10),
            startDate: item.date,
            monthsDuration: parseInt(item.duration, 10)
        }));
        // console.log("cooptedBy", cooptedBy);



        const sendCooptationData = async () => {
            // console.log("cooptingUUID, cooptedBy", cooptingUUID, cooptedBy);
            const { data, error } = await setCooptation(cooptingUUID, cooptedBy);
            if (error) {
                toast({
                    variant: "error",
                    title: "error.requestFailed",
                    duration: 8000,
                    description: error
                });
                console.log("Error updating cooptations:", error);
            } else {
                toast({
                    variant: "success",
                    title: "success.changesSaved",
                    duration: 1500,
                });
                setInformationData && setInformationData((prevData) => ({
                    ...prevData,
                    cooptedBy: data?.cooptedBy
                }));
                // console.log("Cooptations updated successfully:", data);
            }
        };

        sendCooptationData();

        // console.log("Data sent : ", data, cooptingUUID, " , showErrorMessage : ", showErrorMessage);
        // showErrorMessage === "" && setOpenNewCooptation(false);
        setOpenNewCooptation(false);
        setData([]);

    }

    function dataControl() {
        setShowErrorMessage("")
        let totalPercentage = 0;
        let names = new Set();
        // console.log("dataControl data = ", data);
        data.forEach(item => {
            console.log("debug dataControl item", item);

            if (!item.uuid || !item.pourcent || item.pourcent == 0 || !item.date) {
                console.log("data : ", data)
                setShowErrorMessage("missingRequired");
                throw new Error("missingRequired");
            }
            if (names?.has(item.uuid)) {
                // console.log("data : ", data)

                setShowErrorMessage("mulitpleSimilarCoopting");
                throw new Error("mulitpleSimilarCoopting");
            } else {
                names.add(item.uuid);
            }
            if (item.pourcent) {
                totalPercentage += parseInt(item.pourcent);
            }
            // console.log("dataControl = totalPercentage", totalPercentage, " , names.has(item.name) : ", names.has(item.name))
        });
        if (totalPercentage !== 100) {
            console.log("debug dataControl totalPercentage = ", totalPercentage)
            setShowErrorMessage("totalOwnership");
            throw new Error("totalOwnership");
        }


    }

    return {
        showErrorMessage,
        openNewCooptation,
        setOpenNewCooptation,
        // selectedRetribution, 
        // setSelectedRetribution, 
        // isInputValid, 
        // inputValue, 
        changeData,
        onCancel,
        onSend,
        dataControl,
        // validateInput, 
        // handleInputBlur
    }
}
export default UseNewCooptation;
// export default {openNewCooptation , selectedRetribution, setSelectedRetribution, isInputValid, inputValue, changeData, onCancel, onSend, dataControl, validateInput, handleInputBlur}