import TimesheetCalendar from './TimesheetCalendar';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import PrintWarningMessage from '@/components/organisms/feedback/PrintWarningMessagee';
import { useTimeEntries } from '@/services/consultant-services/query/useTimeEntries';


export default function ShowTimesheet({ year, month, timesheetId, totalDays, refetchTimesheets, dataOwner }) {
    const [timesheetMonth, setTimesheetMonth] = useState({ year: year, month: month });
    const [uniqueDaysWithEvents, setUniqueDaysWithEvents] = useState();
    const { data: dataTimeEntries, loading: loadingTimeEntries, error: errorTimeEntries, refetch } = useTimeEntries(timesheetId);

    useEffect(() => {
        if (dataTimeEntries) {
            const transformedEvents = transformTimeEntriesToEvents(dataTimeEntries, year, month);
            console.log(" ShowTimesheet timeEntries_", dataTimeEntries, transformedEvents);
            // setTimeEntriesForCalendar(transformedEvents);
            setUniqueDaysWithEvents(getUniqueDaysWithEvents(transformedEvents))
        }
        if (errorTimeEntries) {
            console.error("Error fetching time entries: ", errorTimeEntries);
        }
    }, [dataTimeEntries, errorTimeEntries, year, month]);

    const getUniqueDaysWithEvents = (events) => {
        console.log("getUniqueDaysWithEvents events = ", events)
        const uniqueDays = new Set(events?.map(event => moment(event.start).format('YYYY-MM-DD')));
        return uniqueDays.size;
    };

    return (

        <div className='flex min-h-6'>
            {loadingTimeEntries && <span className='loader' />}
            {errorTimeEntries && <PrintErrorMessage error={errorTimeEntries} />}
            {dataTimeEntries && (
                <div className='w-full'>
                    {totalDays === 0 && <PrintErrorMessage label="error.nbDaysZero" />}
                    {totalDays !== 0 && totalDays !== uniqueDaysWithEvents && <PrintWarningMessage warning="error.nbDaysDifferent" />}
                    {totalDays !== 0 &&
                        <TimesheetCalendar
                            selectedMonthToDisplay={`${year}-${month}`}
                            timesheetId={timesheetId}
                            viewOnly
                            timesheetMonth={timesheetMonth}
                            refetchTimesheets={refetchTimesheets}
                            dataOwner={dataOwner}
                        />}
                </div>
            )}
        </div>
        
    );
}
