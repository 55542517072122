import { Label } from "@/components/atoms/texts/label";
import ReadingStatus from "./readingStatus";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useState } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";
import getCurrencySymbolFromCode from "@/utils/getCurrencySymbolFromCode";
// import useGetExpenseReportProof_AsUser from "@/services/consultant-services/api-rest/useGetExpenseReportProof_AsUser";
// import useGetExpenseReportProof_AsAdmin from "@/services/admin-services/api-rest/useGetExpenseReportProof_AsAdmin";
// import OpenProofAsAdmin from "./OpenProofAsAdmin";
import { Toaster } from "@/components/organisms/feedback/toaster";
// import DownloadProofAsAdmin from "./DownloadProofAsAdmin";
// import OpenProofAsUser from "./OpenProofAsUser";
// import DownloadProofAsUser from "./DownloadProofAsUser";
import { useDeleteExpenseReport } from "@/services/consultant-services/mutation/useDeleteExpenseReport";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { useGetLeasingInvoice } from "@/services/consultant-services/api-rest/useGetLeasingInvoice";
import { useGetExpenseReportProof_AsUser } from "@/services/consultant-services/api-rest/useGetExpenseReportProof_AsUser";
import { countries, getCountryData, getCountryDataList } from "countries-list";
import { useGetExpenseReportProof_AsAdmin } from "@/services/admin-services/api-rest/useGetExpenseReportProof_AsAdmin";

export default function Expense({ data, dataOwner = false, refetch, ...props }) {

    const [open, setOpen] = useState();
    const totalTax = data.vatList ? data.vatList.reduce((acc, vat) => acc + parseFloat(vat.vatAmount), 0) : 0;
    const symbol = getCurrencySymbolFromCode(data.currency)
    const [openProof, setOpenProof] = useState(false);
    const [downloadProof, setDownloadProof] = useState(false);
    const { deleteExpenseReport, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteExpenseReport();
    const { getExpenseProof, loading: loadingExpenseProof, error: errorExpenseProof, data: dataExpenseProof } = dataOwner ? useGetExpenseReportProof_AsUser() : useGetExpenseReportProof_AsAdmin();

    const { toast } = useToast();

    // const openProofOnNewTab = () => {
    //     setOpenProof(true);
    // };
    const handleGetExpenseProof = () => {
        console.log("handleGetExpenseProof", data.expenseReportFileUuid)
        if (data.expenseReportFileUuid) {
            getExpenseProof(data.expenseReportFileUuid);
        }
    };

    const handleOpenInvoice = () => {
        if (!dataExpenseProof) {
            handleGetExpenseProof();
        }
        dataExpenseProof && window.open(dataExpenseProof?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }

    useEffect(() => {
        dataExpenseProof && window.open(dataExpenseProof?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [dataExpenseProof]);

    const deleteExpense = (uuidExpense) => {
        console.log("deleteExpense", uuidExpense);
        deleteExpenseReport(uuidExpense);

    }
    useEffect(() => {
        if (dataDelete?.deleteExpenseReport) {
            toast({
                variant: "success",
                title: "success.backendOperation.delete",
                duration: 8000,
                description: "La note de frais " + data.invoiceIssuer + " " + data.comment + " a été supprimée"
            })
            refetch(1)
        } else if (dataDelete?.deleteExpenseReport == false && errorDelete) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
    }, [dataDelete])

    useEffect(() => {
        const displayToast = () => {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
        errorDelete && !loadingDelete && displayToast();
    }, [errorDelete]);


    return (
        <div className={`bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree ${(data.status == "SUBMITTED" && !dataOwner) && "hover:bg-medium"}`} {...props}>
            {/* <Toaster /> */}
            <ReadingStatus type={data.status} />
            <div className="max-w-[15%] overflow-hidden flex gap-firstDegree">

            </div>

            {/* <Label fontWeight="">n°{data.category.accountingAccountNumber}</Label> */}

            <div className="max-w-[50%] overflow-hidden flex gap-firstDegree flex-col 2xl:flex-row">
                <Label className="visible text-nowrap" fontWeight="bold">{data.category.labelFr}</Label>

                <Label className=" visible text-nowrap">{data.invoiceIssuer} - {countries[data.countryCode.toUpperCase()].name}</Label>
                <Label className="truncate italic">{data.comment} </Label>
                
            </div>

            <div className="grow"></div>
            <div className="max-w-[45%] min-w-[250px] justify-end lg:min-w-[400px] overflow-hidden flex gap-firstDegree items-center">


                <Label fontWeight="">{data.invoiceDate}</Label>
                {data.countryCode == "lu" ?
                    <div className="flex gap-firstDegree">
                        <Label className="hidden lg:flex" fontWeight="bold" message="consultants.expense.amountExclTaxs" />
                        <Label fontWeight="">{data.amountExcludingVat} {symbol}</Label>
                    </div> :
                    <div className="flex gap-firstDegree">
                        <Label className="hidden lg:flex" fontWeight="bold" message="consultants.expense.amountInclTaxs" />
                        <Label fontWeight="">{data.totalAmount} {symbol}</Label>
                    </div>
                }



                {data.countryCode == "lu" && <div className="flex gap-firstDegree items-center">
                    <Label className="hidden lg:flex" fontWeight="bold" message="consultants.expense.amountTax" />
                    <Popover open={open} onOpenChange={setOpen} >
                        <PopoverTrigger onMouseOver={(e) => setOpen(true)} onMouseOut={(e) => setOpen(false)} asChild className="flex gap-firstDegree items-center ">
                            <div className="relative">
                                <img src={Polygon1} className="absolute top-[-2px] left-[-6px] opacity-50" />

                                <Label fontWeight="" className="">{totalTax.toFixed(2)} {symbol}</Label>
                            </div>

                        </PopoverTrigger>
                        <PopoverContent className="flex flex-col gap-firstDegree w-auto">

                            {data.vatList && data.vatList.map((vat, index) => (
                                <div className="flex gap-firstDegree" key={index}>
                                    <Label size="body" fontWeight="bold">{vat.vatRate}% :</Label>
                                    <Label size="body">{vat.vatAmount.toFixed(2)} {symbol}</Label>
                                </div>
                            ))}
                        </PopoverContent>
                    </Popover>
                </div>}

                {/* <Label fontWeight="">{data.sizeFile}</Label> */}
                <ControlsDownload
                    dataOwner={dataOwner}

                    onClickNewTab={() => handleOpenInvoice()}
                    disabledOpenNewTab={!data.expenseReportFileUuid || errorExpenseProof}

                    hideDownload

                    onClickDelete={() => deleteExpense(data.uuid)}
                    disabledDelete={errorDelete} />

                {loadingDelete || loadingExpenseProof && <span className="loader" />}
            </div>

        </div>
    )
}