// import { useAuth } from '@/context/AuthContext';
// import { useState } from 'react';
// import errorToText from '@/utils/errorToText';

// const useUser = () => {
//     const [user, setUser] = useState(null);
//     const { user: authUser } = useAuth();

//     const getUser = async (uuid) => {
//         if (!authUser.strongToken) {
//             return { data: null, error: 'No strong token available' };
//         }

//         try {
//             const response = await fetch(window.ENV.BACKEND_URL+'/api/graphql', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${authUser.strongToken}`
//                 },
//                 body: JSON.stringify({
//                     query: `
//                     query MyQuery {
//                         user(uuid: "${uuid}") {
//                           email
//                           personalEmail
//                           phoneNumber
//                           addressLine1
//                           addressLine2
//                           city
//                           trigram
//                           countryCode
//                           entryDate
//                           iban
//                           bicCode
//                           cooptedBy {
//                             cooptant {
//                               firstname
//                               lastname
//                               uuid
//                             }
//                           }
//                           cooptantFor {
//                             coopted {
//                               firstname
//                               lastname
//                               uuid
//                             }
//                             monthsDuration
//                             percentage
//                             startDate
//                           }
//                           consultantProfile {
//                             name
//                             uuid
//                           }
//                           retributionModel {
//                             upToFiveConsultants
//                             upToTenConsultants
//                             moreThanTenConsultants
//                             uuid
//                           }
//                           company {
//                             uuid
//                             name
//                           }
//                           zipCode
//                           lastname
//                           firstname

//                           uuid
//                         }
//                       }
//                     `
//                 })
//             })

//             if (!response.ok) {
//                 const errMessage = errorToText(response);

//                 throw new Error(errMessage);
//             }

//             const data = await response.json();
//             if (data.errors) {
//                 const errMessage = data.errors[0].message;

//                 throw new Error(errMessage);
//             }
//             // console.log("data.data.user", data.data.user);
//             setUser(data.data.user);

//             return { data: data.data.user, error: null };
//         } catch (err) {
//             console.log(err);
//             return { data: null, error: err.message };
//         }
//     };


//     return { getUser, user };
// };

// export default useUser;


import { gql, useLazyQuery } from '@apollo/client';
import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';

// Définition de la requête GraphQL
const GET_USER = gql`
  query GetUser($uuid: ID!) {
    user(uuid: $uuid) {
      email
      personalEmail
      phoneNumber
      addressLine1
      addressLine2
      city
      trigram
      countryCode
      entryDate
      iban
      bicCode
      cooptedBy {
        cooptant {
          firstname
          lastname
          uuid
        }
      }
      cooptantFor {
        coopted {
          firstname
          lastname
          uuid
        }
        monthsDuration
        percentage
        startDate
      }
      consultantProfile {
        name
        uuid
      }
      retributionModel {
        upToFiveConsultants
        upToTenConsultants
        moreThanTenConsultants
        uuid
      }
      company {
        uuid
        name
      }
      zipCode
      lastname
      firstname
      uuid
    }
  }
`;

const useUser = () => {
    const { user: authUser } = useAuth();
    const [user, setUser] = useState(null);
    const [getUserQuery, { loading, error, data }] = useLazyQuery(GET_USER, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data?.user) {
                setUser(data.user);
            }
        },
        onError: (error) => {
            console.error("Error fetching user:", error);
        },
    });

    // Fonction pour récupérer l'utilisateur
    const getUser = (uuid) => {
        if (!authUser.strongToken) {
            console.error('No strong token available');
            return;
        }

        getUserQuery({
            variables: { uuid },
        });
    };

    return {
        getUser,
        user: user || data?.user,
        loading,
        error,
    };
};

export default useUser;
