import { countries } from "countries-list";

export const transformedCountries = Object.keys(countries).reduce(
    (acc, code) => {
        acc[code] = { name: countries[code].name, code: code };
        return acc;
    },
    {}
);
// Fonction pour récupérer le code du pays en fonction du nom
export const getCountryCodeByName = (countryName) => {
    // Trouver la clé (code) du pays qui correspond au nom donné
    const countryCode = Object.keys(transformedCountries).find(
        (code) => transformedCountries[code].name.toLowerCase() === countryName.toLowerCase()
    );

    return countryCode || null; // Retourne le code du pays ou null si non trouvé
};
export const getLanguageByCode = (code) => {
    const lowerCaseCode = code.toLowerCase();
    // console.log("le code reçu", lowerCaseCode);

    // Vérifier si le code existe dans transformedLangues
    if (transformedCountries[lowerCaseCode]) {
        // Retourner le nom de la langue correspondant au code
        return transformedCountries[lowerCaseCode].name;
    }
    // console.log("Les langues", transformedCountries);

    return null; // Retourne null si le code n'est pas trouvé
};