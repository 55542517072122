import React from 'react';
import UseNewCooptation from './useNewCooptation';
import { Button } from "@/components/molecules/buttons/button";
import NewCooptation from "@/components/macro-organisms/panels/panels-admin/newCooptation/NewCooptation";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import AddIcon from '@mui/icons-material/Add';
import VerticalSplitRoundedIcon from '@mui/icons-material/VerticalSplitRounded';
import Action from '@/components/molecules/buttons/Action';
import { Group as GroupIcon } from '@mui/icons-material';


const NewCooptationPanel = ({ global = false, actionVariant = "default",setInformationData, ...props }) => {
    const {
        showErrorMessage,
        openNewCooptation,
        setOpenNewCooptation,

        changeData,
        onCancel,
        onSend,
    } = UseNewCooptation({setInformationData});

    return (
        <Sheet open={openNewCooptation} onOpenChange={setOpenNewCooptation} >
            <SheetTrigger className={global && `w-full`}>
                {global ? (
                    <Action
                        icon={<GroupIcon />}
                        text="consultants.dashboard.actions.cooptation"
                        initialVariant={openNewCooptation ? "active" : "default"}

                        {...props}
                    />
                ) : (
                    <Button text="global.panelOpening.newCooptation" leftIcon={<AddIcon />} showLeftIcon={true} showRightIcon={true} rightIcon={<VerticalSplitRoundedIcon />} ></Button>

                )}
            </SheetTrigger>
            <SheetContent >
                <SheetHeader
                    title={"global.panelOpening.newCooptation"}
                />
                <SheetSlot className=" flex ">
                    <NewCooptation changeData={changeData} showErrorMessage={showErrorMessage} global={global} />
                </SheetSlot>
                <SheetFooter
                    buttonOutlineText={"global.actions.discard"}
                    buttonDressedText={"global.actions.save"}
                    onClickOutline={() => onCancel()}
                    onClickDressed={() => onSend()}
                />
            </SheetContent>
        </Sheet>
    )
}
export default NewCooptationPanel;

