import { useAuth } from "@/context/AuthContext";
import { useMutation, gql } from "@apollo/client";

const UPDATE_USER_PERSONAL_IBAN = gql`
  mutation EditInformationUser_iban($iban: String!) {
    updateProfile(updatedProfile: { iban: $iban }) {
      iban
    }
  }
`;

const UPDATE_USER_PERSONAL_BIC_CODE = gql`
  mutation EditInformationUser_bicCode($bicCode: String!) {
    updateProfile(updatedProfile: { bicCode: $bicCode }) {
      bicCode
    }
  }
`;
const UPDATE_USER_PHONE_NUMBER = gql`
  mutation EditInformationUser_bicCode($phoneNumber: String!) {
    updateProfile(updatedProfile: { phoneNumber: $phoneNumber }) {
      phoneNumber
    }
  }
`;


const UPDATE_USER_PERSONAL_EMAIL = gql`
  mutation EditInformationUser_personalEmail($personalEmail: String!) {
    updateProfile(updatedProfile: { personalEmail: $personalEmail }) {
      personalEmail
    }
      
  }
`;

const UPDATE_USER_PERSONAL_ADDRESS = gql`
  mutation EditInformationUser_address($addressLine1: String!, $addressLine2: String, $zipCode: String!, $city: String!, $countryCode: String!) {
    updateProfile(updatedProfile: { addressLine1: $addressLine1, addressLine2: $addressLine2, zipCode: $zipCode, city: $city, countryCode: $countryCode }) {
      addressLine1
      addressLine2
      city
      zipCode
    }
  }
`;

export const useUpdateProfile = () => {
  const { user } = useAuth();

  const [updateIban, { loading: loadingIban, error: errorIban, data: dataIban }] = useMutation(UPDATE_USER_PERSONAL_IBAN, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const [updateBicCode, { loading: loadingBicCode, error: errorBicCode, data: dataBicCode }] = useMutation(UPDATE_USER_PERSONAL_BIC_CODE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  const [updateAddress, { loading: loadingAddress, error: errorAddress, data: dataAddress }] = useMutation(UPDATE_USER_PERSONAL_ADDRESS, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });
  const [updatePhoneNumber, { loading: loadingPhoneNumber, error: errorPhoneNumber, data: dataPhoneNumber }] = useMutation(UPDATE_USER_PHONE_NUMBER, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });
  
  const [updatePersonalEmail, { loading: loadingPersonalEmail, error: errorPersonalEmail, data: dataPersonalEmail }] = useMutation(UPDATE_USER_PERSONAL_EMAIL, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  return {
    updateIban,
    updateBicCode,
    updateAddress,
    updatePhoneNumber,
    updatePersonalEmail,
    loadingIban,
    errorIban,
    dataIban,
    loadingBicCode,
    errorBicCode,
    dataBicCode,
    loadingAddress,
    errorAddress,
    dataAddress,
    loadingPhoneNumber,
    errorPhoneNumber,
    dataPhoneNumber,
    loadingPersonalEmail,
    errorPersonalEmail,
    dataPersonalEmail,
  };
};
