


import { useAuth } from "@/context/AuthContext";
import TimesheetCalendar from "../macro-organisms/tabs/consultants-vision-admin/timesheet/TimesheetCalendar";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PrintEmptyDataMessage from "../molecules/PrintEmptyDataMessage";
import PrintErrorMessage from "../organisms/feedback/PrintErrorMessage";
// import ExpenseTabUser from "../macro-organisms/tabs/consultant-vision-user/expense-account/expenseTabUser";
import { useStoreLeasingInvoice } from "@/services/admin-services/api-rest/useStoreLeasingInvoice";

import React, { useState, useEffect } from 'react';
import { useGetLeasingInvoice } from "@/services/consultant-services/api-rest/useGetLeasingInvoice";
import { useGetExpenseReportProof_AsUser } from "@/services/consultant-services/api-rest/useGetExpenseReportProof_AsUser";


import DatePickerValue from "../molecules/inputs/DatePicker";
import { Avatar } from "../atoms/avatar";
import { useStorePayslip } from "@/services/admin-services/api-rest/useStorePayslip";
import { useGetPayslip } from "@/services/consultant-services/api-rest/useGetPayslip";
export default function ComponentTest() {
    // const { user } = useAuth();
    // if (!user) return;

    // const { storeLeasingInvoice, loading: storeLoading, error: storeError, data: storeData } = useStoreLeasingInvoice();
    const { storePayslip, loading: storeLoading, error: storeError, data: storeData } = useStorePayslip();

    const { getLeasingInvoice, loading: getLoading, error: getError, data: leasingFile } = useGetLeasingInvoice();
    const { getExpenseProof, loading: loadingExpenseProof, error: errorExpenseProof, data: dataExpenseProof } = useGetExpenseReportProof_AsUser();
    const { getPayslip , loading: loadingPayslipFile, error: errorPayslipFile, data: dataPayslipFile } = useGetPayslip();


    const [file, setFile] = useState(null);
    const [uuidFile, setUuidFile] = useState("");

// c404ff64-8d9c-409f-b0d7-7b5461df834d
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    const handleStoreInvoice = () => {
        if (file) {
            storePayslip(file, "7295d00c-e237-4df6-8b65-5c13b322b48f");
        }
    };
    useEffect(() => {
        console.log("ComponentTest file", file);
    }, [file]);

    const handleGetInvoice = () => {
        if (uuidFile) {
            //   getLeasingInvoice(uuidFile);
            // getExpenseProof(uuidFile)
            getPayslip(uuidFile)
        }
    };

    return (
        <div className="h-full flex flex-col bg-medium">
            <h1 className="text-primary text-h1 font-regular">Component Test</h1>


            {/* <Avatar  isEmpty={true} size="medium" onEdit={() => console.log("Edit button clicked")} />
            <Avatar src="https://thispersondoesnotexist.com/" size="medium" onEdit={() => console.log("Edit button clicked")} />
            <Avatar fallback="John Doe" onEdit={() => console.log("Edit button clicked")} size="medium" /> */}

           <h2>Store payslip Invoice</h2>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleStoreInvoice} disabled={storeLoading}>
                {storeLoading ? "Uploading..." : "Upload Invoice"}
            </button>
            {storeError && <p>Error: {storeError}</p>}
            {storeData && <p>Stored Invoice UUID: {storeData.uuid}</p>}

{/* bd5be6ed-4437-4f7c-9150-f43c417051c4 */}
            {/* 
            {/*  */}

            <h2>Get Payslip </h2>
            <input type="text" value={uuidFile} onChange={(e) => setUuidFile(e.target.value)} placeholder="Enter UUID" />
            <button onClick={handleGetInvoice} disabled={getLoading}>
                {getLoading ? "Loading" : "Get Invoice"}
            </button>
            {getError && <p>Error: {getError}</p>}
            {dataPayslipFile && console.log("getData : ", dataPayslipFile.type)}
            <img src={dataPayslipFile?.url}/>
            {/* {/* <img src={dataExpenseProof?.url}/> */}

            {dataPayslipFile && <a href={dataPayslipFile} download="leasingInvoice.png">Download Invoice</a>} 

        </div>


    );
}
