import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";

const GET_CONSULTANT_LEASING_INVOICES = gql`
  query ConsultantLeasingInvoices($consultantUuid: ID!, $sliceNumber: Int!) {
    consultantLeasingInvoices(leasingInvoicesRequest: {
      consultantUuid: $consultantUuid
      sliceNumber: $sliceNumber
    }) {
      leasings {
        uuid
        leasingInvoiceFileUuid
        year
        month
        invoiceDate
        amountExcludingVat
        totalAmount
        comment
        vatList {
          vatRate
          vatAmount
        }
      }
      hasNext
    }
  }
`;

export const useConsultantLeasingInvoices = (consultantUuid, sliceNumber) => {
  const { user } = useAuth();

  const { loading, error, data, refetch } = useQuery(GET_CONSULTANT_LEASING_INVOICES, {
    variables: { consultantUuid, sliceNumber },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });
  
  useEffect(() => {
    refetch(sliceNumber)
  }, [sliceNumber]);

  return { loading, error, data: data?.consultantLeasingInvoices, refetch };
};
