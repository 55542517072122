// EditBillsPanel.js

import React, { useState, useRef, useEffect } from 'react';
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetFooter, SheetSlot } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { Paid } from '@mui/icons-material';
import Action from '@/components/molecules/buttons/Action';
import FormEditBills from './FormEditBills';
import BillsContent from './BillsContent';
import { useAddInvoice } from '@/services/admin-services/mutations/useAddConsultantInvoice';
import { useUpdateInvoice } from '@/services/admin-services/mutations/useUpdateConsultantInvoice';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';

export default function EditBillsPanel({ global = false, refetchFromScratch, activeUser, years }) {
    const submitRef = useRef(null);
    const [openEditBills, setOpenEditBills] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [changeContent, setChangeContent] = useState(false); // Logic to switch between BillsContent and FormEditBills
    const [isButtonSaveClicked, setIsButtonSaveClicked] = useState(false);
    const [itemTodelete, setItemToDelete] = useState(null); // Item to delete
    const [monthDataBillsBasedOnItemDeleted, setMonthDataItemDeleted] = useState(null); // Updated bills after delete
    const [selectedYear, setSelectedYear] = useState("");
    const [editData, setEditData] = useState(null);

    const { addInvoice, loading: loadingAdd, error: errorAdd, data: dataAdd } = useAddInvoice();
    const { updateInvoice, loading: loadingUpdate, error: errorUpdate, data: dataUpdate } = useUpdateInvoice();

    const handleSubmit = async (formData) => {
        // console.log("Form data:", consultantUuid, customer, billNumber, days, amountClient, amountConsultant, currency, tJMClient, tJMConsultant, periodStart, periodEnd, invoiceDate,);
        try {
            setSubmissionError(null);
            setSubmissionSuccess(false);
            const updatedInvoice = {
                uuid: editData?.uuid,
                invoiceDate: formData.invoiceDate,
                periodEnd: formData.periodEnd,
                periodStart: formData.periodStart,
                invoiceNumber: formData.billNumber,
                clientName: formData.customer,
                daysWorked: parseFloat(formData.days),
                dailyRateConsultant: parseFloat(formData.tJMConsultant),
                dailyRateClient: parseFloat(formData.tJMClient),
                currency: formData.currency

            }
            const newInvoice = {
                consultantUuid: formData.consultantUuid,
                invoiceDate: formData.invoiceDate,
                periodEnd: formData.periodEnd,
                periodStart: formData.periodStart,
                invoiceNumber: formData.billNumber,
                clientName: formData.customer,
                daysWorked: parseFloat(formData.days),
                dailyRateConsultant: parseFloat(formData.tJMConsultant),
                dailyRateClient: parseFloat(formData.tJMClient),
                currency: formData.currency

            }
            console.log("handleSubmit, updatedInvoice =", updatedInvoice)
            if (editData) {

                await updateInvoice({
                    variables: {updatedInvoice}, // Passer les variables correctement
                });
            } else {
                await addInvoice({
                    variables: { newInvoice },
                });
            }
        } catch (err) {
            setSubmissionError(err.message);
            console.error("Error during submission:", err);
        }
    };

    useEffect(() => {
        if (dataAdd?.addConsultantInvoice?.uuid || dataUpdate?.updateConsultantInvoice?.uuid) {
            setSubmissionSuccess(true);
            // refetchFromScratch();
        }
        (errorAdd || errorUpdate) && setSubmissionError(errorAdd || errorUpdate);
    }, [dataAdd, dataUpdate, errorAdd, errorUpdate]);

    const handleClose = (close) => {
        setSubmissionError(null);
        setSubmissionSuccess(null);
        setOpenEditBills(close);
    };

    const toggleContent = () => {
        setChangeContent(!changeContent);
        setEditData();
        setSubmissionError(null);
        setSubmissionSuccess(null);
    };

    return (
        <Sheet open={openEditBills} onOpenChange={handleClose}>
            <SheetTrigger className={global && `w-full`}>
                {global ? (
                    <Action
                        icon={<Paid />}
                        text="consultants.dashboard.actions.invoice"
                        initialVariant={openEditBills ? "active" : "default"}
                    />
                ) : (
                    <Button leftIcon={<Paid />} showLeftIcon text="consultants.statement.overflow.seeBillsDetails" />
                )}
            </SheetTrigger>
            <SheetContent>
                <SheetHeader
                    title={changeContent ? (editData ?"consultants.statement.billsDetails.updateBill" : "consultants.statement.billsDetails.newBill") : "consultants.statement.billsDetails.addNewBills"}
                    subheader={"global.information.allFieldsMandatory"}
                />
                <SheetSlot className="flex relative">
                    {changeContent ? (
                        <FormEditBills
                            onSubmit={handleSubmit}
                            submitRef={submitRef}
                            global={global}
                            submissionSuccess={submissionSuccess}
                            submissionError={submissionError}
                            editData={editData}
                        />
                    ) : (
                        <BillsContent
                            activeUser={activeUser}
                            setChangeContent={toggleContent}
                            //   setEditBillsData={setChangeContent}
                            setEditBillsData={setEditData}
                            years={years}
                            itemToDelete={itemTodelete}
                            newMonthDataAfterDelete={monthDataBillsBasedOnItemDeleted}
                            changeMonthDataDeleted={setMonthDataItemDeleted}
                            informSelectedYear={setSelectedYear}
                        />
                    )}
                    {(loadingAdd || loadingUpdate) && <span className='loader' />}
                    {/* {(errorAdd || errorUpdate) && <PrintErrorMessage error={errorAdd || errorUpdate} />} */}
                </SheetSlot>
                <SheetFooter
                    buttonOutlineText={changeContent && "global.actions.back" }
                    buttonDressedText={changeContent ? "global.actions.ConfirmNext" : "consultants.statement.billsDetails.newBill"}
                    onClickOutline={toggleContent}
                    onClickDressed={() => changeContent  ? submitRef.current?.click() : toggleContent()}
                />
            </SheetContent>
        </Sheet>
    );
}
