// Rôle requis : USER_TIMESHEET
// Ce hook permet à un utilisateur de récupérer un fichier PDF contenant les informations de sa timesheet pour un mois donné.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette requête.

import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';

function useTimesheetExport() {
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const [loading, setLoading] = useState(null);
    const [data, setData] = useState(null);

    const requestTimesheetExport = async (timesheetUuid) => {

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${window.ENV.BACKEND_URL}/api/secured/users/timesheet/export/${timesheetUuid}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user?.strongToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            console.log("blob file timesheet ", blob, url)

            setData({ url, type: blob.type });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { requestTimesheetExport,  loading, error , data};
}

export default useTimesheetExport;
