import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

const RootRedirect = () => {
    const { isAuth, hasPrivileges } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth()) {
            if (hasPrivileges("ADMIN_")) {
                navigate('/consultant');
            } else if (hasPrivileges("USER_")) {
                navigate('/administration');
            }
        } else {
            navigate('/login');
        }
    }, [isAuth, hasPrivileges, navigate]);

    return null;
};

export default RootRedirect;
