// hooks/useConnectionForm.js
import useUserActivation from '@/pages/useUserActivation';
import { Code2 } from 'lucide-react';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import QRCode from 'qrcode';
import generateRandomHSecret from '@/utils/generateRandomSecret';
// import { useLogin, useLoginOTP } from '@/services/global-services/useAuthentification';
import { useAuth } from '@/context/AuthContext';
import { getCookie, eraseCookie } from '@/utils/cookie';
import useCurrentUser from '@/services/global-services/query/useCurrentUser';
import { useNavigate, useParams } from 'react-router-dom';
import useFirstConnection from '@/services/global-services/api-rest/useFirstConnection';
import useChangePassword from '@/services/global-services/api-rest/useChangePassword';
import useRequestPasswordResetToken from '@/services/global-services/api-rest/useRequestPasswordResetToken';
import useSetUpOtp from '@/services/global-services/api-rest/useSetUpOtp';
import usePasswordReset from '@/services/global-services/api-rest/usePasswordReset';
// import useLogin from '@/pages/login/useLogin';
import {useLogin, useLoginOTP } from '@/services/global-services/api-rest/useAuthentification';


const useConnectionForm = (firstConnection, resetPassword) => {
    const [step, setStep] = useState(4);
    const [formData, setFormData] = useState({ email: '', password: '', newPassword: '', newPasswordCheck: '', initPasswordCheck: '', initPassword: '', tempPassword: '', savedEmail: '', rememberMe: false , resetPwdEmail:''});
    const [formErrors, setFormErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState({});

    const [loadingResponse, setLoadingResponse] = useState(false);
    const [code, setCode] = useState();
    const [secret, setSecret] = useState();
    const [setUpOtpInProgress, setSetUpOtpInProgress] = useState(false)
    const [countdown, setCountdown] = useState(10);


    const [t] = useTranslation('global');
    //const { activationToken, errorUserActivation } = useUserActivation(); //utilisé pour les test remplacé par ?
    // const { initPassword } = useLogin();
    const [srcQRCode, setSrcQRCode] = useState();
    const navigate = useNavigate();
    const { activationToken } = useParams();
    const { resetToken } = useParams();



    //hooks backend
    const { loadingFirstConnection, errorFirstConnection } = useFirstConnection();
    const { login } = useLogin();
    const { requestPasswordResetToken } = useRequestPasswordResetToken();
    const { loginOTP } = useLoginOTP();


    //contexte d'authentification
    const { loginWeakToken, loginStrongToken, logout, isAuth } = useAuth();
    const [mustSetupOTP, setMustSetupOTP] = useState(false);
    const [mustChangePassword, setMustChangePassword] = useState(false);
    const { getCurrentUser, currentUser } = useCurrentUser();
    const { changePassword } = useChangePassword();
    const { passwordReset } = usePasswordReset();
    const { setUpOtp } = useSetUpOtp();

    //resetPassword, resetToken
    useEffect(() => {
        console.log("firstConnection", firstConnection, "activationToken", activationToken, "resetPassword", resetPassword, "resetToken", resetToken);
        if (firstConnection && activationToken) {
            (!loadingFirstConnection && !errorFirstConnection) && goToStep(6);
        } else if (resetPassword && resetToken) {
            goToStep(1);
        } else (
            goToStep(4)
        )
    }, [firstConnection, resetPassword, activationToken, resetToken]);

    const showSecret = (email) => {
        const secret = generateRandomHSecret();
        setSecret(secret);
        if (email) {
            QRCode.toDataURL(`otpauth://totp/${email}?secret=${secret}&issuer=Kubrik%2021&algorithm=SHA1&digits=6&period=30`).then(setSrcQRCode);
        } else {
            QRCode.toDataURL(`otpauth://totp/Kubrik%2021?secret=${secret}&issuer=Kubrik%2021&algorithm=SHA1&digits=6&period=30`).then(setSrcQRCode);
        }
    }
    // Handles changes for email and password fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("handleChange", e, name, value)

        setFormData(prev => ({ ...prev, [name]: value }));

        if (formErrors[name]) {
            setFormErrors(prev => ({ ...prev, [name]: null }));
        }
        if (step == 1) {
            const errors1 = (name == "newPassword") ? validateForm(true, value) : {};
            setFormErrors(errors1);
        }
        if (step == 2) {
            const errors2 = (name == "initPassword") ? validateForm(true, value) : {};
            setFormErrors(errors2);
        }
    };
    const handleRememberMe = (isChecked) => {
        console.log("handleRememberMe", isChecked);
        setFormData(prev => ({ ...prev, rememberMe: !formData.rememberMe }));
    }

    const handlePasswordForgotten = ()=>{
        goToStep(0); 
        let email_ = formData?.email;
        setFormData(prev => ({ ...prev, [resetPwdEmail]: email_ }));

    }

    const handleChangeCode = (code_) => {
        setCode(code_);
    }

    const validatePassword = (password, t) => {
        const errors = {};

        const passwordConstraints = {
            length: password.length >= 10 && password.length <= 32,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            digit: /[0-9]/.test(password),
            specialChar: /[!"#$%&'()*\/+\-.,:;<>?`=@^_{|}~]/.test(password)
        };

        if (!passwordConstraints.length) {
            errors.password = t("error.passwordMustContain");
        } else if (!passwordConstraints.uppercase) {
            errors.password = t("error.passwordMustContain");
        } else if (!passwordConstraints.lowercase) {
            errors.password = t("error.passwordMustContain");
        } else if (!passwordConstraints.digit) {
            errors.password = t("error.passwordMustContain");
        } else if (!passwordConstraints.specialChar) {
            errors.password = t("error.passwordMustContain");
        }
        console.log("validatePassword", errors, password, t);
        return errors;
    };

    // Validates form data
    const validateForm = (onChangeComing = false, data) => {
        const errors = {};
        switch (step) {
            case 0:
                if (!formData.resetPwdEmail) { errors.requestPasswordResetToken = t("error.mandatory"); }
                break;
            case 1:
                if (formData.newPassword !== formData.newPasswordCheck && !onChangeComing) {
                    errors.newPasswordCheck = t("error.passwordNotEquals");
                }

                const newPasswordErrors = (onChangeComing && data) ? validatePassword(data, t) : validatePassword(formData.newPassword, t);
                newPasswordErrors.password && (errors.newPassword = newPasswordErrors.password);
                break;

            case 2:
                if (formData.initPasswordCheck !== formData.initPassword && !onChangeComing) {
                    errors.initPasswordCheck = t("error.passwordNotEquals");
                }

                const initPasswordErrors = (onChangeComing && data) ? validatePassword(data, t) : validatePassword(formData.initPassword, t);
                initPasswordErrors.password && (errors.initPassword = initPasswordErrors.password);
                break;

            case 4:
                if (!formData.email) errors.email = t("error.mandatory");
                if (!formData.password) errors.password = t("error.mandatory");
                break;
            case 5:
                const isCodeComplete = code.length == 6;
                if (!isCodeComplete) {
                    errors.code = t("error.mandatory");
                    break;
                }
            case 6:
                if (!formData.email) errors.email = t("error.mandatory");
                if (!formData.tempPassword) errors.tempPassword = t("error.mandatory");
                return {};
            default:
                break;
        }
        return errors;

    };



    const handleSubmit = async (event) => {
        setFormErrors({});
        setFormSuccess({});
        setLoadingResponse(true);
        switch (step) {
            case 0:
                setLoadingResponse(true);

                //requestPasswordResetToken
                console.log("resetPassword Done formData: ", formData);
                const errors0 = validateForm();
                if (Object.keys(errors0).length === 0) {

                    // if (Object.keys(errorRequestPasswordResetToken).length === 0) {
                    //console.log("resetPassword formData: ", formData);
                    // const result = await requestPasswordResetToken(formData.email);
                    const { success, error: errorRequestPasswordResetToken } = await requestPasswordResetToken(formData.resetPwdEmail);
                    if (success) {
                        setFormSuccess({ checkMail: true });
                        setLoadingResponse(false);
                        console.log('success');
                        //nextStep(); // Appelez votre fonction nextStep ici
                    } else {
                        setFormErrors({ errorsBackEnd: errorRequestPasswordResetToken });
                        console.log('error');

                    }
                } else {
                    setFormErrors(errors0);
                }
                return {}
            case 1:
                setLoadingResponse(true);

                console.log("resetPassword Done formData: ", formData);
                const errors1 = validateForm();
                if (Object.keys(errors1).length === 0) {
                    setLoadingResponse(true);
                    const { success, error: errorPasswordReset } = await passwordReset(resetToken, formData.newPassword);
                    console.log("result", success, errorPasswordReset);

                    if (success) {
                        setFormSuccess({ passwordReset: true });
                        setLoadingResponse(false);
                        setFormData(prev => ({ ...prev, newPassword: '', newPasswordCheck: '' }));
                        goToStep(4);
                    } else {
                        setFormErrors({ errorsBackEnd: errorPasswordReset });
                        setLoadingResponse(false);
                    }
                } else {
                    console.log("errors1", errors1);
                    setFormErrors(errors1);
                }
                console.log("code non lu l231")
                setLoadingResponse(false);
                return {};

            case 2:
                setLoadingResponse(true);

                console.log("create account formData: ", formData);
                setLoadingResponse(true);
                const errors2 = validateForm();
                if (Object.keys(errors2).length === 0) {
                    const { success, error: errorChangePassword } = await changePassword(formData.initPassword);
                    console.log("result 1", success, "error", errorChangePassword);

                    if (success) {
                        setMustChangePassword(false);
                        if (mustSetupOTP) {
                            showSecret(formData.savedEmail);
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, ['initPasswordCheck']: '', ['initPassword']: '' }));
                            goToStep(3);
                        } else {
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, ['initPasswordCheck']: '', ['initPassword']: '' }));
                            goToStep(5);
                        }

                    } else {
                        console.log("result", success, errorChangePassword)
                        setFormErrors({ errorsBackEnd: errorChangePassword });
                    }

                } else {
                    setFormErrors(errors2);
                }
                setLoadingResponse(false);
                return {}
            case 3:
                setLoadingResponse(true);
                setSetUpOtpInProgress(true);
                setLoadingResponse(false);
                goToStep(5);
                return {}
            case 4:
                //basic authentication 
                setLoadingResponse(true);

                const errors4 = validateForm();
                if (Object.keys(errors4).length === 0) {
                    const { data: result, error: errorLogin } = await login(formData.email, formData.password, formData.rememberMe);

                    console.log("result : ", result, "errorLogin : ", errorLogin);
                    if (result) {

                        setMustSetupOTP(result.mustSetupOTP);
                        setMustChangePassword(result.mustChangePassword);
                        loginWeakToken(result.authToken);

                        if (result.mustChangePassword) {
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, savedEmail: formData.email, email: '', password: '' }));
                            goToStep(2);
                        } else if (result.mustSetupOTP) {
                            showSecret(formData.email);
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, savedEmail: formData.email, email: '', password: '' }));
                            goToStep(3);
                        } else {
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, savedEmail: formData.email, email: '', password: '' }));
                            goToStep(5);
                        }


                    } else {
                        setFormErrors({ errorsBackEnd: errorLogin });
                        setLoadingResponse(false);

                    }
                } else {
                    setFormErrors(errors4);
                    setLoadingResponse(false);
                }
                return {}
            case 5:
                //otp input
                setLoadingResponse(true);

                const errorsBackEnd = {};
                console.log("arrive in step 5 setUpOtpInProgress= ", setUpOtpInProgress);
                let globalResult;
                if (setUpOtpInProgress) {
                    //call setUpOtp to get strong Token
                    setMustSetupOTP(false);
                    console.log("configure OTP formData: ", formData);

                    // const otp = formData.otp; // Assurez-vous que `formData` contient le code OTP
                    const { data: result, error: errorLoginOTP } = await setUpOtp(secret, code);
                    console.log("1", result, errorLoginOTP);
                    if (!result) {
                        // errorsBackEnd.otp = errorLoginOTP || t("error.otpLoginFailed");
                        console.log("error bacl end at step 5 handle submit with setUpOtpInProgress==true");
                        setFormErrors({errorsBackEnd: errorLoginOTP });
                        
                        setLoadingResponse(false);
                        return {};
                    } else {
                        globalResult = result;
                    }

                } else {
                    //call loginOtp to get strong Token
                    const errors5 = validateForm();
                    if (Object.keys(errors5).length === 0) {
                        // const errorsBackEnd = {};
                        const { data: result, error: errorLoginOTP } = await loginOTP(code, JSON.parse(getCookie('user')).weakToken);
                        console.log("2", result, errorLoginOTP);

                        if (!result) {
                            // errorsBackEnd.errorsBackEnd = errorLoginOTP || t("error.otpLoginFailed");
                            console.log("error bacK end at step 5 handle submit with setUpOtpInProgress==false");
                            // setFormErrors(errorsBackEnd);
                            setFormErrors({errorsBackEnd: errorLoginOTP });

                            setLoadingResponse(false);
                            return {};
                        } else {
                            globalResult = result;
                        }
                    } else {
                        setFormErrors(errors5);

                    }
                }
                console.log(globalResult);
                if (globalResult.authToken) {
                    // Once the strong token is obtained, fetch user info with GraphQL
                    const { data: currentUser, error: errorCurrentUser } = await getCurrentUser(globalResult.authToken);

                    console.log('Current User :', currentUser);

                    if (currentUser) {
                        loginStrongToken(
                            globalResult.authToken,
                            currentUser.role.privileges,
                            currentUser.firstname,
                            currentUser.surname,
                            currentUser.uuid,
                            currentUser.company,
                            currentUser.language,
                            formData.rememberMe)
                        // Check privileges and navigate accordingly
                        setLoadingResponse(false);

                        if (currentUser.role.privileges.some(priv => priv.name.startsWith('ADMIN_'))) {
                            navigate('/consultant');
                        } else if (currentUser.role.privileges.some(priv => priv.name.startsWith('USER_'))) {
                            navigate('/administration');
                        }
                    } else {
                        setCode('');
                        setFormErrors({ errorsBackEnd: errorCurrentUser });

                        // errorsBackEnd.currentUser = errorCurrentUser || t("error.failedToFetchUserInfo");
                    }
                } else {
                    // errorsBackEnd.otp = t("error.unknownError");
                    setFormErrors({ errorsBackEnd: t("error.unknownError") });

                }
                console.log("errorsBackEnd", errorsBackEnd);
                // setFormErrors(errorsBackEnd);
                setLoadingResponse(false);
                return {};
            case 6:
                //premiere authentification
                setLoadingResponse(true);

                const errors6 = validateForm();
                if (Object.keys(errors6).length === 0) {
                    //appel à la fonction back end login
                    const { data: result, error: errorLogin } = await login(formData.email, formData.tempPassword, formData.rememberMe);

                    !errorLogin && console.log("result : ", result, "errorLogin : ", errorLogin, "result.mustChangePassword", result.mustChangePassword, "result.mustSetupOTP", result.mustSetupOTP);
                    if (result) {

                        setMustSetupOTP(result.mustSetupOTP);
                        setMustChangePassword(result.mustChangePassword);

                        //stocke le token de login
                        loginWeakToken(result.authToken);

                        // Réinitialiser les champs email et password après connexion réussie
                        if (result.mustChangePassword) {
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, savedEmail: formData.email, email: '', tempPassword: '' }));
                            goToStep(2);
                        } else if (result.mustSetupOTP) {
                            showSecret(formData.email);
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, savedEmail: formData.email, email: '', tempPassword: '' }));
                            goToStep(3);
                        } else {
                            setLoadingResponse(false);
                            setFormData(prev => ({ ...prev, savedEmail: formData.email, email: '', tempPassword: '' }));
                            goToStep(5);
                        }

                    } else {
                        // const errorsBackEnd = {};
                        // errorsBackEnd.tempPassword = errorLogin || t("error.loginFailed");
                        setFormErrors({errorsBackEnd: errorLogin|| t("error.loginFailed")});
                        
                        setLoadingResponse(false);

                    }
                } else {
                    setFormErrors(errors6);
                    setLoadingResponse(false);

                }
                return {}
            default:
                setLoadingResponse(false);

                return {};
        };

    };

    // Advances to the next step
    const nextStep = () => {
        setStep(prev => prev + 1);
        setFormErrors({})
    };
    const goToStep = (step) => {
        setStep(step);
        setFormErrors({})
    };

    const prevStep = () => {
        setStep(prev => {
            switch (prev) {

                case 5:
                    // Vider le champ code
                    setCode('');
                    // Effacer le cookie weakToken
                    eraseCookie('user');
                    break;
                default:
                    console.log('prevStep() : Unknown step:', prev);
            }

            return prev > 0 ? prev - 1 : 0;
        });
    };

    // useEffect(() => {
    //     firstConnection ? goToStep(6) : goToStep(4);

    // }, [firstConnection]);


    return {
        step,
        formData,
        formErrors, formSuccess,
        code,
        handleChange,
        handleChangeCode,
        handleSubmit,
        nextStep,
        prevStep,
        goToStep,
        srcQRCode,
        loadingResponse,
        countdown, 
        handleRememberMe, 
        handlePasswordForgotten
    };
};

export default useConnectionForm;
