import React, { useRef, useState, useEffect } from "react";
import Leasing from "@/components/macro-organisms/tabs/consultant-vision-user/leasing/Leasing";
import useLeasingData from "./useLeasingData";
import NewLeasingPanel from '@/components/macro-organisms/panels/panels-admin/newLeasing/NewLeasingPanel';
import { Toaster } from '@/components/organisms/feedback/toaster';
import { useLeasingInvoices } from '@/services/consultant-services/query/useLeasingInvoices';
import InViewComponent from '@/components/organisms/InViewComponent';
import { useParams } from 'react-router-dom';
import { useConsultantLeasingInvoices } from '@/services/admin-services/query/useConsultantLeasingInvoices';
import PrintEmptyDataMessage from "@/components/molecules/PrintEmptyDataMessage";

import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Button } from "@/components/molecules/buttons/button";

export default function LeasingTab() {
    // const { leasings, error } = useLeasingData();
    const [lastSlice, setLastSlice] = useState(1);
    const { id } = useParams();

    const [leasingsCopy, setLeasingsCopy] = useState([]);
    const { loading, error, data: leasingInvoices, refetch } = useConsultantLeasingInvoices(id, lastSlice);


    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setLeasingsCopy([]);
        refetch(n);
        console.log(n)
    };

    const fetchNewSlice = () => {
        // console.log("fetchNewSlice", leasingInvoices?.hasNext, lastSlice, "loading =", loading, error, "leasingInvoices =", leasingInvoices, "setLeasingsCopy =", leasingsCopy)
        if (!loading && leasingsCopy.length > 0 && leasingInvoices?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };
    useEffect(() => {
        // console.log("useEffect leasingsCopy",leasingsCopy, "leasingInvoices", leasingInvoices, "leasingsCopy?.length", leasingsCopy?.length, leasingInvoices?.leasings?.length)
        leasingsCopy?.length < leasingInvoices?.leasings?.length && setLeasingsCopy((prevLeasing) => [
            ...prevLeasing,
            ...leasingInvoices.leasings
        ]);
    }, [leasingsCopy]);
    // Concaténation des nouvelles données avec les anciennes
    useEffect(() => {
        // console.log("useEffect", "leasingInvoices", leasingInvoices, "loading", loading)
        if (!loading && leasingInvoices) {

            // Mettre à jour la copie des dépenses avec les nouvelles données
            setLeasingsCopy((prevLeasing) => [
                ...prevLeasing,
                ...leasingInvoices.leasings
            ]);
        }
    }, [leasingInvoices, loading]);

    // Requête initiale lors du changement d'ID
    useEffect(() => {
        refetchFromScratch();
    }, [id]);

    // TODO clean des taux de tva, clean du form is send, reset du consultant selectionné 
    return (
        <div className="flex flex-col items-end gap-secondDegree overflow-auto pb-secondDegree px-1">
            <div className="flex gap-secondDegree">
                <Toaster />
                <Button style="ghost" disabled={loading} showLeftIcon leftIcon={<ReplayRoundedIcon />} onClick={() => refetchFromScratch()} showText={false} sizePadding={"firstDegree"} />

                <NewLeasingPanel global={false} refetchFromScratch={refetchFromScratch} />
            </div>
            {loading && <div className="w-full flex justify-center"><span className="loader " /></div>}

            {(!error && !loading && leasingsCopy) && leasingsCopy.map((item, index) => (
                <Leasing key={index} data={item} dataOwner={true} refetchFromScratch={refetchFromScratch} />
            ))}

            {!error && !loading &&
                leasingsCopy?.length == 0 &&
                <PrintEmptyDataMessage message="error.emptyDataLeasing" />}
            <InViewComponent inView={fetchNewSlice} />

        </div>
    )
}
