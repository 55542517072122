import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";

const GET_LEASING_INVOICES = gql`
  query LeasingInvoices($sliceNumber: Int!) {
    leasingInvoices(leasingInvoicesRequest: { sliceNumber: $sliceNumber }) {
       leasings {
        uuid
        leasingInvoiceFileUuid
        year
        month
        invoiceDate
        amountExcludingVat
        totalAmount
        comment
        vatList {
          vatRate
          vatAmount
        }
      }
      hasNext
    }
  }
`;

export const useLeasingInvoices = (sliceNumber) => {
  const { user } = useAuth();

  const { loading, error, data , refetch} = useQuery(GET_LEASING_INVOICES, {
    variables: { sliceNumber },
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch(sliceNumber)
  }, [sliceNumber]);

  return { loading, error, data: data?.leasingInvoices, refetch };
};
