// src/apolloClient.js
import {
    ApolloClient,
    InMemoryCache,

    HttpLink,
    from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import Logout from "./pages/logout/logout";
import useLogout from "./pages/logout/uselogout";
import { eraseCookie } from "./utils/cookie";
//  import { NetworkConnectionLost, setErrorNetworkVisible} from "./components/organisms/feedback/NetworkConnectionLost";

const handleSetErrorNetworkVisible = (isError)=>{
    // const {setErrorNetworkVisible,errorNetworkVisible} = NetworkConnectionLost();
    // const err = setErrorNetworkVisible(isError)
    // console.log("Graphql setErrorNetworkVisible isError = ", isError, "err = ", err);

}

const errorLink = onError(({ graphqlErrors, networkError }) => {


    if (graphqlErrors) {
        graphqlErrors.map(({ message, location, path }) => {
            alert(`Graphql error ${message}`);
            console.error(`GraphQL error: ${message}`);
            console.error(`Error location: ${location.map((loc) => `Line ${loc.line}, Column ${loc.column}`).join(", ")}`);
            console.error(`Error path: ${path.join(" -> ")}`);
        });
    }

    if (networkError) {
        console.log("Graphql networkError", networkError);
        // handleSetErrorNetworkVisible(true);

        // internet deconnected
        if (networkError.statusCode == 500) {
            alert(`You've been deconnected, please login again`);
            // eraseCookie('user');

        }
        // user token is not valid, graphql return forbidden error
        if (networkError.statusCode == 403) {
            // alert(`You've been deconnected, please login again`);
            eraseCookie('user');

        }
        if (networkError.message  === "Failed to fetch") {
            // alert(`You've been deconnected, please login again`);
            //  setErrorNetworkVisible(true);
            //TODO affichage du bandeau en cas de mauvaise connexion
            console.log("Graphql no internet ");
        }

        console.log(`Graphql no internet ${networkError}`, networkError.message === "Failed to fetch", networkError.statusCode)

    }


});

const link = from([
    errorLink,
    new HttpLink({
        uri: window.ENV.BACKEND_URL+"/api/graphql",
    }),
]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
});

export default client