import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import PrivateRoute from "./router/PrivateRoute";
import Login from "@/pages/login/login";
import Consultant from "./pages/admin/consultant/consultant";
import Administration from "./pages/consultant/administration/administration";
import OverflowOptimization from "./pages/admin/overflow-optimization/overflowOptimization";
import Statistics from "./pages/admin/statistics/statistics";
import Finance from "./pages/admin/finance/finance";
import ProfitSharing from "./pages/consultant/profit-sharing/profitSharing";
import CompanyValuation from "./pages/consultant/company-valuation/companyValuation";
import Overflow from "./pages/consultant/overflow/overflow";
import ErrorPage from "./error404";
import NavBar from "./components/organisms/navigation/header/Navbar";
import RootRedirect from "./router/rootRedirect";
import Logout from "./pages/logout/logout";
import { ConsultantProvider } from "./context/ConsultantContext";
import ComponentTest from "./components/ui/ComponentTest";
import ConsultantIndex from "./components/macro-organisms/tabs/consultants-vision-admin/ConsultantIndex";
import StatementTab from "./components/macro-organisms/tabs/consultants-vision-admin/statement/StatementTab";
import InformationTab from "./components/macro-organisms/tabs/consultants-vision-admin/information/InformationTab";
import DetailsAboutConsultant from "./components/macro-organisms/tabs/consultants-vision-admin/DetailsAboutConsultant";
import StatementTabUser from "./components/macro-organisms/tabs/consultant-vision-user/statement/StatementTabUser";
import InformationTabUser from "./components/macro-organisms/tabs/consultant-vision-user/information/InformationTabUser";
import CVTab from "./components/macro-organisms/tabs/consultants-vision-admin/cv/cvTab";
import GenerateCV from "./components/macro-organisms/tabs/consultants-vision-admin/cv/GenerateCV";
import CVTabUser from "./components/macro-organisms/tabs/consultant-vision-user/cv/cvTabUser";
import SkillsPage from "./components/macro-organisms/tabs/consultants-vision-admin/skills/SkillTab";
import SkillsPageConsultant from "./components/macro-organisms/tabs/consultant-vision-user/skills/SkillTabUser";
import ExpenseTabUser from "./components/macro-organisms/tabs/consultant-vision-user/expense-account/ExpenseTabUser.jsx";
import ExpenseTab from "./components/macro-organisms/tabs/consultants-vision-admin/expense-account/ExpenseTab";
import useMobileDetect from "./utils/useMobileDetect";
import ErrorMobile from "./errorMobile";
import ConnectionForm from "./components/organisms/forms/ConnectionForm";
import LeasingTabUser from "./components/macro-organisms/tabs/consultant-vision-user/leasing/LeasingTabUser";
import PayslipTabUser from "./components/macro-organisms/tabs/consultant-vision-user/payslip/payslipTabUser";
import LeasingTab from "./components/macro-organisms/tabs/consultants-vision-admin/leasing/LeasingTab";
import PayslipTab from "./components/macro-organisms/tabs/consultants-vision-admin/payslib/PayslipTab";
import TimesheetTab from "./components/macro-organisms/tabs/consultants-vision-admin/timesheet/TimesheetTab";
import TimesheetTabUser from "./components/macro-organisms/tabs/consultant-vision-user/timesheet/TimesheetTabUser";
import Prospects from "./pages/admin/prospects/Prospects";
import ProspectsHome from "./pages/admin/prospects/ProspectsHome";
import CVProspect from "./pages/admin/prospects/CVProspect";
import { Toaster } from "./components/organisms/feedback/toaster";
import { Label } from "./components/atoms/texts/label";
import { NetworkConnectionLost } from "./components/organisms/feedback/NetworkConnectionLost";
import { NotificationProvider } from "./hooks/global-hooks/NotificationContext";


const App = () => {
    const isMobile = useMobileDetect();



    if (isMobile) {
        // Affiche une page d'erreur ou un composant spécifique pour les mobiles
        return (
            <Router>
                <AuthProvider>
                    <NotificationProvider>

                        <NavBar />
                        <Routes>
                            <Route path="*" element={<ErrorMobile />} />
                        </Routes>
                    </NotificationProvider>
                </AuthProvider>

            </Router>
        );
    }

    return (
        <Router>
            <AuthProvider>
                <NotificationProvider>

                    {/* <NetworkConnectionLost /> */}
                    <Toaster />
                    <NavBar />
                    <Routes>
                        {/* Phase de développement uniquement */}
                        <Route path="/test" element={<ComponentTest />} />
                        <Route path="/login" element={<Login />}>
                            <Route index element={<ConnectionForm />}></Route>
                        </Route>

                        <Route path="/activate" element={<Login />}>
                            <Route index element={<ConnectionForm />}></Route>
                            <Route path=":activationToken" element={<ConnectionForm firstConnection={true} />}></Route>
                        </Route>
                        <Route path="/resetPassword" element={<Login />}>
                            <Route index element={<ConnectionForm />}></Route>
                            <Route path=":resetToken" element={<ConnectionForm resetPassword={true} />}></Route>
                        </Route>
                        <Route path="/logout" element={<Logout />} />

                        {/* Gère la redirection à la racine */}
                        <Route path="/" element={<RootRedirect />} />{" "}

                        {/* For admin vision */}
                        <Route path="/consultant" element={<PrivateRoute element={<ConsultantProvider><Consultant /></ConsultantProvider>} requiredPrivilege={["ADMIN_"]} />}>
                            <Route index element={<ConsultantIndex />} />
                            <Route path=":id" element={<DetailsAboutConsultant />}>
                                <Route path="statement" element={<StatementTab />} />
                                <Route path="information" element={<InformationTab />} />
                                <Route path="cv">
                                    <Route index element={<CVTab />} />
                                    <Route path="generate" element={<GenerateCV />} />
                                    <Route path="generate/:templateID" element={<GenerateCV />} />
                                </Route>
                                <Route path="payslip" element={<PayslipTab />} />
                                <Route path="timesheet" element={<TimesheetTab />} />
                                <Route path="skills" element={<SkillsPage />} />
                                <Route path="expense" element={<ExpenseTab />} />
                                <Route path="leasing" element={<LeasingTab />} />
                            </Route>
                        </Route>
                        <Route path="/overflowOptimization" element={<PrivateRoute element={<OverflowOptimization />} requiredPrivilege={["ADMIN_"]} />} />
                        <Route path="/statistics" element={<PrivateRoute element={<Statistics />} requiredPrivilege={["ADMIN_"]} />} />
                        <Route path="/finance" element={<PrivateRoute element={<Finance />} requiredPrivilege={["ADMIN_"]} />} />
                        <Route path="/prospects" element={<PrivateRoute element={<Prospects />} requiredPrivilege={["ADMIN_"]} />} >
                            <Route index element={<ProspectsHome />} />
                            <Route index path="cv" element={<ProspectsHome />} />
                            <Route path="cv/:prospectID" element={<CVProspect />} >

                                <Route index element={<CVTab isForProspect />} ></Route>
                                <Route path="generate" element={<GenerateCV isForProspect />} />
                                <Route path="generate/:templateID" element={<GenerateCV isForProspect />} />


                            </Route>
                            {/* <Route path="cv/:prospectID" element={<CVProspect />} /> */}

                        </Route>

                        {/* For consultant vision */}
                        <Route path="/administration" element={<PrivateRoute element={<Administration />} requiredPrivilege={["USER_"]} />}>
                            <Route index path="statement" element={<StatementTabUser />} />
                            <Route path="information" element={<InformationTabUser />} />
                            <Route path="cv">
                                <Route index element={<CVTabUser />} />
                                <Route path="generate" element={<GenerateCV />} />
                                <Route path="generate/:templateID" element={<GenerateCV />} />
                            </Route>
                            <Route path="payslip" element={<PayslipTabUser />} />
                            <Route path="skills" element={<SkillsPageConsultant />} />
                            <Route path="expense" element={<ExpenseTabUser />} />
                            <Route path="leasing" element={<LeasingTabUser />} />
                            <Route path="timesheet" element={<TimesheetTabUser />} />
                        </Route>
                        <Route path="/profitSharing" element={<PrivateRoute element={<ProfitSharing />} requiredPrivilege={["USER_"]} />} />
                        <Route path="/companyValuation" element={<PrivateRoute element={<CompanyValuation />} requiredPrivilege={["USER_"]} />} />
                        <Route path="/overflow" element={<PrivateRoute element={<Overflow />} requiredPrivilege={["USER_"]} />} />

                        {/* Errors pages */}
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </NotificationProvider>
            </AuthProvider>
        </Router>
    );
};

export default App;
