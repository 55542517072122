/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Label } from '../../../atoms/texts/label';
import CreateIcon from '@mui/icons-material/Create';
import useDoubleClick from 'use-double-click';
import { useRef, useEffect } from 'react';
import useEditableItemOverview from './useEditableItemOverview';
import { useAuth } from '@/context/AuthContext';

 

function EditableItemOverview({ initialValue, labelMessage }) {
    const {
    editing,
    newValue,
    isHovered,
        divRef,
  
    handleMouseEnter,
    handleMouseLeave,
    handleCheck,
    handleValueEnter,
        handleStartEditing,
        displayWarningToast,
    handleToast
    
    } = useEditableItemOverview(initialValue);
    const { user } = useAuth();
    

 
    return (
        
            <div className={ `${editing ? ' border-5': ' border-dashed'}  ${user.role!='admin' ? 'h-[115px] ': 'h-[102px]'} bg-horizontalPrimaryLinear editable divParent rounded-sm    py-[8px] border-[5px] border-primary relative`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={divRef} 
                
            >
        {/* first div for icons, show icons if role is admin*/}
            {(user.role == 'admin') && (<div className={`${editing ? 'bg-primary':''} flex-row right-0  w-[30px] h-[30px] rounded-sm absolute px-1 top-0   `}>
                    {(editing) && <CheckIcon
                        className='text-secondary absolute'
                        onClick={handleCheck}
                                    />}
                    {(!editing &&isHovered )&& <CreateIcon className='text-secondary' onClick={handleStartEditing} />}
                   
                </div>)
        }
                 
                <div className='px-[4px]'>
                    
                {/* Second div for number and caption */}
                <div className='flex flex-col items-center gap-2 py-paddingSmallLeftAndRight justify-center w-full'>
                    <div className={`${editing ? '' :''} w-full  flex items-center justify-center`}>
                         <input
                          
                            //defaultValue={initialValue}
                            onBlur={  handleToast}
                            value={newValue ? newValue: 0}
                            readOnly={!editing}
                            onChange={(e) => handleValueEnter(e)}
                            onFocus={() => console.log('focus')}
                            className={`${editing ? 'w-[80%]' :'outline-none  w-[100%]'}  grow truncate ... bg-transparent  px-secondDegree  max-w-[200px]  h-[40px] text-center text-[20px] 2xl:text-[20px] font-bold text-white editableItem`}
                            />
                    </div>
                   
                    <Label size="" fontWeight="" fontFamily="" message={labelMessage} className="text-white labelforOverview  text-h8 font-bold  truncate "/>
                </div>
                 </div>
            </div>
        
     )
}
export default EditableItemOverview;
