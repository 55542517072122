/**
 * @component MultiFileUploadZone
 *
 * The `MultiFileUploadZone` component provides an interface for uploading multiple files using a drag-and-drop zone. It includes functionality for handling file selection, uploading, and displaying errors or status messages. This component leverages a custom hook for handling file uploads and manages various states related to file processing.
 *
 * Key Features:
 * - Allows users to drag and drop or select multiple files for upload.
 * - Handles file upload through a custom hook and displays loading, error, and success states.
 * - Provides feedback to users about the status of their upload and any errors encountered.
 *
 * Props:
 * - @param {Boolean} hideFileComponent - Boolean to control the visibility of the file upload component.
 * - @param {Function} setHideFileComponent - Function to set the state of whether to hide the file upload component.
 * - @param {Function} setIsDoingAction - Function to indicate that an action (such as file upload) is in progress.
 *
 * State:
 * - @variable {Array} files - Array of files selected for upload.
 * - @variable {String|null} fileError - Error message related to file upload, if any.
 * - @variable {Object} uploadCV - Function to handle file upload, provided by the custom hook `useUploadCV`.
 * - @variable {Boolean} loading - Boolean indicating if the upload is in progress.
 * - @variable {Object|null} error - Error object, if any, returned by the upload process.
 * - @variable {Object|null} response - Response object from the upload process.
 *
 * Usage:
 * This component is used to handle file uploads, providing users with a drag-and-drop area for selecting files and a button to initiate the upload process. It integrates with a custom hook for managing uploads and provides visual feedback based on the upload status.
 *
 * Example:
 * <MultiFileUploadZone
 *   hideFileComponent={hideFileComponent}
 *   setHideFileComponent={setHideFileComponent}
 *   setIsDoingAction={setIsDoingAction}
 * />
 *
 * Dependencies:
 * - `DragAndDropZone` for the drag-and-drop file upload interface.
 * - `Button` for triggering the upload action.
 * - `useUploadCV` custom hook for handling the file upload process.
 * - `Label` for displaying error messages.
 *
 * Where it is used:
 * - @component AddProspectsPanel
 
 */


import React, { useEffect, useState } from "react";
import DragAndDropZone from "@/components/organisms/forms/DragAndDropZone";
import { Button } from "@/components/molecules/buttons/button";
import useUploadCV from "@/services/admin-services/useAnalyzeCV"; // Assurez-vous que le chemin est correct
import { Label } from "@/components/atoms/texts/label";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";

const MultiFileUploadZone = ({ hideFileComponent, setHideFileComponent, setIsDoingAction }) => {
    const [files, setFiles] = useState([]);
    const [fileError, setFileError] = useState();
    // const [fileErrors, setFileErrors] = useState({});

    const { uploadCV, loading, error, response } = useUploadCV();

    const handleUpload = async () => {
        let errors = {}
        console.log("handleUpload")
        if (files.length > 0) {
            //TODO : taille max
            await files.forEach(async (file) => {
                const { data, error } = await uploadCV(file);
                // errors = (...es, error);
                console.log("handleUpload", "data", data, "error", error)

            });
            // setFileError(errors);


        } else {

            setFileError("Please select at least one file to upload.");
        }
        console.log("handleUpload errors =", errors)

        // files.length > 0 && setHideFileComponent && setHideFileComponent(true);
        // setIsDoingAction(true)
    };

    const handleFileChange = (newFiles) => {
        setFiles([...files, ...newFiles]); // Ajouter les nouveaux fichiers à la liste existante
    };
    useEffect(() => {
        if (response) {
            setIsDoingAction(true)
            setHideFileComponent(true);
        }
    }, [response]);
    // useEffect(() => {
    //     setFileErrors(...fileErrors, error);
    // }, [error]);
    return (
        <>
            {!hideFileComponent && (
                <div className="flex flex-col gap-secondDegree p-thirdDegree">
                    <DragAndDropZone
                        file={files} // Passer la liste des fichiers
                        setFile={handleFileChange} // Fonction de mise à jour des fichiers
                        fileError={fileError}
                        isMultifiles={true}
                    />
                    <Button
                        showLeftIcon={true}
                        showRightIcon={true}
                        text="global.actions.generate"
                        sizePadding="default"
                        style="ghost"
                        onClick={handleUpload}
                        disabled={loading}
                    />
                    {loading && <span className="loader"></span>}
                    {/* {fileError && <Label message="error.errorCvNotUploaded" color="error"/>} */}
                    {/* {error && <p className="text-red-500">Error: {error.detail}</p>} */}
                    {(error) && <PrintErrorMessage error={error} />}
                    {/* {(fileErrors) && <PrintErrorMessage error={fileErrors} />} */}
                    {(fileError) && console.log("fileErrors", fileError)}
                    {response && <p className="text-green-500"> {response.status}</p>}
                    {/* {response && response} */}
                </div>
            )}
        </>
    );
};
//TODO tester la gestion des erreurs et le comportement

export default MultiFileUploadZone;
