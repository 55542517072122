/**
 * @component ShowAllCvProcessing
 *
 * The `ShowAllCvProcessing` component displays the status of file processing, showing real-time updates of the state of each file being processed. It provides visual feedback through icons and labels, and periodically fetches the latest status of files.
 *
 * Props:
 * - @param {Function} setIsDoingAction - Function to indicate that an action (such as fetching data) is in progress.
 * - @param {String} actualDate - The date in ISO format used to filter the files for processing.
 *
 * State:
 * - @variable {Object} data - Data containing the status of the files, retrieved from the `useAllCvProcessing` hook.
 * - @variable {Boolean} loadingAllCvProcessing - Boolean indicating if the data is currently being fetched.
 * - @variable {Object} errorAllCvProcessing - Error object if an error occurred during the data fetch.
 *
 * Effects:
 * - Fetches data when the component mounts and periodically every second.
 * - Updates `setIsDoingAction` to indicate that an action is in progress.
 *
 * Usage:
 * This component is used to show the processing status of CV files. It displays a list of files with their current processing status, including icons for failure, in-progress, and completion states. The component periodically updates its state to reflect the latest information.
 *
 * Example:
 * <ShowAllCvProcessing
 *   setIsDoingAction={setIsDoingAction}
 *   actualDate={actualDate}
 * />
 *
 * Dependencies:
 * - `useAllCvProcessing` custom hook for fetching the processing data.
 * - `PrintErrorMessage` for displaying error messages.
 * - `PrintInformationMessage` for displaying information messages.
 * - `HighlightOffTwoToneIcon` and `CheckCircleTwoToneIcon` from `@mui/icons-material` for status indicators.
 * - `Chip` for displaying file status as a badge.
 * - `Label` for displaying text and labels.
 *
 * Where it is used:
 
 * - @component  MultiFileUploadZone
 */


import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage'
import useAllCvProcessing from '@/services/admin-services/query/useAllCvProcessing'
import React, { useEffect } from 'react'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Chip from '@/components/molecules/buttons/Chip';
import { Label } from '@/components/atoms/texts/label';
import PrintInformationMessage from '@/components/organisms/feedback/PrintInformationMessage';
const FileStates = {
    FAILED: "global.information.failed",
    IN_PROGRESS: "global.information.inProgress",
    COMPLETED: "global.information.completed"
}

export default function ShowAllCvProcessing({setIsDoingAction, actualDate }) {
  const { data, refetch, loadingAllCvProcessing, errorAllCvProcessing } =useAllCvProcessing(actualDate);

  useEffect(() => {
    refetch();
    setIsDoingAction(true)
  }, [loadingAllCvProcessing]);

  useEffect(() => {
    // Créer un intervalle qui exécute la fonction refetch toutes les 5 secondes
    const interval = setInterval(() => {
      setIsDoingAction(true); 
      refetch();
      // Exécute la fonction refetch toutes les 5 secondes
    }, 1000); // 5000 ms = 5 secondes
 setIsDoingAction(true);
    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
   
  }, [refetch]);

  useEffect(()=>{
  setIsDoingAction(true)
  },[])

  if (loadingAllCvProcessing) return <div className="flex w-full flex-row items-center p-[250px]">  <span className="loader"></span>;</div>
   
  if (errorAllCvProcessing)
    return <PrintErrorMessage error={errorAllCvProcessing} />;

  return (
    <div className="w-full  p-secondDegree ">
      {/* <div className="w-full p-firstDegree border-dashed border-[4px] border-blue bg-information rounded-sm ">
        {" "}
        <Label message="prospects.youCanClose" />
      </div> */}
      <PrintInformationMessage information="prospects.youCanClose"/>
      <div className="w-full grid grid-cols-6  p-secondDegree gap-[12px] items-center h-auto ">
        <Label fontWeight="bold" message="global.information.status" />
        <div className="col-span-4 ">
          <Label message="global.information.file" fontWeight="bold" />
        </div>
        <div className="col-span-1 ">
          <Label message="global.information.completion" fontWeight="bold" />
        </div>
      </div>
      {console.log("cv data", data)}
      {data &&
        data.map((cv) => (
          <div
            key={cv.uuid}
            className=" grid grid-cols-6 py-secondDegree gap-[12px] items-center"
          >
            <div className=" justify-center flex">
              {cv.status == "FAILED" && (
                <HighlightOffTwoToneIcon sx={{ color: "#F9A3A3" }} />
              )}
              {cv.status == "IN_PROGRESS" && (
                <span className="loaderFile "></span>
              )}
              {cv.status == "COMPLETED" && (
                <CheckCircleTwoToneIcon
                  sx={{ color: "#45C299", justify: "center" }}
                  className="justify-center "
                />
              )}
            </div>

            <span className=" col-span-4 justify-center  text-bodySmall truncate">
              {cv.filename}
            </span>

            <Chip
              item={FileStates[cv.status]}
              // isSelected={true}
              printPercentage={false}
              rounded="large"
              bg={
                cv.status == "COMPLETED"
                  ? "success"
                  : cv.status == "FAILED"
                  ? "error"
                  : "warning"
              }
              //border="outline"
              fontWeight="semibold"
              size="h6"
              className=""
            />
          </div>
        ))}
    </div>
  );
}
