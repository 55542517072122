import React, { useEffect, useState } from "react";
import { Avatar } from "@/components/atoms/avatar";
import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/molecules/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
    DialogClose,
} from "@/components/ui/dialog";

import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import { Toaster } from "@/components/organisms/feedback/toaster"

import { Separator } from '@/components/atoms/divider/separatorShadcn';
import DataDeletion from '@/components/macro-organisms/panels/panels-admin/DataDeletion';
import Copy from '@/components/atoms/Copy';

import NewCooptationPanel from '@/components/macro-organisms/panels/panels-admin/newCooptation/NewCooptationPanel';
import useInformationData from "@/hooks/admin-hooks/useInformationData";
import { useParams } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/organisms/forms/select";
import { useToast } from "@/components/organisms/feedback/use-toast"
import useUpdateUser from "@/services/mutation/admin-mutations/useUpdateUserl"; //IMPORT MUTATION
import useUpdateConsultantProfile from "@/services/mutation/admin-mutations/useUpdateConsultantProfile";
import useAddRetributionModel from "@/services/mutation/admin-mutations/useAddRetributionModel";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";

// import useInformationTab from './UseNewCooptation'

export default function InformationTab() {
    const { id } = useParams();
    const { informationData: data, userError, profilesError, modelsError: retributionErrors, userLoading, profilesLoading, modelsLoading: retributionLoading } = useInformationData(id);
    const [informationData, setInformationData] = useState({});
    // var newUuidRetribution;
    // console.log("informationData = ", informationData);
    const [openRGPD, setOpenRGPD] = React.useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState("");

    const [selectedRetribution, setSelectedRetribution] = useState();
    const [selectedProfile, setSelectedProfile] = useState();
    const [formatedRetribution, setFormatedRetribution] = useState();
    const { toast } = useToast();

    const { setRetribution } = useUpdateUser(); //IMPORT MUTATION
    const { addRetributionModel } = useAddRetributionModel(); //IMPORT MUTATION
    const { setConsultantProfile } = useUpdateConsultantProfile(); //IMPORT MUTATION
    useEffect(() => {
        if (data) {
            // Faire une copie des données et trier `cooptantFor` si nécessaire
            const sortedData = {
                ...data,
                cooptantFor: data.cooptantFor
                    ? [...data.cooptantFor].sort((a, b) => {
                        const dateA = new Date(a.startDate);
                        const dateB = new Date(b.startDate);
                        
                        // Vérifie si les dates sont valides
                        if (isNaN(dateA) || isNaN(dateB)) {
                            console.error('Invalid date detected', a.startDate, b.startDate);
                            return 0; // Si la date n'est pas valide, ne pas changer l'ordre
                        }
                        
                        return dateA - dateB;
                    })
                    : []
            };
    
            // Assigner les données triées à informationData
            setInformationData(sortedData);
        }
    }, [data]);
    

    useEffect(() => {
        informationData && informationData?.retributionModel && setFormatedRetribution(informationData?.retributionModel?.upToFiveConsultants + "/" + informationData?.retributionModel?.upToTenConsultants + "/" + informationData?.retributionModel?.moreThanTenConsultants)
        informationData && informationData?.consultantProfile && setSelectedProfile(informationData?.consultantProfile?.uuid)
        console.log("debug informationData : ",informationData);

    }, [informationData])

    // useEffect(() => {
    //     console.log("uuseeffect newuuidretribution");
    //     informationData && informationData?.uuid && newUuidRetribution && sendData(informationData?.uuid, newUuidRetribution);
    // }, [newUuidRetribution])

    const [isInputValid, setIsInputValid] = useState(true); // État pour suivre la validité de l'input

    const [inputValue, setInputValue] = useState("");

    const validateInput = (value) => {
        const regex = /^\d{1,2}\/\d{1,2}\/\d{1,2}$/; // Expression régulière pour le format 00/00/00
        return regex.test(value);
    };

    const handleInputBlur = (e) => {
        if (!e.target.value) {
            return;
        }
        setSelectedRetribution(e.target.value);
        const isValid = validateInput(e.target.value);
        setIsInputValid(isValid); // Met à jour l'état de validité de l'input
        if (isValid) {
            setInputValue(e.target.value); // Met à jour la valeur sélectionnée si l'input est valide
        }
    };

    const callUpdateRetibutionModel = (selectedRetribution) => { //IMPORT MUTATION

        const sendData = async (uuid_, selectedRetribution_) => {
            const { data, error } = await setRetribution(uuid_, selectedRetribution_);
            if (error) {
                toast({
                    variant: "error",
                    title: "error.requestFailed",
                    duration: 8000,
                    message: error
                })
                console.error("Error updating data:", error);
            } else {
                toast({
                    variant: "success",
                    title: "success.changesSaved",
                    duration: 1500,
                })
                console.log("retributionModel updated data:", data);
                setInformationData((prevData) => ({
                    ...prevData,
                    retributionModel: data.retributionModel
                }));
            }
        };


        const sendDataAddRetributionModel = async () => {
            const [upToFiveConsultants, upToTenConsultants, moreThanTenConsultants] = selectedRetribution.split('/');
            const { data: dataAddRetribution, error: errorAddRetribution } = await addRetributionModel(upToFiveConsultants, upToTenConsultants, moreThanTenConsultants, informationData?.company.uuid);
            if (errorAddRetribution) {
                toast({
                    variant: "error",
                    title: "error.requestFailed",
                    duration: 8000,
                    message: errorAddRetribution
                })
                console.error("Error updating data:", errorAddRetribution);
            } else {
                toast({
                    variant: "success",
                    title: "success.changesSaved",
                    duration: 1500,
                })
                console.log("Cooptations updated data:", dataAddRetribution, errorAddRetribution);
                console.log("BEFORE SEND DATA:", informationData?.uuid, dataAddRetribution.uuid);

                sendData(informationData?.uuid, dataAddRetribution.uuid)
                setInputValue();
                setSelectedRetribution();
            }
        };

        if (inputValue) {
            //creer un nouveau modele 
            (isInputValid) && sendDataAddRetributionModel();
        } else {
            sendData(informationData?.uuid, selectedRetribution);
        }
    }




    const callUpdateConsultantProfile = (selectedProfile) => {

        const sendData = async () => {
            const { data, error } = await setConsultantProfile(informationData?.uuid, selectedProfile);
            if (error) {
                toast({
                    variant: "error",
                    title: "error.requestFailed",
                    duration: 8000,
                    message: error
                })
                console.error("Error updating data:", error);
            } else {
                toast({
                    variant: "success",
                    title: "success.changesSaved",
                    duration: 1500,
                })
                console.log("Cooptations updated data:", data);
                setInformationData((prevData) => ({
                    ...prevData,
                    consultantProfile: data.consultantProfile
                }));
            }
        };

        sendData();
    }

    // const { toast } = useToast()


    const renderCooptingRows = () => {
        

        return informationData?.cooptantFor?.map((cooptingItem, index) => (
            <TableRow key={index}>
                <TableCell className="p-1 text-center">{cooptingItem?.coopted?.firstname + " " + cooptingItem?.coopted?.lastname}</TableCell>
                <TableCell className="p-1 text-center">
                    {informationData?.retributionModel
                        ? index < 5
                            ?
                            <Label >{informationData?.retributionModel?.upToFiveConsultants} %</Label>
                            : index < 10 ?
                                <Label >{informationData?.retributionModel?.upToTenConsultants} %</Label>
                                :
                                <Label >{informationData?.retributionModel?.moreThanTenConsultants} %</Label>
                        : <Label color="gray400" message="error.unfilled" />
                    }

                </TableCell>
                <TableCell className="p-1 text-center align-center">
                    <Label > {cooptingItem?.percentage}%</Label>{" "}
                </TableCell>
                <TableCell className="p-1 text-center">
                    {cooptingItem?.monthsDuration === "∞" ? (
                        cooptingItem?.monthsDuration
                    ) : (
                        <>
                            <Label >{cooptingItem?.monthsDuration}</Label>
                            <Label size="small" color="gray400"> - ({cooptingItem?.startDate})</Label>
                        </>
                    )}
                </TableCell>

            </TableRow>
        ));
    };

    // if (userLoading) {
    //     return <div><span className="loader"></span></div>
    // }

    if (!informationData && !userLoading) {
        return (<>
            {userError && <PrintErrorMessage error={userError} />}
            {!userError && <PrintErrorMessage label='error.errorFetchData' />}
        </>);
    }



    return (
        <div className="flex gap-thirdDegree justify-center ">

            {userLoading && !informationData && <span className="loader my-4"></span>}           
             <Toaster />
            {informationData && <Avatar
                fallback={informationData?.firstname + " " + informationData?.lastname}
                size="medium"
                className="shrink-0 grow-0 "
            />}
            {informationData && <div className="flex flex-col gap-secondDegree grow ">
                <div className="flex justify-between  grow">
                    <Label
                        size="h3"
                        fontWeight="bold"
                        message="consultants.dashboard.tabs.information"
                    />

                    <NewCooptationPanel setInformationData={setInformationData}/>

                </div>
                <div className="flex gap-secondDegree">
                    <div className="flex flex-col grow justify-start gap-secondDegree">
                        {/* <Label className="group">{informationData?.name}<Copy value={informationData?.name} /></Label> */}

                        {/* Email */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.email" />
                            {informationData?.personalEmail ? <Label className="group">{informationData?.personalEmail}<Copy value={informationData?.personalEmail} /></Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* proEmail */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.proEmail" />
                            {informationData?.email ? <Label className="group">{informationData?.email}<Copy value={informationData?.email} /></Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* phoneNumber */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.phoneNumber" />
                            {informationData?.phoneNumber ? <Label className="group">{informationData?.phoneNumber}<Copy value={informationData?.phoneNumber} /></Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* Address */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.address" />
                            {informationData?.addressLine1 || informationData?.addressLine2 || informationData?.zipcode || informationData?.city || informationData?.countryCode ? <Label className="group ">
                                {informationData?.addressLine1},  {informationData?.addressLine2}
                                <br />
                                {informationData?.zipcode},  {informationData?.city},  {informationData?.countryCode}
                                <Copy value={informationData?.addressLine1 + ", " + informationData?.addressLine2 + ", " + informationData?.zipcode + ", " + informationData?.city + ", " + informationData?.countryCode} />
                            </Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* Seniority */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.seniority" />
                            {informationData?.entryDate ? <Label className="group ">{informationData?.entryDate}  <Copy value={informationData?.entryDate} /></Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* IBAN */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.iban" />
                            {informationData?.iban ? <Label className="group ">{informationData?.iban}  <Copy value={informationData?.iban} /></Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* BIC */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.bic" />
                            {informationData?.bicCode ? <Label className="group ">{informationData?.bicCode}  <Copy value={informationData?.bicCode} /></Label> : <Label color="gray400" message="error.unfilled" />}
                        </div>

                        {/* Data privacy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.dataPrivacy" />
                            <Sheet open={openRGPD} onOpenChange={setOpenRGPD}>
                                <SheetTrigger className="w-min">
                                    <Button text="global.panelOpening.showMore" className="w-min" style="dressedGhost" />
                                </SheetTrigger>
                                <SheetContent >
                                    <SheetHeader
                                        title={"consultants.informations.dataDeletionInfo.panel.title"}
                                    />
                                    <SheetSlot className=" flex ">
                                        <DataDeletion />
                                    </SheetSlot>
                                    {/* <SheetFooter
                                        buttonOutlineText={"global.actions.cancel"}
                                        buttonDressedText={"global.actions.request"}
                                        warning={"consultants.informations.dataDeletionInfo.warning.dataDeletion"}
                                        onClickOutline={() => setOpenRGPD(false)}
                                        onClickDressed={(checkboxIsChecked) => { console.log(informationData?.uuid, 'user ask data deletion, consent done  : ', checkboxIsChecked) }}
                                    /> */}
                                </SheetContent>
                            </Sheet>
                        </div>

                    </div>
                    <Separator orientation="vertical" />
                    <div className="flex flex-col grow-5 justify-start gap-secondDegree ">
                        {/* CooptedBy */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.cooptedBy" />
                            {informationData?.cooptedBy && informationData?.cooptedBy.length > 0 ?
                                <>
                                    {informationData?.cooptedBy.map((item, index) => (
                                        <Label className="group" key={index}>
                                            {item?.cooptant?.firstname + " " + item?.cooptant?.lastname}
                                            <Copy value={item?.cooptant?.firstname + " " + item?.cooptant?.lastname} />
                                        </Label>

                                    ))}
                                </>
                                :
                                <Label color="gray400" message="error.unfilled" />
                            }

                        </div>

                        {/* Profile */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label
                                fontWeight="bold"
                                message="consultants.informations.profile"
                            />
                            <aside className="flex gap-secondDegree items-center">
                                {informationData?.consultantProfile ? <Label className="group h-full">{informationData?.consultantProfile.name} <Copy value={informationData?.consultantProfile.name} /></Label> : <Label color="gray400" message="error.unfilled" />}

                                <Dialog >
                                    <DialogTrigger>
                                        <Label className="hover:cursor-pointer font-bold underline " color="primary" size="body" message="global.actions.set" />
                                    </DialogTrigger>
                                    <DialogContent>
                                        <DialogHeader>
                                            <DialogTitle><Label message="consultants.informations.profile" size="h4" /></DialogTitle>
                                            <DialogDescription className="flex flex-col gap-4 py-4">
                                                <span>
                                                    <Label message="consultants.informations.actualProfil" />
                                                    {informationData?.consultantProfile && <Label fontWeight="bold">{informationData?.consultantProfile.name}</Label>}
                                                </span>
                                                <Label message="consultants.informations.selectProfile" />
                                                {profilesLoading && <span className="loader" />}
                                                {profilesError && <PrintErrorMessage error={profilesError} className="col-span-5 w-full" />}
                                                {(!profilesError && !profilesLoading) &&

                                                    <Select value={selectedProfile} onValueChange={setSelectedProfile}>
                                                        <SelectTrigger className="w-[180px]">
                                                            {informationData?.consultantProfile ? <SelectValue placeholder={informationData?.consultantProfile?.name}  /> : <SelectValue placeholder={selectedProfile}  />}
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {/* {console.log("informationData?.allConsultantsProfiles : ", informationData?.allConsultantProfiles)} */}

                                                            {informationData?.allConsultantProfiles && informationData?.allConsultantProfiles.map((item, index) => (
                                                                <SelectItem value={item?.uuid} id={index} >{item?.name}</SelectItem>
                                                            ))}


                                                        </SelectContent>
                                                    </Select>
                                                }
                                            </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter className="sm:justify-start">
                                            <DialogClose asChild>
                                                <Button type="button" style="ghost" text='global.actions.cancel' />
                                            </DialogClose>
                                            {(!profilesError && !profilesLoading) && <DialogClose className="w-full">
                                                <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { callUpdateConsultantProfile(selectedProfile); onclose() }} />
                                            </DialogClose>}
                                        </DialogFooter>
                                    </DialogContent>
                                </Dialog>
                            </aside>

                        </div>

                        {/* Regime */}
                        <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.dashboard.newCooptation.table.regime" />
                            <aside className="flex gap-secondDegree items-center">

                                {informationData?.retributionModel ? <Label className="group">{formatedRetribution}   <Copy value={formatedRetribution} /></Label> : <Label color="gray400" message="error.unfilled" />}
                                <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
                                    <DialogTrigger>
                                        <Label className="hover:cursor-pointer font-bold underline" color='primary' message="global.actions.set" />
                                    </DialogTrigger>
                                    <DialogContent>
                                        <DialogHeader>
                                            <DialogTitle><Label message="consultants.informations.retribution.title" size="h4" />{informationData?.firstname + " " + informationData?.lastname}</DialogTitle>
                                            <DialogDescription className="flex flex-col gap-4 py-4">
                                                {informationData?.retributionModel && <span className='font-bold text-body'><Label className='font-medium' message="consultants.informations.retribution.actual" />{informationData?.retributionModel?.upToFiveConsultants + "/" + informationData?.retributionModel?.upToTenConsultants + "/" + informationData?.retributionModel?.moreThanTenConsultants}</span>}
                                                <Label fontWeight='bold' message="consultants.informations.retribution.instruction" />
                                                {retributionLoading && <span className="loader" />}
                                                {retributionErrors && <PrintErrorMessage error={retributionErrors} className="col-span-5 w-full" />}
                                                {(!retributionErrors && !retributionLoading) && <RadioGroup value={selectedRetribution || (informationData?.retributionModel && informationData?.retributionModel?.uuid)} onValueChange={setSelectedRetribution} >
                                                    {informationData?.allRetributionsModels && informationData?.allRetributionsModels.map((item, index) => (
                                                        <div className="flex items-center space-x-2" key={index}>
                                                            <RadioGroupItem value={item?.uuid} id={index} />
                                                            <Label htmlFor={item?.uuid}>{item?.upToFiveConsultants + "/" + item?.upToTenConsultants + "/" + item?.moreThanTenConsultants}</Label>

                                                        </div>
                                                    ))}
                                                    {/* {console.log("selectedRetribution : ", selectedRetribution)} */}
                                                    <div className="flex items-center space-x-2">
                                                        <RadioGroupItem value={inputValue} id="r3" />
                                                        <input
                                                            htmlFor="r3"
                                                            className={`px-2 py-1 rounded-sm border ${isInputValid ? 'border-grey' : 'border-error'}`}
                                                            onBlur={handleInputBlur}

                                                            placeholder='--/--/--' />
                                                    </div>
                                                </RadioGroup>}
                                                {!isInputValid && <Label className="text-error" message="error.formatRetribution" />}
                                            </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter className="sm:justify-start">
                                            <DialogClose asChild>
                                                <Button type="button" style="ghost" text='global.actions.cancel' />
                                            </DialogClose>
                                            {(!retributionErrors && !retributionLoading) && <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { callUpdateRetibutionModel(selectedRetribution); setDialogIsOpen(false) }} />}                                        </DialogFooter>
                                    </DialogContent>

                                </Dialog>

                            </aside>

                        </div>

                        {/* Cooptations */}
                        {informationData?.cooptantFor && informationData?.cooptantFor?.length > 0 && <div className="flex flex-col gap-firstDegree">
                            <Label fontWeight="bold" message="consultants.informations.activeCooptation" />
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="bg-transparent w-[25%] h-auto py-firstDegree"><Label message="consultants.dashboard.newCooptation.table.name" /></TableHead>
                                        <TableHead className=""><Label message="consultants.dashboard.newCooptation.table.regime" /></TableHead>
                                        <TableHead className=""><Label message="consultants.dashboard.newCooptation.table.ownership" /></TableHead>
                                        <TableHead className=""><Label message="consultants.dashboard.newCooptation.table.duration" /></TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>

                                    {informationData?.cooptantFor?.length > 0 && renderCooptingRows()}

                                </TableBody>
                            </Table>

                        </div>}

                    </div>

                </div>
            </div>}
        </div >


    )

}
