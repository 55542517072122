import { useState, useEffect } from 'react';
import formatUsers from './fomatName';
import { useConsultantContext } from '@/context/ConsultantContext';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useAllUsers from '@/services/global-services/query/useAllUsers';
import { useToast } from '../../../organisms/feedback/use-toast';



const useConsultant = () => {
    const { getAllUsers, allUsers } = useAllUsers();
    const [users, setUsers] = useState();
    const { toast } = useToast();

    const getAllUsersAsync = async () => {
        const { data, error } = await getAllUsers();

        if (data) {
            console.log("User data:", data);
            setUsers(data);
            setUsesMatched(data);
            setUsersFormated(data);
        } else {
            console.error("Error fetching user:", error);
            toast({
                variant: "error",
                title: "Error",
                description: error,
                duration: 8000,
            });
        }
    };
    useEffect(() => {
        getAllUsersAsync();
    }, [])


    const [usersMatched, setUsesMatched] = useState();
    const [usersFormated, setUsersFormated] = useState();


    const [searchText, setSearchText] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const {  activeUser, setActiveUser } = useConsultantContext();

    const searchConsultant = (text) => {
        setSearchText(text);
        const filteredUsers = users.filter((user) =>
            user.firstname.toLowerCase().includes(text.toLowerCase()) ||
            user.lastname.toLowerCase().includes(text.toLowerCase()) ||
            user.trigram.toLowerCase().includes(text.toLowerCase())
        );
        console.log("filteredUsers: ", filteredUsers, "users: ", users);
        setUsesMatched(filteredUsers);
    };

    const clearSearch = () => {
        setSearchText('');
        setUsesMatched([]);
    };

    const initActivesUsersFromUrl = () => {
        if (!activeUser[0] && users) {
            const tempActiveUser = users.filter((u) => u.uuid == id)
            setActiveUser(tempActiveUser[0]);
        }
    }
    useEffect(() => {
        activeUser && initActivesUsersFromUrl();
    }, [users])


    const handleClick = (user) => {
        setIsSelected(true);
        setActiveUser(user);
        if (id) {
            const newPath = location.pathname.replace(id, user.uuid);
            navigate(newPath, { replace: true });
        } else {
            navigate(user.uuid + '/statement');
        }
    };

    return { usersFormated, usersMatched, searchText, isSelected, searchConsultant, clearSearch, handleClick };
};

export default useConsultant;
