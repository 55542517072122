import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_LEASING_INVOICE = gql`
  mutation AddLeasingInvoice(
    $leasingInvoiceFileUuid: ID!,
    $consultantUuid: ID!,
    $period: Date!,
    $invoiceDate: Date!,
    $currency: String!,
    $amountExcludingVat: Float!,
    $totalAmount: Float!,
    $comment: String,
    $vatList: [NewLeasingInvoiceVatRequestDto]
  ) {
    addLeasingInvoice(newLeasingInvoice: {
      leasingInvoiceFileUuid: $leasingInvoiceFileUuid,
      consultantUuid: $consultantUuid,
      period: $period,
      invoiceDate: $invoiceDate,
      currency: $currency,
      amountExcludingVat: $amountExcludingVat,
      totalAmount: $totalAmount,
      comment: $comment,
      vatList: $vatList
    }) {
      uuid
      leasingInvoiceFileUuid
      year
      month
      invoiceDate
      currency
      amountExcludingVat
      totalAmount
      vatList {
        vatRate
        vatAmount
      }
    }
  }
`;

export const useAddLeasingInvoice = () => {
  const { user } = useAuth();

  const [addLeasingInvoice, {data, loading, error }] = useMutation(ADD_LEASING_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    
  });

  return { addLeasingInvoice, loading, error, data };
};
