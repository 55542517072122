/* eslint-disable react/prop-types */
/**
 * @component Information
 *
 * This component serves as a detailed profile management section for a user. It allows users to view, edit, and update their profile picture, languages, profile titles, and other information. It integrates various sub-components for handling specific tasks like uploading profile pictures and adding languages or titles.
 *
 * Key Features:
 * - Displays and allows updates to the user's profile picture.
 * - Supports the addition and management of languages and profile titles.
 * - Handles form validation, action tracking, and error display.
 * - Includes animations using `framer-motion` for smooth transitions.
 * - Error handling and feedback system integrated with the `PrintErrorMessage` component.
 *
 * Props:
 * - @param {Function} hideAllOverview - Function to hide the overview section.
 * - @param {Function} showComponent - Function to show detailed components.
 * - @param {String} avatarSrc - Source URL for the avatar image.
 * - @param {Function} setAvatarSrc - Function to update the avatar source.
 * - @param {Array} languages - List of languages the user speaks.
 * - @param {Object} user - Object representing the user, containing attributes like `uuid`, `firstname`, and `lastname`.
 * - @param {Function} setIsDoingAction - Function to set the action state when performing tasks like uploading a picture.
 * - @param {Array} titles - List of profile titles associated with the user.
 *
 * Usage:
 * - This component is used in user profile pages or panels to allow users to update their personal information.
 * - It provides various sections for uploading avatars, adding languages, and managing profile titles, all with responsive design and animations.
 *
 * Example:
 * <Information
 *   hideAllOverview={hideOverview}
 *   showComponent={showDetails}
 *   avatarSrc={userAvatar}
 *   setAvatarSrc={updateAvatar}
 *   languages={userLanguages}
 *   user={userDetails}
 *   setIsDoingAction={setLoadingAction}
 *   titles={profileTitles}
 * />
 *
 * Dependencies:
 * - `Button` component for user actions.
 * - `PrintErrorMessage` for displaying error feedback.
 * - `ProfileAvatar`, `AddLanguage`, `AddProfileTitle`, `HandleLanguage`, and `PrintProfileTitle` for managing various sections of the user profile.
 * - `framer-motion` for animations.
 * - `useProspectPicture` hook to handle profile picture fetching and uploading.
 *
 * Sub-Components:
 * - @component ProfileAvatar: Manages the display and upload of the user's avatar.
 * - @component HandleLanguage: Displays and manages the user's languages.
 * - @component AddProfileTitle: Handles adding new profile titles for the user.
 * - @component PrintProfileTitle: Displays and allows editing of the user's profile titles.
 */

import { Button } from "@/components/molecules/buttons/button";
import AddIcon from "@mui/icons-material/Add";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Label } from "@/components/atoms/texts/label";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import HandleLanguage from "./HandleLanguage";
import AddProfileTitle from "./AddProfileTitle";
import { motion } from "framer-motion";
import { item } from "@/utils/animationConst";
import { useState, useEffect } from "react";
import PrintProfileTitle from "./PrintProfileTitle";
import ProfileAvatar from "./ProfileAvartar";
import useProspectPicture from "@/services/admin-services/useProspectPicture";
import AddLanguage from "./AddLanguage";
import UpdateProspectInfo from "./UpdateProspectInfo";
const getProfileTitles = () => {
    return [
        {
            profileTitle: "Ingénieur DATA",
            date: "01/02/2022",
            id: "01",
        },
    ];
};
export default function Information({
    hideAllOverview,
    showComponent,
    avatarSrc,
    setAvatarSrc,
    languages,
   
    user,
    setIsDoingAction,
    titles,

}) {
    const {
        profilePicture,
        loading,
        error,
        // getProspectPicture,
        uploadProspectPicture,
    } = useProspectPicture(user?.uuid);

    const [showForm, setShowForm] = useState(false);
    const [profiles, setProfiles] = useState(getProfileTitles());
    const [editingProfile, setEditingProfile] = useState(false);
    const [fomrIsValid, setFormIsValid] = useState(false);
    const [serverresponseIsError, setServerResponseIserror] = useState(false);
    const [errors, setErrors] = useState(null);
    const [editingLanguage, setEditingLanguage] = useState(false);

    const [showFormLanguage, setShowFormLanguage] = useState(false);

    const hider = () => {
        hideAllOverview(false);
        showComponent(false);
    };
    useEffect(() => {
        // Récupérer la photo du prospect dès que le composant est monté
        // getProspectPicture();
    }, [setIsDoingAction]);

    const handleUpload = (file) => {
        uploadProspectPicture(file);
    };

    return (
        <motion.div
            variants={item}
            className="w-full flex  flex-col gap-secondDegree"
        >
            {serverresponseIsError && <PrintErrorMessage error={errors} />}
            <div className="flex items-center gap-fourthDegree">

                <Button
                    showLeftIcon={true}
                    leftIcon={
                        <ArrowBackIcon className="cursor-pointer" onClick={hider} />
                    }
                    showText={false}
                    style="ghost"
                    onClick={hider}
                />

                <Label
                    message="consultants.statement.prospectConsultantPanel.informations"
                    size="h3"
                    fontWeight="bold"
                />
            </div>

            <ProfileAvatar
                avatarSrc={profilePicture ? profilePicture : "ML"}
                setAvatarSrc={handleUpload}
                name={user.lastname}
                firstName={user.firstname}
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
                loading={loading}
            />
            {/* */}
            <Separator orientation="horizontal" />
            <UpdateProspectInfo
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
            />

            <Separator orientation="horizontal" />
            <div className="flex flex-row justify-between gap-secondDegree">
                <Label
                    message="global.information.languages"
                    size="h3"
                    fontWeight="bold"
                />
                <Button
                    showLeftIcon={true}
                    leftIcon={<AddIcon fontSize="small" />}
                    showText={true}
                    text="global.actions.add"
                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                    style="ghost"
                    className="font-bold basis-1/4 "
                    onClick={() => setShowFormLanguage(true)}
                />
            </div>
            {showFormLanguage && (
                <AddLanguage
                    showForm={showFormLanguage}
                    setShowForm={setShowFormLanguage}
                    setEditingLanguage={setEditingLanguage}
                   
                    languages={languages}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                    setFormIsValid={setFormIsValid}
                    setServerResponseIserror={setServerResponseIserror}
                    setErrors={setErrors}
                />
            )}
            <HandleLanguage
                languages={languages}
             
                user={user}
                setIsDoingAction={setIsDoingAction}
                setFormIsValid={setFormIsValid}
                setServerResponseIserror={setServerResponseIserror}
                setErrors={setErrors}
            />
            <Separator orientation="horizontal" />

            <div className="flex flex-row justify-between gap-secondDegree">
                <Label
                    message="consultants.statement.prospectConsultantPanel.profileTitle"
                    size="h3"
                    fontWeight="bold"
                />
                <Button
                    showLeftIcon={true}
                    leftIcon={<AddIcon fontSize="small" />}
                    showText={true}
                    text="global.actions.add"
                    sizePadding="default" // Utilisez "small" pour un padding plus petit
                    style="ghost"
                    className="font-bold basis-1/4 "
                    onClick={() => setShowForm(true)}
                />
            </div>
            {showForm && (
                <AddProfileTitle
                    showForm={showForm}
                    setShowForm={setShowForm}
                    setProfiles={setProfiles}
                    editingProfile={false}
                    setEditingProfile={setEditingProfile}
                    user={user}
                    setIsDoingAction={setIsDoingAction}
                    setFormIsValid={setFormIsValid}
                    setServerResponseIserror={setServerResponseIserror}
                    setErrors={setErrors}
                />
            )}

            {titles &&
                titles.map((profile) => {
                    return (
                        <PrintProfileTitle
                            key={profile.uuid}
                            profile={profile}
                            editingProfile={editingProfile}
                            setEditingProfile={setEditingProfile}
                            setProfiles={setProfiles}
                            user={user}
                            setIsDoingAction={setIsDoingAction}
                            setFormIsValid={setFormIsValid}
                            setServerResponseIserror={setServerResponseIserror}
                            setErrors={setErrors}
                            
                        />
                    );
                })}
        </motion.div>
    );
}
