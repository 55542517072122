import { useAuth } from "@/context/AuthContext";
import { useParams } from "react-router-dom";

import { gql, useMutation} from "@apollo/client";

const DELETE_INVOICE = gql`
  mutation DeleteConsultantInvoice($uuid: ID!) {
    deleteConsultantInvoice(uuid: $uuid)
  }
`;
export const useDeleteConsultantInvoice = () => {
  const { user } = useAuth();
  if (!user) return;

  const [deleteInvoice, { loading, error, data }] = useMutation(
    DELETE_INVOICE,
    {
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`,
        },
      },
    }
  );

  return { deleteInvoice, loading, error, data };
};