const getBillsForYear = (user, year) => {
  if (!user.bills || !user.bills[year]) {
    return {}; // Return an empty object or handle the absence of data as needed
  }

  return user.bills[year];
};

const getMonthDataBills = (yearlyData) => {
  const monthsData = Object.values(yearlyData);

  const monthDataBills = monthsData.map((monthlyData) => {
    const dayEntries = Object.values(monthlyData);

    // Assuming you want to get the first entry for each month
    const firstDayData = dayEntries.length > 0 ? dayEntries[0] : null;

    return firstDayData;
  });

  return monthDataBills;
};
const getUniqueYearsForUser = (user) => {
  const uniqueYears = new Set();
  console.log(user);

  if (user.YearlyData) {
    const years = Object.keys(user.YearlyData);
    years.forEach((year) => uniqueYears.add(Number(year)));
  }

  return Array.from(uniqueYears);
};
const getYearDataForUser = (user, year) => {
  if (user.YearlyData && user.YearlyData[year]) {
    return user.YearlyData[year];
  } else {
    return null; // Ou une valeur par défaut selon votre besoin
  }
};

function formatCurrency(amount, currency) {
  const data = amount == 0 ? 0.0 : amount;
  const upperCaseCurrency = currency.toUpperCase();

  
  const validCurrencies = ["USD", "EUR"]; // add another currencry here
  if (!validCurrencies.includes(upperCaseCurrency)) {
    return "Invalid Currency Code";
  }

  const formattedAmount = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: upperCaseCurrency,
  }).format(data);

  return `${formattedAmount}`;
}

const getMonthData = (yearlyData) => {
  const userMonthData = [];
  yearlyData &&
    Object.values(yearlyData).forEach((month) => {
      userMonthData.push(Object.values(month)[0]);
    });
  return userMonthData;
};

const getYearsWithBills = (user) => {
  return Object.keys(user.bills);
};
const getAllBillYears = (user) => {
  const uniqueYears = new Set();

  if (user.bills) {
    const years = Object.keys(user.bills);
    years.forEach((year) => uniqueYears.add(Number(year)));
  }

  return Array.from(uniqueYears);
};
const getClientsForUser = (user) => {
  const clients = [];
  for (const year in user.bills) {
    for (const month in user.bills[year]) {
      const monthlyData = user.bills[year][month];
      for (const day in monthlyData) {
        const { customer } = monthlyData[day];
        clients.push(customer);
      }
    }
  }
  return clients;
};

const getStatisticValue = (user, year, statisticName) => {
  if (!user || !user.YearlyData || !user.YearlyData[year]) {
    // L'utilisateur, l'année ou les données annuelles ne sont pas trouvés
    return null;
  }

  const yearlyData = user.YearlyData[year];
  const statisticValue = yearlyData.statics[statisticName];

  return statisticValue !== undefined ? statisticValue : null;
};
const filterUnwantedPropertiesAdmin = (data) => {
 //console.log("invoices filter", data)
  return Object.values(data).map((dayData) => {
    const { month, clientName, daysWorked, amountClient, invoiceNumber,uuid, ...rest } = dayData;
    const mois = monthMap[month]
   // console.log("fg k", mois)
    
    return {
      mois,
      clientName,
      invoiceNumber,
      daysWorked,
      amountClient,
      uuid,
    };
  });
};

const getUsersName = (users) => {
  let names = [];
  users.map((user) => names.push(user.name));

  return names;
};
function transformStatementData(data) {
  // Map des mois
  const monthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  // Transformation de l'objet
  const transformedData = {
    rowNameOrMonth: monthMap[data.month],
    employerCost: data.employerCost,
    customerPayment: data.consultantServiceAmount,
    cooptation: data.cooptations && data.cooptations.amount,
    overflow: data.overflowBalance,
  };

  return transformedData;
}
const monthMap = {
  1: "january",
  2: "february",
  3: "march",
  4: "april",
  5: "may",
  6: "june",
  7: "july",
  8: "august",
  9: "september",
  10: "october",
  11: "november",
  12: "december",
};

function enrichMonthlyData(data) {
  // Créer un tableau pour les 12 mois avec des objets par défaut
  const enrichedData = Array.from({ length: 12 }, (_, index) => ({
    uuid: null,
    month: index + 1,
    daysWorked: null,
    daysSick: null,
    leasing: null,
    expenses: null,
    overflow: null,
    grossSalary: null,
    employerContributions: null,
    employeeCost: null,
    consultantServiceAmount: null,
    taxes: null,
    dailyRate: null,

    cooptations: {
      amount:null
    }
  }));

  // Mapper les données existantes aux mois correspondants
  data.forEach((item) => {
    const monthIndex = item.month - 1;
    enrichedData[monthIndex] = { ...enrichedData[monthIndex], ...item };
  });

  return enrichedData;
}
const findUserByEmail = (allProspects, email) => {
  const prospect = allProspects.find(
    (prospect) => prospect.personalEmail === email
  );
  console.log("tout les prospects", allProspects);
  console.log("le consultant saisie est ", prospect);
  return prospect ? prospect : null; // Retourne l'UUID si trouvé, sinon null
};
export {
  getUniqueYearsForUser,
  getUsersName,
  getYearDataForUser,
  formatCurrency,
  getMonthData,
  getBillsForYear,
  getYearsWithBills,
  getClientsForUser,
  getMonthDataBills,
  getAllBillYears,
  getStatisticValue,
  transformStatementData,
  filterUnwantedPropertiesAdmin,
  monthMap,
  enrichMonthlyData,
  findUserByEmail,
};
