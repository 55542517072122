import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useState } from 'react';
import errorToText from '@/utils/errorToText';

// Définition de la mutation GraphQL
const UPDATE_COOPTATION = gql`
  mutation updateCooptations($uuidCoopting: ID!, $cooptedBy: [CooptationRequestDto]!) {
    updateCooptations(
      updatedCooptations: {
        consultantUuid: $uuidCoopting,
        cooptedBy: $cooptedBy
      }
    ) {
      cooptedBy {
        cooptant {
          firstname
          lastname
          uuid
        }
      }
    }
  }
`;

const useUpdateCooptation = () => {
    const [cooptations, setCooptations] = useState(null);
    const { user: authUser } = useAuth();

    // Utilisation de la mutation Apollo
    const [updateCooptationMutation, { loading, error, data }] = useMutation(UPDATE_COOPTATION, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser?.strongToken}`,
            },
        },
    });

    const setCooptation = async (uuidCoopting, cooptedBy) => {
        if (!authUser?.strongToken) {
            return { data: null, error: 'No strong token available' };
        }

        console.log("cooptedBy", cooptedBy);

        try {
            const response = await updateCooptationMutation({
                variables: {
                    uuidCoopting,
                    cooptedBy,
                },
            });

            // Vérifie si la mutation renvoie des erreurs
            if (!response || response.errors) {
                const errMessage = response?.errors?.[0]?.message || "Unknown error occurred";
                throw new Error(errMessage);
            }

            // Met à jour l'état avec la réponse UUID
            setCooptations(response.data.updateCooptations);
            console.log("debug response= ", response, "response.data.updateCooptations = ", response.data.updateCooptations)
            return { data: response.data.updateCooptations, error: null };
        } catch (err) {
            console.error(err);
            return { data: null, error: err.message };
        }
    };
    console.log("debug cooptations=", cooptations)

    return { setCooptation, data: cooptations, loading, error };
};

export default useUpdateCooptation;
