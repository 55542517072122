import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";


export const ADD_PROSPECT = gql`
  mutation AddProspect($newProspect: NewProspectDto!) {
    addProspect(newProspect: $newProspect) {
      lastname
      firstname
      gender
      personalEmail
      birthdate
      uuid
    }
  }
`;


export const useAddProspect = () => {
  const { user } = useAuth();
  const [addProspectMutation, { loading, error, data }] =
    useMutation(ADD_PROSPECT);

  const addProspect = async (prospectData) => {
    try {
      const response = await addProspectMutation({
        variables: {
          newProspect: {
            ...prospectData,
            companyUuid: user.company.uuid,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${user.strongToken}`,
          },
        },
      });

      console.log("La réponse du seveur", response)
      return response.data.addProspect;
    } catch (err) {
      throw new Error(`Failed to add prospect: ${err.message}`);
    }
  };
console.log("******Added user ", data)
  return { addProspect, loading, error, data };
};
