import { Label } from "@/components/atoms/texts/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useState } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";
import { useTranslation } from "react-i18next";
import ReadingStatus from "./readingStatus";
import { useGetLeasingInvoice } from "@/services/consultant-services/api-rest/useGetLeasingInvoice";
import { useDeleteConsultantInvoice } from "@/services/admin-services/mutations/useDeleteConsultantInvoice";
import { useDeleteLeasingInvoice } from "@/services/admin-services/mutations/useDeleteLeasingInvoice";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { useGetConsultantLeasingInvoice } from "@/services/admin-services/api-rest/useGetConsultantLeasingInvoice";

// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function Leasing({ data, dataOwner, refetchFromScratch =()=>{}}) {

    const [open, setOpen] = useState();
    const totalTax = (data?.totalAmount && data?.amountExcludingVat) && data.totalAmount - data.amountExcludingVat;
    const [t] = useTranslation("global");
    // Convertir la date passée en objet Date
    const providedDate = new Date(data.invoiceDate);

    // Obtenir la date actuelle
    const currentDate = new Date();

    // Calculer la différence en jours
    const differenceInTime = currentDate.getTime() - providedDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    console.log("data", data);

    const { getLeasingInvoice, loading: loadingFile, error: errorFile, data: leasingFile } = dataOwner ? useGetConsultantLeasingInvoice(): useGetLeasingInvoice();
    const { deleteLeasingInvoice, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteLeasingInvoice();
    const { toast } = useToast();

    const handleGetInvoice = () => {
        console.log("handleGetInvoice", data.leasingInvoiceFileUuid)
        if (data.leasingInvoiceFileUuid) {
            getLeasingInvoice(data.leasingInvoiceFileUuid);
        }
    };

    const handleOpenInvoice = () => {
        if (!leasingFile) {
            handleGetInvoice();
        }
        leasingFile && window.open(leasingFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }

    useEffect(() => {
        leasingFile && window.open(leasingFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [leasingFile]);

    const deleteLeasing = (uuidLeasing) => {
        console.log("deleteLeasing", uuidLeasing);
        deleteLeasingInvoice(uuidLeasing);
    }
    // TODO : texte à mettre dans global
    useEffect(() => {
        console.log("dataDelete", dataDelete)
        if (dataDelete) {
            toast({
                variant: "success",
                title: "success.backendOperation.delete",
                duration: 8000,
                description: "La facture de leasing " + data.month + "/" + data.year + " " + data.comment + " a été supprimée"
            })
            refetchFromScratch(1)
        } else if (dataDelete?.deleteLeasingInvoice == false && errorDelete) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
    }, [dataDelete])
    return (
        <div className="bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree">
            <Label fontWeight="semibold">{data.fact}</Label>

            <ReadingStatus isNew={differenceInDays < 30} />
            {/* <Label fontWeight={differenceInDays < 30 && "bold"}>{t("consultants.dashboard.tabs.leasing") + " " + data.month + "/" + data.year}</Label> */}
            {/* <Label fontWeight="">{data.comment}</Label>

            <div className="grow"></div>
            <Label fontWeight="">{data.invoiceDate}</Label> */}



            <div className=" overflow-hidden flex gap-firstDegree flex-col md:flex-row grow items-center ">
                <Label className="text-nowrap " fontWeight={differenceInDays < 30 && "bold"}>{t("consultants.dashboard.tabs.leasing") + " " + data.month + "/" + data.year}</Label>
                <Label className="shrink truncate" fontWeight="">{data.comment}</Label>
                <div className="grow"></div>
                <Label className=" min-w-[300px] shrink-0" fontWeight="">{data.invoiceDate}</Label>
            </div>
            <div className="flex gap-firstDegree shrink-0">
                <Label fontWeight="bold" message="consultants.expense.amountExclTaxs" />
                <Label fontWeight="">{data.amountExcludingVat} €</Label>
            </div>

            <div className="flex gap-firstDegree items-center shrink-0">
                <Label fontWeight="bold" message="consultants.expense.amountTax" />
                <Popover open={open} onOpenChange={setOpen} >
                    <PopoverTrigger onMouseOver={(e) => setOpen(true)} onMouseOut={(e) => setOpen(false)} asChild className="flex gap-firstDegree items-center ">
                        <div className="relative">
                            <img src={Polygon1} className="absolute top-[-2px] left-[-6px] opacity-50" />

                            <Label fontWeight="" className="">{totalTax.toFixed(2)} €</Label>
                        </div>

                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col gap-firstDegree w-auto">
                        {data.vatList && data.vatList.map((item, index) => (
                            <div className="flex gap-firstDegree" key={index}>
                                <Label size="body" fontWeight="bold">{item.vatRate} % :</Label><Label size="body"> {item.vatAmount} €</Label>
                            </div>
                        ))}
                    </PopoverContent>
                </Popover>
            </div>
            {/* <Label fontWeight="">{data.fileSize}</Label> //TODO */}
            <ControlsDownload
                dataOwner={dataOwner}
                onClickNewTab={handleOpenInvoice}
                onClickDownload={handleGetInvoice}
                onClickDelete={() => deleteLeasing(data.uuid)}
                disabledOpenNewTab={!data.leasingInvoiceFileUuid || errorFile}
                disabledDelete={errorDelete}
                hideDownload
            />
            {loadingFile ||loadingDelete && <span className="loader" />}

        </div>
    );
}