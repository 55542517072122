// useExpenseForm.js

import { useApproveExpenseReport } from '@/services/admin-services/mutations/useApproveExpenseReport';
import { useState, useEffect } from 'react';

const useFormNewExpense = (onSubmit, submitRef, data, refetchFromScratch) => {
    // const currencies = [
    //     { code: "EUR", symbol: "€" },
    //     { code: "test  hard codee", symbol: "$" },
    //     { code: "JPY", symbol: "¥" },
    //     { code: "GBP", symbol: "£" },
    //     { code: "CHF", symbol: "Fr" }
    // ];

    const [file, setFile] = useState(null);
    const [currency, setCurrency] = useState("EUR");
    const [date, setDate] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [amount, setAmount] = useState('');
    const [vatValues, setVatValues] = useState({});
    const [totalVat, setTotalVat] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [vendor, setVendor] = useState();
    const [country, setCountry] = useState();
    const [comment, setComment] = useState();


    const [fileError, setFileError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [selectValueError, setSelectValueError] = useState(null);
    const [amountError, setAmountError] = useState(null);
    const [totalVatError, setTotalVatError] = useState(null);
    const [totalAmountError, setTotalAmountError] = useState(null);
    const [commentError, setCommentError] = useState(null);
    const [vendorError, setVendorError] = useState(null);
    const [countryError, setCountryError] = useState(null);

    const { approveExpenseReport, loading, error, data: dataApproval } = useApproveExpenseReport();



    const handleVatChange = (e, rate) => {
        if (e.target.value === '' || e.target.value <= 0) {
            // Créer une copie de vatValues sans l'élément correspondant à rate.uuid
            const { [rate.uuid]: _, ...remainingVatValues } = vatValues;
            setVatValues(remainingVatValues);
        } else {
            // Mettre à jour vatValues avec la nouvelle valeur
            const newVatValues = {
                ...vatValues,
                [rate.uuid]: { value: e.target.value, rate: rate.rate } || 0,
            };
            setVatValues(newVatValues);
        }

        console.log("vatValues", vatValues);
    };



    function toNumber(value) {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number;  // Retourne 0 si la valeur n'est pas un nombre
    }
    useEffect(() => {
        if (!vatValues) return;
        let total;
        // if (vatValues[0]?.rate) {

        // }

        if (vatValues[0]?.vatRate) {
            total = Object.values(vatValues).reduce((sum, vat) => {
                // Extraire la clé de l'objet (le UUID) et son contenu
                return sum + (vat.vatAmount);
            }, 0);
        } else {
            total = Object.values(vatValues).reduce((sum, vat) => {
                // Extraire la clé de l'objet (le UUID) et son contenu
                return sum + (vat.rate * vat.value / 100);
            }, 0);
        }
        console.log("total", total, vatValues, vatValues[0]?.vatRate)

        setTotalVat(total);
    }, [vatValues]);


    useEffect(() => {
        vendor && validateForm("vendorErrorTest");
    }, [vendor]);

    useEffect(() => {
        country && validateForm("countryErrorTest");
    }, [country]);

    useEffect(() => {
        comment && validateForm("commentErrorTest");
    }, [comment]);

    useEffect(() => {
        totalVat && validateForm("totalVatErrorTest");
         console.log("totalVat || amount", totalVat || amount , totalVat , amount, toNumber(totalVat) + toNumber(amount));
        (totalVat || amount) && setTotalAmount(toNumber(totalVat) + toNumber(amount))
    }, [totalVat]);

    useEffect(() => {
        totalAmount && validateForm("totalAmountErrorTest");
    }, [totalAmount]);

    useEffect(() => {
        date && validateForm("dateErrorTest");
    }, [date]);

    useEffect(() => {
        amount && validateForm("amountErrorTest");
        totalVat || amount && setTotalAmount(toNumber(totalVat) + toNumber(amount))
    }, [amount]);

    useEffect(() => {
        selectValue && validateForm("selectValueErrorTest");
    }, [selectValue]);

    useEffect(() => {
        comment && validateForm("commentErrorTest");
    }, [comment]);

    useEffect(() => {
        country && validateForm("countryErrorTest");
    }, [country]);

    useEffect(() => {
        vendor && validateForm("vendorErrorTest");
    }, [vendor]);

    useEffect(() => {
        // console.log('file changed', file);
    }, [file]);

    useEffect(() => {
        if (data) {

            setDate(data.invoiceDate);
            setSelectValue(data.category.uuid);
            setAmount(data.amountExcludingVat);
            setVatValues(data.vatList);
            setVendor(data.invoiceIssuer);
            setTotalAmount(data.totalAmount);
            setCountry(data.countryCode.toUpperCase());
            setComment(data.comment);
            setCurrency(data.currency);

        }
    }, [data]);

    //TODO Reset les tva values
    const resetForm = () => {
        setFile(null);
        setDate('');
        setSelectValue('');
        setAmount('');
        setVatValues({});
        setTotalVat(0);
        setTotalAmount(0);
        setVendor('')
        setCountry('')
        setComment('')
        setFileError(null);
        setDateError(null);
        setSelectValueError(null);
        setAmountError(null);
        setTotalVatError(null);
        setTotalAmountError(null);
        setCommentError(null);
        setVendorError(null);
        setCountryError(null);
    };


    const declineExpense = async () => {
        console.log("click on decline expense");
        try {
            const result = await approveExpenseReport(data.uuid, "REJECT");
            result && refetchFromScratch();
            console.log("Expense declined successfully:", result);
        } catch (err) {
            console.error("Failed to decline expense:", err);
        }
    };

    const approveExpense = async () => {
        console.log("click on approveExpense");
        try {
            const result = await approveExpenseReport(data.uuid, "APPROVE");
            result && refetchFromScratch();
            console.log("Expense approved successfully:", result);
        } catch (err) {
            console.error("Failed to approve expense:", err);
        }
    };

    function validateForm(varToTest) {
        //ajouter la validation des champs pour vendor ,country, comment
        const numericAmount = parseFloat(amount);
        const numericTotalVat = parseFloat(totalVat);
        switch (varToTest) {
            case "all":
                setCommentError(!comment);
                setCountryError(!country);
                setVendorError(!vendor);
                !data && setFileError(!file);
                setDateError(!date);
                if (country == "LU") {
                    setAmountError(amount <= 0);
                    setTotalVatError((numericTotalVat >= numericAmount) || numericTotalVat == 0);
                } else {
                    setTotalAmountError(totalAmount <= 0);
                }
                setSelectValueError(!selectValue);
                // console.log(!file || !data, !data, file)
                break;
            case "fileErrorTest":
                setFileError(!file || !data);
                break;
            case "dateErrorTest":
                setDateError(!date);
                break;
            case "selectValueErrorTest":
                setSelectValueError(!selectValue);
                break;
            case "amountErrorTest":
                setAmountError(amount <= 0);
                break;
            case "totalVatErrorTest":
                setTotalVatError((numericTotalVat >= numericAmount) || numericTotalVat == 0);
                break;
            case "totalAmountErrorTest":
                setTotalAmountError(totalAmount <= 0);
                break;
            case "commentErrorTest":
                setCommentError(!comment);
                break;
            case "countryErrorTest":
                setCountryError(!country);
                break;
            case "vendorErrorTest":
                setVendorError(!vendor);
                break;
            default:
                console.error("Unknown error test:", varToTest);
                break;
        }
        if ((!data && !file)) {
            return false;
        }
        return true;
    };

    return {
        file,
        date,
        currency,

        selectValue,
        amount,
        vatValues,
        totalVat,
        totalAmount,
        vendor,
        country,
        comment,
        handleVatChange,
        resetForm,
        declineExpense,
        approveExpense,
        toNumber,
        fileError,
        dateError,
        selectValueError,
        amountError,
        totalVatError,
        totalAmountError,
        commentError,
        vendorError,
        countryError,
        setFile,
        setDate,
        setCurrency,
        setSelectValue,
        setAmount,
        setTotalAmount,
        setVendor,
        setCountry,
        setComment,
        validateForm
    };
};

export default useFormNewExpense;
