import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import { useCallback, useEffect, useMemo, useState } from "react";
import ControlsDownload from "@/components/molecules/controlsDownload";
import ReadingStatus from "./readingStatus";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import getMonthName from "@/utils/getMonthName";
import ShowTimesheet from "./ShowTimesheet";
import DaysSynthesis from "./DaysSynthesis";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter, DialogClose, } from "@/components/ui/dialog";
import DragAndDropZone from "@/components/organisms/forms/DragAndDropZone";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { Toaster } from "@/components/organisms/feedback/toaster";
import NewTimesheetPanel from "./NewTimesheetPanel";
import useTimesheetSubmission from "@/services/consultant-services/api-rest/useTimesheetSubmission";
import useTimesheetExport from "@/services/consultant-services/api-rest/useTimesheetExport";
import { useTimeEntries } from "@/services/consultant-services/query/useTimeEntries";
import { useDeleteTimesheet } from "@/services/consultant-services/mutation/useDeleteTimesheet";
import { useApproveTimesheet } from '@/services/admin-services/mutations/useApproveTimesheet';
import useSignedTimesheet from "@/services/admin-services/api-rest/useSignedTimesheet";

// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function Timesheet({ data, dataOwner, refetchTimesheets }) {

    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(false);
    const [fileError, setFileError] = useState();

    const [totalDays, setTotalDays] = useState(0);

    const [loading, setLoading] = useState(true);
    const { toast } = useToast();

    // Hook pour la soumission de la timesheet signée
    const { submitSignedTimesheet, loading: submitLoading, error: submitError } = useTimesheetSubmission();

    // Hook pour l'export de la timesheet en PDF
    const { requestTimesheetExport, loading: exportLoading, error: exportError, data: timesheetFile } = useTimesheetExport();

    // Hook pour la suppression
    const { deleteTimesheet, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteTimesheet();

    // Hook pour approuver ou decline les timesheet 
    const { approveTimesheet, loading: loadingApprove, error: errorApprove, data: dataApprove } = useApproveTimesheet();

    const { requestSignedTimesheet, data: pdfFileSignedTimesheet, loading: loadingSignedTimesheeet, error: errorSignedTimesheet } = useSignedTimesheet()

    useEffect(() => {
        // console.log("Timesheet data =", data)
        if (data) {
            const total = data.daysWorked + data.daysIllness + data.daysVacation + data.daysTraining;
            setTotalDays(total);
        }
    }, [data])

    // const handleSendFile = () => {
    //     console.log("file : ", file);
    //     setFileError();
    //     setFile()
    //     toast({
    //         variant: "success",
    //         title: "success.sendFile",
    //         duration: 1500,
    //     });
    // }

    // Fonction pour gérer l'envoi du fichier signé
    const handleSendFile = async () => {
        if (!file) {
            setFileError("No file selected");
            return;
        }

        try {
            await submitSignedTimesheet(data.uuid, file);
            setFile(null);
            toast({
                variant: "success",
                title: "success.sendFile",
                duration: 1500,
            });
            refetchTimesheets();
        } catch (error) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: error
            });
        }
    };
    const handleOpenTimesheet = async () => {
        if (!timesheetFile && data?.uuid) {
            await requestTimesheetExport(data.uuid);
        }
        timesheetFile && window.open(timesheetFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }

    useEffect(() => {
        timesheetFile && window.open(timesheetFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [timesheetFile]);

    useEffect(() => {
        pdfFileSignedTimesheet && window.open(pdfFileSignedTimesheet?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [pdfFileSignedTimesheet]);


    useEffect(() => {
        // console.log("useeffect dataDelete = ", dataDelete)
        dataDelete?.deleteTimesheet == true && refetchTimesheets();
    }, [dataDelete]);

    useEffect(() => {
        console.log("dataApprove = ", dataApprove);
        dataApprove?.approveTimesheet == true && refetchTimesheets();
    }, [dataApprove]);

    useEffect(() => {
        errorApprove && toast({
            variant: "error",
            title: "error.requestFailed",
            duration: 8000,
            description: errorApprove.message
        });
    }, [errorApprove]);

    useEffect(() => {
        errorSignedTimesheet && toast({
            variant: "error",
            title: "error.requestFailed",
            duration: 8000,
            description: errorSignedTimesheet.message
        });
    }, [errorSignedTimesheet]);

    useEffect(() => {
        errorDelete && toast({
            variant: "error",
            title: "error.requestFailed",
            duration: 8000,
            description: errorDelete.message
        });
    }, [errorDelete]);

    useEffect(() => {
        exportError && toast({
            variant: "error",
            title: "error.requestFailed",
            duration: 8000,
            description: exportError.message
        });
    }, [exportError]);

    useEffect(() => {
        submitError && toast({
            variant: "error",
            title: "error.requestFailed",
            duration: 8000,
            description: submitError.message
        });
    }, [submitError]);

    const handleDeleteTimesheet = (timesheetUuid) => {
        deleteTimesheet(timesheetUuid);
    }

    const openDetailsTimeEntries = (onOpen) => {
        setOpen(!open);
    }

    const handleApproveTimesheet_asAdmin = (uuid) => {
        console.log("handleApproveTimesheet_asAdmin uuid =", uuid);
        (!dataOwner && approveTimesheet) && approveTimesheet(uuid, 'APPROVE');
    }

    const handleDeclineTimesheet_asAdmin = (uuid) => {
        console.log("handleDeclineTimesheet_asAdmin uuid =", uuid);
        (!dataOwner && approveTimesheet) && approveTimesheet(uuid, 'REJECT');
    }

    const handleRequestSignedTimesheeet = () => {
        (!dataOwner && requestSignedTimesheet) && requestSignedTimesheet(data.uuid, data.year, data.month);
    }
    return (
        <div className={`bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom  rounded-sm justify-start items-center flex flex-col gap-secondDegree`}>
            <div className="flex justify-start items-center  gap-secondDegree w-full">
                <Button style="ghost" sizePadding="small" showLeftIcon leftIcon={open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />} showText={false} onClick={openDetailsTimeEntries} />
                <ReadingStatus type={data.status} data="category" />
                <div className="flex gap-betweenText">
                    <Label message="consultants.statement.billsDetails.customer" />
                    <Label fontWeight="bold">{data.clientName}</Label>
                </div>
                <div className="flex gap-betweenText">
                    <Label message="consultants.timesheet.title" />
                    <Label>{getMonthName(data.month)}</Label>
                    <Label>{data.year}</Label>
                </div>

                <div className="grow"></div>
                {((data.status == "DRAFT") && totalDays > 0 && dataOwner) &&
                    <Dialog >
                        <DialogTrigger>
                            <Button style="pill" sizePadding="pill" text="consultants.timesheet.importFile" />
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle><Label message="consultants.timesheet.importFile" size="h4" /></DialogTitle>
                                <DialogDescription className="flex flex-col gap-4 py-4">
                                    <DragAndDropZone file={file} setFile={setFile} fileError={fileError} className=" border-primary" />
                                </DialogDescription>
                            </DialogHeader>
                            <DialogFooter className="sm:justify-start">
                                <DialogClose asChild>
                                    <Button type="button" style="ghost" text='global.actions.cancel' onClick={() => { setFile(), setFileError() }} />
                                </DialogClose>
                                <DialogClose className="w-full">
                                    <Button style="fill" text='global.actions.save' className="w-full" onClick={() => { handleSendFile() }} disabled={submitLoading} />
                                </DialogClose>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                }
                {(dataOwner && data) && (data?.status == "DRAFT" ) &&
                    <span onClick={() => setOpen(false)}>
                        <NewTimesheetPanel
                            global={false}
                            isEditing
                            year={data?.year}
                            month={data?.month}
                            timesheetId={data?.uuid}
                            client={data?.clientName}
                            refetchTimesheets={refetchTimesheets}
                        />
                    </span>
                }
                {(!dataOwner && data.status === "SUBMITTED") && <>
                    <Button
                        style="pill"
                        sizePadding="pill"
                        text="global.actions.approve"
                        className="bg-success20 border border-success"
                        onClick={() => handleApproveTimesheet_asAdmin(data?.uuid)} />
                    {loadingApprove && <span className="loader" />}
                    <Button
                        style="pill"
                        sizePadding="pill"
                        text="global.actions.decline"
                        className="bg-error20 border border-error"
                        onClick={() => handleDeclineTimesheet_asAdmin(data?.uuid)} />
                </>}

               

                <ControlsDownload
                    dataOwner={dataOwner}

                    onClickNewTab={() => { dataOwner ? handleOpenTimesheet() : handleRequestSignedTimesheeet() }}
                    disabledOpenNewTab={dataOwner ? (!data.uuid || exportError) : errorSignedTimesheet}
                    hideOpenNewTab={data.status !== "DRAFT"}
                    hideDownload


                    onClickDelete={() => handleDeleteTimesheet(data.uuid)}
                    disabledDelete={!data.uuid || errorDelete} 
                    hideDelete={data?.status === "APPROVED"}
                    />


                {(exportLoading || submitLoading || loadingDelete || loadingSignedTimesheeet) && <span className="loader" />}
            </div>
            {open && <div className=" w-full flex gap-secondDegree">
                <div className="w-1/4 flex flex-col gap-firstDegree">
                    {/* Synthesis */}
                    <DaysSynthesis data={data.daysWorked} message="daysWorked" />
                    <DaysSynthesis data={data.daysIllness} message="daysIllness" />
                    <DaysSynthesis data={data.daysVacation} message="daysVacation" />
                    <DaysSynthesis data={data.daysTraining} message="daysTraining" />
                </div>
                <div className="w-3/4">
                    <ShowTimesheet year={data.year} month={data.month} timesheetId={data.uuid} refetchTimesheets={refetchTimesheets} totalDays={totalDays} dataOwner={dataOwner}></ShowTimesheet>
                </div>
            </div>}

        </div>
    )
    // };
}