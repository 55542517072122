// import { useAuth } from "@/context/AuthContext";
// import { gql, useMutation } from "@apollo/client";

// const ADD_INVOICE = gql`
//   mutation AddConsultantInvoice($newInvoice: NewConsultantInvoiceRequestDto!) {
//     addConsultantInvoice(newInvoice: $newInvoice) {
    
//       clientName
//       currency
//       dailyRateClient
//       dailyRateConsultant
//       daysWorked
//       invoiceDate
//       invoiceNumber
//       periodEnd
//       periodStart
//     }
//   }
// `;
// export const useAddInvoice = () => {
//   const { user } = useAuth();

//   const [addInvoice, { loading, error, data }] = useMutation(ADD_INVOICE, {
//     context: {
//       headers: {
//         Authorization: `Bearer ${user.strongToken}`,
//       },
//     },
//     fetchPolicy: "network-only",
//   });

//   return { addInvoice, loading, error, data };
// };




//   // update(cache, { data: { addConsultantInvoice } }) {
//       // Update cache after mutation
//       // const { consultantInvoices } = cache.readQuery({
//       //   query: GET_INVOICES_PER_YEAR,
//       //   variables: {
//       //     invoiceRequest: {
//       //       consultantUuid: user.consultantUuid,
//       //       year: addConsultantInvoice.year,
//       //     },
//       //   },
//       // });

//       // cache.writeQuery({
//       //   query: GET_INVOICES_PER_YEAR,
//       //   variables: {
//       //     invoiceRequest: {
//       //       consultantUuid: user.consultantUuid,
//       //       year: addConsultantInvoice.year,
//       //     },
//       //   },
//       //   data: {
//       //     consultantInvoices: {
//       //       invoices: [...consultantInvoices.invoices, addConsultantInvoice],
//       //     },
//       //   },
//       // });
//    // },


// useAddInvoice.js

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const ADD_INVOICE = gql`
  mutation AddConsultantInvoice($newInvoice: NewConsultantInvoiceRequestDto!) {
    addConsultantInvoice(newInvoice: $newInvoice) {
      uuid
      
      
      invoiceDate
      periodStart
      periodEnd
      clientName
      invoiceNumber
      currency
      dailyRateConsultant
      dailyRateClient
      daysWorked
    }
  }
`;

export const useAddInvoice = () => {
  const { user } = useAuth();

  const [addInvoice, { loading, error, data }] = useMutation(ADD_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  return { addInvoice, loading, error, data };
};
