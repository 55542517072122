import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";

const GET_EXPENSE_REPORTS = gql`
  query ExpenseReports($sliceNumber: Int!) {
    expenseReports(expenseReportsRequest: { sliceNumber: $sliceNumber }) {
      expenses {
        uuid
        expenseReportFileUuid
        createdAt
        invoiceDate
        category {
            accountingAccountNumber
            labelFr
            labelEn
            label
            uuid
            }
        status
        amountExcludingVat
        currency
        countryCode
        comment
        totalAmount
        invoiceIssuer
        vatList {
          vatRate
          vatAmount
        }
      }
      hasNext
    }
  }
`;


export const useExpenseReports = (sliceNumber) => {
    const { user } = useAuth();

    const { loading, error, data, refetch } = useQuery(GET_EXPENSE_REPORTS, {
        variables: { sliceNumber },
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
    });

    useEffect(()=>{
        refetch()
    }, [sliceNumber])

    return { loading, error, expenseReports: data?.expenseReports, refetch };
};

