/* eslint-disable react/prop-types */

/* eslint-disable react/prop-types */
/**
 * @component AddLanguage
 *
 * The `AddLanguage` component allows users to add or edit the languages they speak, along with their proficiency level. It provides a form for selecting a language and rating the level of proficiency. The component is designed to work with asynchronous updates to a user profile.
 *
 * Key Features:
 * - Select a language from a predefined list of languages (using the `UniqueSelect` component).
 * - Rate the language proficiency using a star-based rating system (using the `Stars` component).
 * - Handle form submission to add or update the user's language information.
 * - Includes form validation and server response handling.
 *
 * Props:
 * - @param {Object} language - Language data for editing mode (optional).
 * - @param {boolean} showForm - Whether to show the form.
 * - @param {Function} setShowForm - Function to toggle the visibility of the form.
 * - @param {boolean} [editingLanguage=false] - Flag to indicate if the form is in edit mode.
 * - @param {Function} setLanguages - Function to update the list of languages after submission.
 * - @param {Function} setEditingLanguage - Function to toggle the edit mode for languages.
 * - @param {Array} languages - Array of language objects that the user speaks.
 * - @param {Function} setIsDoingAction - Function to toggle loading or action states when performing tasks.
 * - @param {Function} setFormIsValid - Function to update the form's validation status.
 * - @param {Function} setServerResponseIserror - Function to handle the error state based on server responses.
 * - @param {Function} setErrors - Function to update the error messages.
 * - @param {Object} user - Object representing the user, containing attributes like `uuid`, `firstname`, and `lastname`.
 *
 * Usage:
 * - This component is primarily used in user profile settings to add or edit spoken languages and their respective proficiency levels.
 *
 * Example:
 * ```jsx
 * <AddLanguage
 *   showForm={true}
 *   setShowForm={setShowForm}
 *   languages={languages}
 *   setLanguages={updateLanguages}
 *   setIsDoingAction={toggleLoading}
 *   setFormIsValid={validateForm}
 *   setServerResponseIserror={handleServerError}
 *   setErrors={updateErrors}
 *   user={currentUser}
 * />
 * ```
 *
 * Dependencies:
 * - `Button` component for form actions (Save, Cancel).
 * - `UniqueSelect` component for selecting languages from a dropdown.
 * - `Stars` component for rating language proficiency with a 5-star system.
 * - `Form` component from `react-hook-form` for handling form validation and submission.
 * - `useTranslation` hook from `react-i18next` for multi-language support.
 * - `useUpdateProspect` hook for updating the user's profile with the new language data.
 * - Utility functions `generateRandomId`, `getLanguageCodeByName`, `transformedLangues`, and `validateFormData`.
 *
 * Sub-Components:
 * - @component UniqueSelect: A dropdown input for selecting languages or proficiency levels.
 * - @component Stars: A star rating system to capture the user's language proficiency (from 1 to 5 stars).
 * - @component Button: The button component used for form actions (Save, Cancel).
 */

import { useState } from "react";
import { Button } from "@/components/molecules/buttons/button";
import { useForm } from "react-hook-form";
import { Form } from "@/components/organisms/forms/form";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import UniqueSelect from "@/components/molecules/inputs/UniqueSelect";
// import languageNames from "countries-list/minimal/languages.native.min";
import { languages } from "countries-list";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import generateRandomId from "@/utils/randomId";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import useUpdateProspect from "@/services/admin-services/mutations/updateProspect/useUpdateProspectAddItems";
import Stars from "../skills/Stars";
import { getLanguageCodeByName } from "@/utils/getLanguesNameAnCode";
// Obtenir une liste des noms de langue à partir de l'objet languages
const languageNames = Object.values(languages).map((lang) => lang.name);

const languageLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];
export default function AddLanguage({
  language,
  showForm,
  setShowForm,
  editingLanguage = false,
  setEditingLanguage,
  languages,
  setIsDoingAction,
  setFormIsValid,
  setServerResponseIserror,
  setErrors,
  user
}) {
  const form = useForm();

  const id = generateRandomId(); //à enlever
  const [languageName, setLanguageName] = useState("");
  const [languageCode, setLanguageCode]=useState("")
  const [level, setLevel] = useState(0);
  const [t] = useTranslation("global");
  const {addSpokenLanguage, errorAddSpokenLanguage, loadingAddSpokenLanguage}=useUpdateProspect()

  useEffect(() => {
    const mother = t("global.information.motherTongue");
    if (languageLevels[6]) {
      languageLevels[6] != mother && languageLevels.push(mother);
    } else {
      languageLevels.push(mother);
    }
  }, [t]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingLanguage) {
      //update
    }
    else {
      //add
      if (languageCode.length > 0 && level > 0) {
        await addSpokenLanguage(user.uuid, languageCode, level)
        setIsDoingAction(true)
         if (errorAddSpokenLanguage) {
           console.error("Failed to add description:", errorAddSpokenLanguage);
           //alert("Add successfully ");
           setFormIsValid && setFormIsValid(false);

           setServerResponseIserror && setServerResponseIserror(false);
           setEditingLanguage && setEditingLanguage(false);
           if (errorAddSpokenLanguage && setErrors)
             setErrors(errorAddSpokenLanguage);
         } else {
           setFormIsValid && setFormIsValid(true);
         }
      }
      else {
        console.log("Form is invalid", languageCode, level)
      }
    }

    console.log("id ", id);
    setShowForm(false);
   
  };
  const handleCancel = () => {
    setEditingLanguage(false);
    setShowForm(false);
  };
  const changeLanguage = (language) => {
    setLanguageName(language)
    setLanguageCode(getLanguageCodeByName(language).toLocaleUpperCase())

    
  }

  return (
    <div>
      {showForm && (
        <div className="gap-secondDegree flex flex-col p-secondDegree shadow-md ">
          <Separator orientation="horizontal"  />
          <Form {...form}>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-firstDegree w-full"
            >
              {/**/}
              <div className="flex flex-row gap-betweenText">
                <UniqueSelect
                  className=""
                  id="1"
                  showLabel={false}
                  dataToSelect={languageNames}
                  isSearchable={true}
                  filterDataFunction={changeLanguage}
                />
               
                <Stars
                  totalStars={5}
                  informSelectedIndex={setLevel}
                  indexSelected={level}
                  allowChange={true}
                />
              </div>
              <div className="flex flex-row gap-[12px] items-center w-full">
                {/* <DeleteIcon
                  className="text-primary cursor-pointer"
                  onClick={handleDelete}
                /> */}
                <Button
                  showLeftIcon={true}
                  leftIcon={<ClearIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.cancel"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="outline"
                  className="font-bold border-primary w-full"
                  onClick={handleCancel} // Options: "fill", "outline", "ghost", "dressedGhost"
                />
                <Button
                  showLeftIcon={true}
                  leftIcon={<SaveIcon fontSize="small" />}
                  showText={true}
                  text="global.actions.save"
                  sizePadding="default" // Utilisez "small" pour un padding plus petit
                  style="fill"
                  className="font-bold w-full"
                  type="submit"
                  // Options: "fill", "outline", "ghost", "dressedGhost"
                />
              </div>
            </form>
          </Form>
        </div>
      )}
    </div>
  );
}
