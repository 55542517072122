import { useState } from "react";
import errorToText from "@/utils/errorToText";

const useConsultantStatementYears = () => {
  const [loading, setLoading] = useState(false); 
  const [errorYear, setError] = useState(null);
  const [allYears, setAllYears] = useState(null);

  const getYears = async (token, uuid) => {
    setLoading(true);
    setError(null);
    console.log("getYears called with token:", token, "uuid:", uuid);

    try {
      const response = await fetch(
        window.ENV.BACKEND_URL+"/api/graphql",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: `query {
              consultantStatementYears(consultantUuid: "${uuid}")
            }`,
          }),
        }
      );

      if (!response.ok) {
        const errMessage = errorToText(response);
        setLoading(false);
        throw new Error(errMessage);
      }

      const data = await response.json();
      console.log("Server response:", data);

      if (data.errors) {
        const errMessage = data.errors[0].message;
        setLoading(false);
        throw new Error(errMessage);
      }

      setAllYears(data.data.consultantStatementYears);
      setLoading(false);
      return { data: data.data.consultantStatementYears, error: null };
    } catch (err) {
      console.error("getYears error:", err);
      setError(err.message);
      setLoading(false);
      return { data: null, error: err.message };
    }
  };

  return { getYears, allYears, errorYear };
};

export default useConsultantStatementYears;
