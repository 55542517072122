import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const DELETE_LEASING_INVOICE = gql`
  mutation DeleteLeasingInvoice($uuid: ID!) {
    deleteLeasingInvoice(deletedLeasingInvoice: { uuid: $uuid })
  }
`;

export const useDeleteLeasingInvoice = () => {
  const { user } = useAuth();

  const [deleteLeasingInvoiceMutation, { loading, error, data }] = useMutation(DELETE_LEASING_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
    
  });
  const deleteLeasingInvoice = async (uuid) => {
    const response = await deleteLeasingInvoiceMutation({
      variables: { uuid },
    });
    return response.data.deleteExpenseReport;
  };
  return { deleteLeasingInvoice, loading, error, data };
};
