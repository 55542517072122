// import { useEffect, useState } from "react";
// import axios from "axios";
// import { useAuth } from "@/context/AuthContext";



// const useProspectPicture = (prospectId) => {
//     const [profilePicture, setProfilePicture] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const { user } = useAuth();
//     const token = user.strongToken;
//     // const userAgent = navigator.userAgent;
//     // console.log(userAgent);
//     const headers = {
//         Authorization: `Bearer ${token}`,
//         //"user-agent": userAgent, // Remplacez par votre user-agent
//     };

//     // Fonction pour récupérer la photo de profil
//     const getProspectPicture = async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await axios.get(
//                 window.ENV.BACKEND_URL + `/api/secured/admin/prospect/picture/${prospectId}`,
//                 {
//                     headers,
//                     responseType: "blob", // Pour manipuler les fichiers blob
//                 }
//             );

//             // Convertir le blob en une URL de manière temporaire
//             const imageUrl = URL.createObjectURL(response.data);
//             setProfilePicture(imageUrl);
//         } catch (err) {
//             setError(err);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fonction pour télécharger une nouvelle photo de profil
//     const uploadProspectPicture = async (file) => {
//         setLoading(true);
//         setError(null);
//         try {
//             const formData = new FormData();
//             formData.append("picture", file);

//             await axios.post(
//                 window.ENV.BACKEND_URL + `/api/secured/admin/prospect/picture/${prospectId}`,
//                 formData,
//                 {
//                     headers: {
//                         ...headers,
//                         "Content-Type": "multipart/form-data",
//                     },
//                 }
//             );

//             // Après l'upload, récupérez à nouveau l'image pour mettre à jour l'état
//             await getProspectPicture();
//         } catch (err) {
//             setError(err);
//         } finally {
//             setLoading(false);
//         }
//     };
//     useEffect(() => {
//         !profilePicture && getProspectPicture();
//     }, [prospectId])

//     // console.log("!profilePicture =", !profilePicture)
//     return {
//         profilePicture,
//         loading,
//         error,
//         // getProspectPicture,
//         uploadProspectPicture,
//     };
// };

// export default useProspectPicture;


import { useEffect, useState } from "react";
import { useAuth } from "@/context/AuthContext";

const useProspectPicture = (prospectId) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();


    const getProspectPicture = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(
                window.ENV.BACKEND_URL + `/api/secured/admin/prospect/picture/${prospectId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user?.strongToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob(); // Pour récupérer le fichier blob (image)
            const imageUrl = URL.createObjectURL(blob); // Convertir le blob en URL
            setProfilePicture(imageUrl);
        } catch (err) {
            setError(err);

        } finally {
            setLoading(false);
        }
    };

    // Fonction pour télécharger une nouvelle photo de profil avec fetch
    const uploadProspectPicture = async (file) => {
        setLoading(true);
        setError(null);
        console.log("uploadProspectPicture", file, user?.strongToken, prospectId)
        try {
            const formData = new FormData();
            formData.append("picture", file);

            const response = await fetch(
                `${window.ENV.BACKEND_URL}/api/secured/admin/prospect/picture/${prospectId}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${user?.strongToken}`,
                    },
                    body: formData, // FormData gère automatiquement les en-têtes multipart/form-data
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Après l'upload, récupérez à nouveau l'image pour mettre à jour l'état
            await getProspectPicture();
        } catch (err) {
            console.log("uploadProspectPicture err", err)

            setError(err);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     !profilePicture && prospectId && !loading && !error && getProspectPicture();
    // }, [prospectId]);

    return {
        profilePicture,
        loading,
        error,
        uploadProspectPicture,
    };
};

export default useProspectPicture;
