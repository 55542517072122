import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import EventDialog from './EventDialog';
import { Button } from '@/components/molecules/buttons/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Label } from '@/components/atoms/texts/label';
import EventSettings from './EventSettings';
import transformTimeEntriesToEvents from './transformTimeEntriesToEvents';
import { Input } from '@/components/ui/input';
import { useTranslation } from 'react-i18next';
import PrintErrorMessage from '@/components/organisms/feedback/PrintErrorMessage';
import { useAddTimeEntry } from '@/services/consultant-services/mutation/useAddTimeEntry';
import { useDeleteTimeEntry } from '@/services/consultant-services/mutation/useDeleteTimeEntry';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import { useAddTimesheet } from '@/services/consultant-services/mutation/useAddTimesheet';
import { useToast } from '@/components/organisms/feedback/use-toast';
import { useTimeEntries } from '@/services/consultant-services/query/useTimeEntries';
import { useConsultantTimeEntries } from '@/services/admin-services/query/useConsultantTimeEntries';
import 'moment/locale/fr'; // Import de la locale française

import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import fr from "date-fns/locale/fr";

const DragAndDropCalendar = withDragAndDrop(Calendar);

const TimesheetCalendar = ({
    needAbsolutePopUp = false,
    viewOnly = false,
    selectedMonthToDisplay,
    submitRef,
    client,
    refetchTimesheets,
    timesheetId,
    setTimesheetMonth,
    timesheetMonth,
    dataOwner = false
}) => {
    const [events, setEvents] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [idNewEvent, setIdNewEvent] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(selectedMonthToDisplay ? selectedMonthToDisplay : moment().startOf('month').format('YYYY-MM'));
    const [selectedClient, setSelectedClient] = useState(client); // Placeholder for client
    const [timesheetUuid, setTimesheetUuid] = useState(timesheetId);
    const [selectedSlotRange, setSelectedSlotRange] = useState(null); // Nouvelle variable pour gérer la sélection
    const [highlightedDays, setHighlightedDays] = useState([]); // Nouveau tableau pour stocker les jours sélectionnés
    const [formCalendarIsValid, setFormCalendarIsValid] = useState(false);
    const [timeEntriesForCalendar, setTimeEntriesForCalendar] = useState();

    moment.locale('fr'); // Définir la locale en français
    const locales = {
        fr: fr,
    };
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });
    const [t] = useTranslation('global')
    const { toast } = useToast();

    const { data: dataTimeEntries, loading: loadingTimeEntries, error: errorTimeEntries, refetch } = dataOwner ? useTimeEntries(timesheetUuid) : useConsultantTimeEntries(timesheetUuid);

    const { addTimeEntry, addTimeEntries, loading: loadingAddTimeEntry, error: errorAddTimeEntry } = useAddTimeEntry();
    const { deleteTimeEntry, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteTimeEntry(); // Utilisation du hook de suppression
    const { addTimesheet, loading: loadingAddTimesheet, error: errorAddTimesheet, data: dataAddTimesheet } = useAddTimesheet(); // Hook pour créer une timesheet

    useEffect(() => {
        console.log("test TimesheetCalendar", dataTimeEntries, errorTimeEntries)
        if (dataTimeEntries && timesheetMonth?.year && timesheetMonth?.month) {
            const transformedEvents = transformTimeEntriesToEvents(dataTimeEntries, timesheetMonth.year, timesheetMonth.month);
            console.log(" useEffect dataTimeEntries timeEntries_", dataTimeEntries, transformedEvents);
            setTimeEntriesForCalendar(transformedEvents);
        }
        if (errorTimeEntries) {
            console.error("Error fetching time entries: ", { errorTimeEntries }, dataTimeEntries);
        }
    }, [dataTimeEntries, errorTimeEntries, timesheetMonth]);

    useEffect(() => {

        if (timeEntriesForCalendar) {
            const transformedEvents = timeEntriesForCalendar.map((entry, index) => ({
                id: entry.id,
                title: eventComp(t(entry.title), entry.id, entry.year),
                start: new Date(entry.start),
                end: new Date(entry.end),
                bgColor: entry.bgColor,
            }));

            setEvents(transformedEvents);
        }

    }, [timeEntriesForCalendar]);

    useEffect(() => {
        let monthIsValid = (selectedMonth && selectedMonth != 'Invalid date');
        let clientIsValid = !!selectedClient;

        !viewOnly && setFormCalendarIsValid(monthIsValid && clientIsValid)
    }, [selectedClient, selectedMonth]);


    const handleSelectSlot = (slotInfo) => {
        console.log("Selected slot: ", slotInfo);

        let { start, end } = slotInfo;

        // Si la date de fin est exactement minuit le lendemain, on la corrige pour exclure ce jour
        if (moment(end).isSame(moment(end).startOf('day')) && moment(end).diff(moment(start), 'days') >= 1) {
            end = moment(end).subtract(1, 'day').endOf('day').toDate(); // Ajuste la date de fin pour exclure le lendemain
        }

        // Première partie : Gestion de la sélection des jours pour le surlignage
        const selectedDays = [];
        let currentDay = moment(start);

        while (currentDay.isSameOrBefore(end, 'day')) {
            selectedDays.push(currentDay.clone().toDate());
            currentDay = currentDay.add(1, 'day');
        }

        setHighlightedDays(selectedDays); // Mettre à jour les jours surlignés
        setSelectedSlotRange({ start, end }); // Enregistrer la plage sélectionnée avec la date de fin corrigée

        // Deuxième partie : Mise à jour de SelectedSlot et ouverture du dialogue si nécessaire
        if (!viewOnly) {
            setSelectedEvent(null);

            // Mise à jour de la plage de dates sélectionnée avec la date de fin corrigée
            setSelectedSlot({ start, end, isAllDay: moment(end).diff(moment(start), 'days') >= 1 });
            setDialogOpen(true); // Ouvre le dialogue pour l'événement sélectionné
        }
    };


    // Ajouter un style à la sélection de créneau horaire
    const slotPropGetter = (date) => {
        console.log("slotPropGetter", selectedSlotRange, moment(date).isBetween(selectedSlotRange.start, selectedSlotRange.end, null, '[]'));
        if (selectedSlotRange && moment(date).isBetween(selectedSlotRange.start, selectedSlotRange.end, null, '[]')) {
            return {
                className: 'rbc-selected-slot', // Applique la classe CSS personnalisée
                style: { backgroundColor: 'rgba(0, 123, 255, 0.5)', bgColor: 'rgba(0, 123, 255, 0.5)', border: '2px dashed #007bff' }, // Style personnalisé pour la sélection
            };
        }
        return {};
    };

    /**
     * Render event component
     * @param {string} title - Event title
     * @param {number} idEvent - Event ID
     * @returns {JSX.Element} - Event component
     */
    const eventComp = (title, idEvent, year) => (
        <div className="flex justify-center items-center rounded-sm pl-[2px] py-2 pr-0 gap-1 group ">
            {/* {console.log("eventComp, idEvent = ", idEvent)} */}
            <div className='w-full overflow-auto'>
                <Label message={title}>{t(`consultants.timesheet.${title}`)}</Label>
            </div>
            {!viewOnly && <DropdownMenu >
                <DropdownMenuTrigger className={`w-0 group-hover:w-auto `}>
                    <DeleteOutlinedIcon fontSize="small" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="" align="end">
                    <DropdownMenuItem onClick={() => handleDeleteEvent(idEvent, year)}><Label message="global.actions.delete" /></DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>}
        </div>
    );


    const handleSelectEventType = async (title, type_, bgColor, start, end, timeSelection) => {
        if (!viewOnly) {
            const trigger = eventComp(t(title), idNewEvent, start.getFullYear());

            setDialogOpen(false);
            setSelectedSlot(null);
            setSelectedEvent(null);
            setSelectedSlotRange();
            setHighlightedDays();

            console.log("Try to add:", { start, end, id: idNewEvent, title: trigger, bgColor });


            let type = type_;
            let duration = timeSelection;

            // Cas où l'événement s'étend sur plusieurs jours
            let currentDay = moment(start);
            let endDay = moment(end);

            // Vérifier si la date de fin est exactement minuit (00:00:00)
            if (endDay.isSame(endDay.clone().startOf('day'))) {
                // Si oui, réduire d'un jour car on ne veut pas inclure ce jour
                endDay = endDay.subtract(1, 'day');
            }

            const timeEntries = [];

            try {
                // Boucle sur chaque jour de la période
                while (currentDay.isSameOrBefore(endDay, 'day')) {
                    let day = currentDay.date(); // Obtenez le jour du mois
                    console.log("Preparing entry for day:", day);
                    // Préparation des données pour une entrée
                    timeEntries.push({
                        timesheetUuid,
                        day,
                        type,
                        duration,
                    });
                    // Passer au jour suivant
                    currentDay = currentDay.add(1, 'day');
                }

                // Si une seule entrée, utiliser addTimeEntry, sinon addTimeEntries
                if (timeEntries.length === 1) {
                    const response = await addTimeEntry(timeEntries[0].timesheetUuid, timeEntries[0].day, timeEntries[0].type, timeEntries[0].duration);
                    if (response.error) {
                        console.error("Error adding time entry:", response.error);
                    } else {
                        console.log("Single time entry successfully added:", response.data);
                        // Recharger les entrées après l'ajout
                        refetch();
                    }
                } else {
                    const response = await addTimeEntries(timeEntries);
                    if (response.error) {
                        console.error("Error adding multiple time entries:", response.error);
                    } else {
                        console.log("Multiple time entries successfully added:", response.data);
                        // Recharger les entrées après l'ajout
                        refetch();
                    }
                }



            } catch (err) {
                console.error("Error in adding time entry:", err);
            }
        }
    };

    /**
     * Handle selection of an event
     * @param {Object} e - Event object
     */
    const handleSelectEvent = (e) => {
        if (!viewOnly) {
            console.log(events);
        }
    };

    // Fonction de suppression de l'événement
    async function handleDeleteEvent(eventIdToDelete, year) {
        console.log("debug eventIdToDelete", eventIdToDelete, year);
        if (eventIdToDelete && year) {
            try {

                const response = await deleteTimeEntry(eventIdToDelete, year);

                if (response.error) {
                    console.error("Error deleting time entry:", response.error);
                } else {
                    console.log("Time entry successfully deleted.");
                    refetch();

                }
            } catch (err) {
                console.error("Error deleting time entry:", err);
            }
        }
    };
    /**
     * Customize event properties
     * @param {Object} event - Event object
     * @returns {Object} - Object containing className and style
     */
    const eventPropGetter = (event) => {
        const className = event.bgColor || '';
        return { className, style: {} };
    };

    /**
     * Handle event resize
     * @param {Object} param - Contains event, start date, and end date
     */
    const handleEventResize = ({ event, start, end }) => {
        const nextEvents = events.map(existingEvent => (existingEvent === event ? { ...existingEvent, start, end } : existingEvent));
        setEvents(nextEvents);
    };

    /**
     * Handle event drop
     * @param {Object} param - Contains event, start date, and end date
     */
    const handleEventDrop = ({ event, start, end }) => {
        if (!viewOnly) {

            const nextEvents = events.map(existingEvent => (existingEvent === event ? { ...existingEvent, start, end } : existingEvent));
            setEvents(nextEvents);
        }
    };

    /**
     * Handle setting context menu open
     * @param {Object} e - Event object
     */
    const handleSetContextMenuOpen = (e) => {
        setSelectedEvent(null);
    };


    const sendData = async (e) => {
        e.preventDefault();

        if (!selectedMonth || !selectedClient) {
            console.error("Please provide a valid month and client.");
            return;
        }

        // Extraire l'année et le mois de `selectedMonth` (format: 'YYYY-MM')
        const [year, month] = selectedMonth.split('-').map(Number);

        try {
            // Appel du hook `useAddTimesheet`
            const response = await addTimesheet(year, month, selectedClient);

            if (response.error) {
                console.error("Error adding timesheet:", response.error);
                toast({
                    variant: "error",
                    title: "error.requestFailed",
                    duration: 8000,
                    description: response.error,

                });
            } else {
                console.log("Timesheet added successfully:", response.data);
                setTimesheetUuid(response.data.uuid);
                setTimesheetMonth({ year: response.data.year, month: response.data.month })
                toast({
                    variant: "success",
                    title: "success.sendFile",
                    duration: 1500,
                });
                refetchTimesheets();
                refetch()

            }
        } catch (err) {
            console.error("Error in adding timesheet:", err);
        }
    };

    useEffect(() => {
        setDialogOpen(false);
        setSelectedSlot(null);
        setSelectedEvent(null);
        // console.log("useeffect events change : ", events);
    }, [events]);



    const CustomToolbar = (toolbar) => {

        return (
            <div className="w-full flex flex-row items-center ">
                <div className="w-full text-center capitalize">
                    <Label size="body" fontWeight="bold">{toolbar.label}</Label>
                </div>
            </div>
        );
    };

    const handleMonthChange = (e) => {
        const newMonth = moment(e.target.value, 'YYYY-MM').startOf('month').toDate();
        setSelectedMonth(moment(newMonth).format('YYYY-MM'));
    };


    // Utiliser `onSelecting` pour capturer la plage sélectionnée et stocker les jours
    const handleSelecting = (range) => {
        console.log("Selecting range:", range);

        const selectedDays = [];
        let currentDay = moment(range.start);

        while (currentDay.isSameOrBefore(range.end, 'day')) {
            selectedDays.push(currentDay.clone().toDate());
            currentDay = currentDay.add(1, 'day');
        }

        setHighlightedDays(selectedDays); // Mettre à jour les jours surlignés
        setSelectedSlotRange(range); // Enregistrer la plage sélectionnée
    };

    // Fonction pour ajouter un style personnalisé aux jours sélectionnés
    const isDayHighlighted = (date) => {
        return highlightedDays?.some((highlightedDate) =>
            moment(highlightedDate).isSame(date, 'day')
        );
    };
    // useEffect(() => {
    //     if (submitRef) {
    //         submitRef.reset = {
    //             resetForm
    //         };
    //         submitRef.decline = {
    //             sendLeasing
    //         }
    //     }
    // }, [submitRef]);


    return (
        <div className={viewOnly ? `p-0` : `p-4`}>
            {!viewOnly && <form onSubmit={sendData} className="flex space-x-4 mb-4">
                <input
                    value={selectedMonth}
                    type="month"
                    disabled={!!timesheetUuid}
                    onChange={(e) => handleMonthChange(e)}
                    className={`col-span-3 p-2 border rounded-md text-body w-full ${(!selectedMonth || selectedMonth == 'Invalid date') && `border border-error`}`}
                />
                <Input
                    value={selectedClient}
                    disabled={!!timesheetUuid}
                    placeholder={t("consultants.statement.billsDetails.customer")}
                    onChange={(e) => setSelectedClient(e?.target?.value)}
                    className={`col-span-3 p-2 border rounded-md text-body bg-white w-full ${!selectedClient && `border border-error`}`}
                />
                
                {loadingAddTimesheet && <span className='loader' />}
                
                {!timesheetUuid && <Button
                    ref={submitRef}
                    id="submitFormTimesheet"
                    type='submit'
                    variant="primary"
                    text="global.actions.create"
                    disabled={!formCalendarIsValid || loadingAddTimesheet}
                />}
            </form>}
            {!viewOnly && !formCalendarIsValid && <PrintErrorMessage label="error.missingRequired" />}

            {errorAddTimesheet && <PrintErrorMessage error={errorAddTimesheet} />}

            {(viewOnly || timesheetUuid) && <div className='flex flex-col'>
                {!viewOnly && <Separator className="mt-4 mb-2 bg-greyStrong" />}
                {(loadingDelete || loadingAddTimeEntry) && <span className="loader" />}
                {errorAddTimeEntry && <PrintErrorMessage error={errorAddTimeEntry} dismissible />}
                {errorDelete && <PrintErrorMessage error={errorDelete} dismissible />}
                {!viewOnly && <Label message="global.autoSaved" className="text-greyStrong" />}

                <DragAndDropCalendar

                    key={events.length} // Force re-render on events change
                    selectable={viewOnly ? false : true}
                    // resizable={viewOnly ? false : true}
                    resizable={false}
                    localizer={localizer}
                    events={events}
                    defaultView="month"
                    views={['month']}
                    // draggableAccessor={viewOnly ? true : false}
                    draggableAccessor={true}
                    date={moment(selectedMonth, 'YYYY-MM').startOf('month').toDate()}
                    // defaultDate={moment(selectedMonth, 'YYYY-MM').startOf('month').toDate()}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectSlot={handleSelectSlot}
                    eventPropGetter={eventPropGetter}
                    onEventDrop={handleEventDrop}
                    onEventResize={handleEventResize}
                    onDoubleClickEvent={(e) => handleSetContextMenuOpen(e)}
                    onSelectEvent={handleSelectEvent}
                    onDropFromOutside={handleSelectSlot}
                    onSelecting={handleSelecting} // Détection de la plage sélectionnée
                    // slotPropGetter={slotPropGetter} // Appliquer les styles à la sélection

                    selected={selectedEvent}
                    components={{ toolbar: CustomToolbar }}
                    className="gap-2 h-[80%] min-h-[600px]"
                    // Navigation is not allowed in vieew but must be defined because we use date and not defaultDate
                    onNavigate={(e) => { e.preventDefault }}
                    // Ajout du style personnalisé en fonction des jours sélectionnés
                    dayPropGetter={(date) => {
                        isDayHighlighted(date) && console.log("dayPropGetter isDayHighlighted(date)", isDayHighlighted(date))

                        if (isDayHighlighted(date)) {
                            return {
                                style: {
                                    backgroundColor: 'rgba(0, 123, 255, 0.2)',
                                    border: '1px dashed #007bff',
                                    outlineOffset: '0px',
                                    width: '80px',
                                    display: 'block',
                                    translate: '1px',
                                    backgroundSize: '50%'
                                }
                            };
                        }
                        return {};
                    }}
                    // Définir le premier jour de la semaine à lundi
                    step={60}
                    timeslots={2}
                    culture='fr'

                />
                {needAbsolutePopUp ?
                    <EventSettings
                        open={dialogOpen}
                        setDialogOpen={setDialogOpen}
                        onSelect={handleSelectEventType}
                        selectedSlot={selectedSlot}
                        clearSelectedSlot={() => { setSelectedSlot(); setSelectedSlotRange(); setHighlightedDays(); setDialogOpen(false) }}
                    />
                    :
                    <EventDialog
                        open={dialogOpen}
                        setDialogOpen={setDialogOpen}
                        onSelect={handleSelectEventType}
                        selectedSlot={selectedSlot}
                    />
                }
            </div>
            }



        </div>
    );
};

export default TimesheetCalendar;

