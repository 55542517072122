/**
 * @component AddProspectsPanel
 *
 * The `AddProspectsPanel` component provides a user interface for adding new prospects. It supports different modes for adding prospects, including manually and through file uploads. It integrates with various components to handle different actions related to prospect management and displays relevant forms and options based on user interactions.
 *
 * Key Features:
 * - Provides a sheet panel for adding new prospects.
 * - Supports adding prospects manually or through file uploads.
 * - Allows toggling between different prospect addition modes.
 * - Displays relevant components based on the selected mode (manual or file upload).
 * - Manages state related to the addition process and handles different flags.
 *
 * Props:
 * - @param {Function} setIsDoingAction - Function to set the state indicating if an action is being performed.
 * - @param {Boolean} isDoingAction - Boolean indicating if an action is currently being performed.
 * - @param {Function} refetch - Function to refetch data or update the state after an action.
 *
 * State:
 * - @variable {Boolean} open - Boolean to control the visibility of the sheet panel.
 * - @variable {Boolean} addManually - Boolean to indicate if the user wants to add prospects manually.
 * - @variable {Boolean} addFromFiles - Boolean to indicate if the user wants to add prospects from files.
 * - @variable {Boolean} hideFileComponent - Boolean to control the visibility of the file upload component.
 * - @variable {String} actualDate - The current date formatted for the file processing.
 *
 * Functions:
 * - @function resetAllFlags - Resets the state flags related to the panel and action states.
 *
 * Usage:
 * This component is used to manage the addition of new prospects in a system. Users can choose to add prospects manually or upload them through files. The component handles the visibility and state management of different actions involved in the prospect addition process.
 *
 * Example:
 * <AddProspectsPanel
 *   setIsDoingAction={setDoingAction}
 *   isDoingAction={doingAction}
 *   refetch={handleRefetch}
 * />
 *
 * Dependencies:
 * - `Sheet`, `SheetTrigger`, `SheetContent`, `SheetHeader`, `SheetSlot`, `SheetFooter`, `SheetClose` from `@/components/templates/sheet` for the sheet panel functionality.
 * - `Button` from `@/components/molecules/buttons/button` for action buttons.
 * - `AddRounded`, `DirectionsCar` from `@mui/icons-material` for iconography.
 * - `Label` from `@/components/atoms/texts/label` for text labels.
 * - `FileUploadZone`, `FormNewLeasing`, `AddManuallyProspect`, `ShowAllCvProcessing` for various prospect addition and file processing functionalities.
 * - `Separator` from `@/components/atoms/divider/separatorShadcn` for visual separators.
 * - `CreateAndUpdateCV` for managing CV creation and updates.
 *
 * Where it is used:
 * -  ProspectManagementDashboard
 * -  AdminProspectPanel
 */

import React, { useRef, useState, useEffect } from 'react';
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';
import { Label } from '@/components/atoms/texts/label';
import FileUploadZone from './FilesUploadZone';
import FormNewLeasing from '@/components/macro-organisms/panels/panels-admin/newLeasing/FormNewLeasing';
import Action from '@/components/molecules/buttons/Action';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import CreateAndUpdateCV from '../prospectConsultant/CreateAndUpdateCV';
import AddManuallyProspect from './AddManuallyProspect';

import ShowAllCvProcessing from './ShowAllCvProcessing';
export default function AddProspectsPanel({
    setIsDoingAction,
    isDoingAction,
    refetch,
    setShouldPrintNumberProspects

}) {
    // const submitRef = useRef(null);
    const date = new Date();
    const [actualDate, setActualDate] = useState(null);
    // Soustrait 2 minutes à la date actuelle
    //  date.setMinutes(date.getMinutes() - 3);

    // Convertit la date au format ISO avec un décalage de temps explicite
    const createdAfter = date.toISOString().replace(/\.\d{3}Z$/, "+00:00");
    const [open, setOpen] = useState(false);
    const [addManually, setAddManually] = useState(false);
    const [addFromFiles, setAddFromFiles] = useState(false);
    const [hideFileComponent, setHideFileComponent] = useState(false);
    const resetAllFlags = (flag) => {
        setIsDoingAction(true);
        setOpen(flag);
        setShouldPrintNumberProspects(flag)
        setAddManually(false);
        setHideFileComponent(false);
        setAddFromFiles(false);
        setActualDate(createdAfter);
        console.log("open and close")
    };

    return (
        <Sheet open={open} onOpenChange={resetAllFlags}>
            <SheetTrigger className={`w-full`}>
                <Button
                    leftIcon={<AddRounded />}
                    showLeftIcon
                    text="prospects.newProspect"
                    className="float-right"
                />
            </SheetTrigger>
            <SheetContent>
                <SheetHeader title={"prospects.newProspect"} />
                <SheetSlot className=" flex  relative">
                    {/* <FormNewLeasing onSubmit={handleSubmit} submitRef={submitRef} global={global} /> */}
                    {!addManually && !addFromFiles && (
                        <div className="flex flex-col gap-secondDegree p-secondDegree">
                            <Button
                                showLeftIcon={true}
                                showRightIcon={true}
                                text="prospects.createNewProspect"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                onClick={() => setAddFromFiles(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <div className="flex flex-row gap-firstDegree items-center">
                                <Separator className="w-[46%]" />
                                <Label message="global.information.or" />
                                <Separator className="w-[46%]" />
                            </div>
                            <Button
                                showLeftIcon={true}
                                showRightIcon={true}
                                text="prospects.addManually"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                onClick={() => setAddManually(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    )}

                    {addManually && (
                        <AddManuallyProspect
                            setIsOpen={resetAllFlags}
                            setIsDoingAction={setIsDoingAction}
                            refetch={refetch}
                        />
                    )}
                    {addFromFiles && (
                        <FileUploadZone
                            hideFileComponent={hideFileComponent}
                            setHideFileComponent={setHideFileComponent}
                            setIsDoingAction={setIsDoingAction}
                        />
                    )}
                    {hideFileComponent && (
                        <ShowAllCvProcessing
                            setIsDoingAction={setIsDoingAction}
                            actualDate={actualDate}
                        />
                    )}
                </SheetSlot>

            </SheetContent>
        </Sheet>
    );
}



