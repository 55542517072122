import { languages } from "countries-list";

export const transformedLangues = Object.keys(languages).reduce((acc, code) => {
  acc[code] = { name: languages[code].name, code: code };
  return acc;
}, {});
// Fonction pour récupérer le code du pays en fonction du nom
export const getLanguageCodeByName = (language) => {
  // Trouver la clé (code) du pays qui correspond au nom donné
  const countryCode = Object.keys(transformedLangues).find(
    (code) =>
      transformedLangues[code].name.toLowerCase() === language.toLowerCase()
  );

  return countryCode || null; // Retourne le code du pays ou null si non trouvé
};



export const getLanguageNameByCode = (code) => {
  const lowerCaseCode = code.toLowerCase();
  console.log("le code reçu", lowerCaseCode);


// Vérifier si le code existe dans transformedLangues
if (transformedLangues[lowerCaseCode]) {
  // Retourner le nom de la langue correspondant au code
  return transformedLangues[lowerCaseCode].name;
}
  console.log("Les langues",transformedLangues)

  return null; // Retourne null si le code n'est pas trouvé
};
