import { useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import errorToText from '@/utils/errorToText';

const useChangePassword = () => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    const changePassword = async (newPassword) => {
        setLoading(true);
        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/api/secured/users/changePassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.weakToken}`
                },
                body: JSON.stringify({ newPassword })
            });
            console.log("response", response,JSON.stringify({ newPassword }));
            if (response.status != 200) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            // const data = await response.json();
            setLoading(false);
            return { success: true, error: null };
        } catch (err) {
            setLoading(false);
            return { success: false, error: err.message };
        }
    };

    return { changePassword };
};

export default useChangePassword;
